.protocol-info {
  .m-form-show {
    .m-form-show-row {
      .m-form-show__value_entry {
        padding-right: 0 !important;
      }
    }
  }

  .m-form-show__value_entry {
    .entries-wrapper {
      max-height: 90px;
      overflow-y: scroll;
      width: 100%;

      @media print {
        max-height: unset;
        overflow-y: unset;
      }
    }
  }
}