.companies-list
  max-width: 100%
  height: 100%
  .si
    .si-control
      flex-wrap: nowrap
      .add-company-btn
        display: inline-flex
        align-items: center
        margin-left: 40px
        .btn-icon-left
          margin-right: 5px
      .search-group
        width: 100%
    .si-table
      .si-field
        > div
          flex-grow: 2
        .title
          flex-grow: 12
          max-width: 60%
          text-overflow: ellipsis
          overflow-x: hidden
        .phone_number
          width: max-content
        .options
          flex-grow: 0
          align-items: center
          justify-content: center
          display: flex
