.tc2024-xml-form {
  display: flex;
  flex-direction: column;

  .tc2024-xml-form__section {
    display: flex;
    flex-direction: column;
    gap: $indent;
  }

  .tc2024-xml-form__row {
    display: flex;
    gap: $indent;
  }

  .tc2024-xml-form__mcd-icon {
    margin-top: 2px;
  }
}
