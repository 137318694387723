.workplaces-catalog {
  &-form {
    &__kkm {
      display: flex;
      flex-direction: column;
      gap: $indent-small;
      margin-bottom: $indent-validation;

      &-label {
        font-size: 11px;
        font-weight: bold;
        color: lighten($primary-font-color, 5%);
      }
    }
  }
}

.fiscal-printer {
  @mixin container-mixin {
    display: flex;
    flex-direction: column;
    gap: $indent-validation;
  }

  .m-panel__body {
    gap: $indent;
  }

  &-kkm .m-panel__body {
    @include container-mixin;
  }

  &-terminal {
    .m-panel__body {
      @include container-mixin;
    }

    &__terminal-log .el-textarea__inner {
      min-width: 500px;
    }
  }
}

.print-raw-receipt-modal {
  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .m-prompt-notice {
      font-size: 16px;
    }
  }

  &-correction-receipt {
    &__first-column {
      .m-select, .m-date-picker {
        width: 320px !important;
      }
    }
  }

  .el-card__body {
    padding-top: 0;
  }

  &-receipt-entries-item {
    &__title {
      padding-top: $indent;
    }

    &__row {
      display: flex;
      gap: $indent-mid;
      align-items: center;
    }

    &__price-and-amount .m-input {
      width: 190px;
    }
  }

  &-receipt-payments-item {
    display: flex;
    gap: $indent-mid;
    align-items: center;
  }
}
