.medical-polices {
  .m-table-row[data-active-and-not-expired="true"] {
    background-color: lighten($green, 35%);

    &:hover {
      background-color: lighten($green, 25%);
    }
  }
}

.medical-policy-editor {
  .medical-policy-editor__form {
    display: flex;
    flex-direction: column;

    .series-with-number {
      width: 100%;
      display: flex;
      gap: $indent;

      &__series {
        flex-basis: 230px;
      }

      &__number {
        flex-grow: 1;
      }
    }

    .policy-types {
      display: flex;
      gap: $indent;

      &__nsi {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
      }

      &__dates {
        display: flex;
        gap: $indent;

        .validation-wrapper {
          flex-grow: 1;
        }
      }
    }
  }

  .guarantee-letters {
    min-height: 180px;
    max-height: 400px;
  }
}

.medical-policy-search-create {
  .el-input__inner {
    max-height: $element-height;
  }

  .has-active-medical-policy {
    .m-label {
      color: $success;
    }

    .el-input__inner {
      border-color: $success;
    }

    .m-label.is-disabled {
      color: lighten($success, 10%);
    }

    .el-input.is-disabled {
      .el-input__inner {
        border-color: lighten($success, 20%);
      }
    }
  }
}
