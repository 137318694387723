.document-square-field-symbol {
  $border-size: 1px;
  $border: $border-size dotted $grey;

  $width: 19px;
  $height: 24px;

  min-width: $width;
  width: $width;

  min-height: $height;
  height: $height;

  position: relative;
  box-sizing: content-box;

  display: flex;
  justify-content: center;

  font-size: 18px;

  border-top: $border;
  border-bottom: $border;

  font-family: Courier New, monospace;
  text-transform: uppercase;

  &::before {
    content: "";
    position: absolute;
    height: $height - $border-size * 2;
    border-left: $border;
    top: $border-size;
    left: 0;
  }

  &--last {
    margin-right: $border-size;

    &::after {
      content: "";
      position: absolute;
      height: $height + $border-size * 2;
      border-right: $border;
      top: -$border-size;
      right: -$border-size;
    }
  }
}
