.analysis_order_state_icon.fa-square
  color: $primary-color !important
  margin-right: 5px

.analysis_order_state_icon.fa-check-square
  color: $green !important
  margin-right: 5px

.analysis_order_state_icon.fa-flask
  color: #7f9aa7 !important
  margin-right: 5px

.analysis_order_state_icon.fa-undo
  color: lightcoral !important
  margin-right: 5px

.analysis_order_state_icon.fa-box-alt,
.analysis_order_state_icon.fa-box,
.analysis_order_state_icon.fa-edit
  color: $orange !important
  margin-right: 5px

#analysis_orders_page
  .el-select-dropdown
    .el-scrollbar
      .el-scrollbar__view
        .el-select-dropdown__item
          max-width: 600px

  @media print
    div[data-tippy-root]
      display: none

#analysis_order_show
  display: flex
  flex-direction: column
  flex: 1

  .fa-square
    color: $primary-color

  .fa-check-square
    color: $green

  .panel-body
    position: relative
    min-height: 50px
    margin-top: 5px

  #analysis_order_laboratory
    display: flex
    flex-direction: row
    justify-content: space-between
    height: calc(100vh - var(--main-navbar-height) - 61px)

    @media print
      height: unset

    #analysis_order_area
      flex: 2
      display: flex
      flex-direction: column
      width: 100%

      .panel
        border-radius: 4px

        .panel-heading
          border-radius: 4px 4px 0 0

          .panel-title
            font-size: 14px

        .panel-body
          padding: $indent

      .btn.btn-grey-dark
        font-size: 12px

      .btn.btn-primary.btn-back
        margin-top: 0px

      #analysis_order_info
        margin-bottom: 10px

        .col-md-6
          padding-left: 0px
          padding-right: 0px

          #client_info_table
            .show_info_td
              &.comment
                max-width: 300px
              .analysis_order_state_icon
                margin-right: 0px

      .modal-body
        padding-top: 0px
        padding-bottom: 15px
        padding-right: 15px
        padding-left: 15px

      .modal-footer
        .el-button.btn-sm
          display: flex
          align-items: center
          font-size: 14px
          line-height: 1.5
          border-radius: 2px
          padding-top: 7px
          padding-bottom: 7px

          &:active
            box-shadow: none
            border-color: unset

      #entries_container
        margin-bottom: 10px

        .order_sums
          align-self: flex-end

        #entries_table
          width: 100%
          height: 100%
          display: flex
          flex-direction: column
          flex: 1
          overflow-y: hidden

          .entry_row
            display: flex
            flex-direction: row
            width: 100%
            justify-content: space-between
            min-height: 50px
            @media print
              height: auto
            &:nth-of-type(even):not(.skeleton)
              background-color: #f9f9f9
            &:hover
              cursor: pointer

            div.row_column
              padding: 5px 5px 5px 0px
              flex: 1
              text-align: left

            .title
              max-width: 50%
              min-width: 200px

            .biomaterial
              word-break: normal
              max-width: 200px
              margin: 0 15px
              .biomaterial-title-item
                height: 30px
                margin-bottom: $indent-validation
                padding: 5px 0 0 0

            .biomaterial-type
              text-align: center !important
              max-width: 220px
              margin: 0 $indent

            .number
              max-width: 100px

            .price
              max-width: 120px

            .due_date
              text-align: center
              max-width: 150px

            .options
              max-width: 50px
              padding: 5px 0px 5px 0px

              .btn
                width: 35px
                height: 32px

              span.fad
                margin-right: 0
                color: white
                font-size: 12px
                padding-bottom: 1px
                padding-top: 1px
                &:hover
                  text-decoration: none
                &:active
                  text-decoration: none
                &:focus
                  text-decoration: none
                &.fad
                  font-size: 16px

          #entries_table_header
            display: flex
            flex-direction: row
            font-weight: 600
            border-bottom: 1px solid #efefef
            z-index: 1
            width: 100%
            overflow: hidden
            text-overflow: ellipsis
            min-height: 25px

          #entries_table_body
            overflow-y: auto
            position: relative
            flex: 4

          #entries_table_footer
            display: flex
            flex-direction: row
            flex: 1
            z-index: 1
            width: 100%
            font-weight: 600
            border-top: 1px solid #efefef
            min-height: 25px

        #preparation_info
          white-space: pre-line

      #biomaterial_containers
        margin-bottom: 10px

        #containers_table
          width: 100%
          height: 100%
          display: flex
          flex-direction: column
          flex: 1
          overflow-y: hidden

          #containers_table_header
            font-weight: 600
            border-bottom: 1px solid #efefef
            z-index: 1
            width: 100%
            overflow: hidden
            text-overflow: ellipsis
            min-height: 25px

          .container_row
            display: flex
            flex-direction: row
            align-items: center
            height: 40px
            @media print
              height: auto
            &:nth-child(2n)
              background-color: $table-striped-odd
            div
              flex-grow: 1
              padding: $indent-small 0

            .icon
              min-width: 40px
              max-width: 40px

            .entries
              flex-basis: 200px

            .number
              flex-shrink: 1
              flex-basis: 120px
              flex-grow: 0
              min-width: 120px

            .title
              flex-basis: 300px
              max-width: unset
              flex-grow: 2
              min-width: 300px
              @media print
                flex-basis: 190px
                min-width: 190px
                margin-right: $indent-small

            .biomaterial
              flex-basis: 250px
              min-width: 250px
              @media print
                flex-basis: 120px
                min-width: 120px

            .amount
              flex-basis: 60px
              min-width: 60px

            .barcode
              flex-basis: 120px
              margin: 0 $indent-mid
              .el-input
                width: 135px
                &.error
                  .el-input__inner
                    border-color: $error !important
                .el-input__icon
                  height: 90%

            .comment
              flex-basis: 50px
              text-align: center

            .options-container
              display: flex
              flex-basis: 90px
              flex-grow: 0
              flex-shrink: 1
              justify-content: end
            .options
              max-width: 35px
              min-width: 35px
              text-align: end
              margin-right: $indent-mid
              .btn
                min-height: 32px
                max-height: 32px
                min-width: 35px

              span.fad,
              i.fad
                margin-right: 0
                color: white
                font-size: 12px
                padding-top: 1px
                &:hover
                  text-decoration: none
                &:active
                  text-decoration: none
                &:focus
                  text-decoration: none
                &.fad
                  font-size: 16px

            .entries
              .el-select
                width: 100%

                .el-select__tags
                  span
                    display: flex
                    width: inherit
                    .el-tag:nth-child(2)
                      min-width: 40px

                  .el-tag
                    width: 72%
                    &:only-child
                      width: inherit

        #processing_info
          white-space: pre-line

      // bgwah1
      .workplaces-dropdown
        $max-width: 180px
        $button-text-width: $max-width - 26px

        width: $max-width
        margin-right: 5px

        #workplaces-dropdown__button
          align-items: center
          display: flex
          height: 22px
          width: 100%

          span
            max-width: $button-text-width
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap

        .dropdown-menu
          margin-right: -5px
          width: 100%

          .workplaces-dropdown__menu-item
            color: white
            cursor: pointer
            font-size: 13px
            line-height: 1.5em
            margin: 0
            padding: 0 16px

            &:hover
              background-color: #FF9800


            &.ellipsis
              text-overflow: ellipsis

      #kdl-attachments
        #kdl-attachments_table_header
          font-weight: 600
          .title
            padding-left: $indent
        .attachment_row
          display: flex
          align-items: center
          .date
            min-width: 200px
          .options
            flex-grow: 1
            text-align: end
            margin-right: $indent-mid
            .el-button
              width: 35px
              min-height: 32px
              .m-icon
                font-size: 16px !important

      #control_panel
        .panel.panel-primary
          margin-bottom: 13px

        .modal-body
          padding-top: 15px

        #edit_buttons
          display: flex
          flex-direction: row
          justify-content: space-between

          .left_container
            display: flex

          .el-button.btn-sm
            display: flex
            align-items: center
            padding: 6px 15px 6px 15px
            border-radius: 2px
            margin-top: 0px
            margin-bottom: 5px
            font-size: 12px
            line-height: 1.5
            margin-left: 2px
            border: 0
            box-shadow: none
            &:active
              box-shadow: none
              border-color: unset

            &.disabled
              color: white
              &:focus
                color: white
              &:hover
                color: white


            i.fad
              top: 0px
              margin-right: 5px

          .btn-show-delete
            margin-top: -5px
            color: $red
            font-size: 24px
            cursor: pointer
            &.disabled
              color: lighten($red, 10%)
              background-color: unset
        .vue-modal-in
          &.modal-high
            .modal-dialog
              margin-top: 20px
              height: calc(100% - 50px)
          .modal-content
            width: inherit

            .modal-body
              #questionnaire_body
                .question
                  margin-bottom: $indent-mid
                  .answer_input
                    &.error
                      .el-input__inner
                        border-color: $error !important

                  .title
                    .required
                      color: $red

                  .value
                    .el-select
                      width: 100%

                      .el-input
                        width: inherit

                        .el-input__inner
                          width: inherit
                          height: 28px
                          padding-left: 10px
                          font-size: 14px
                          border-radius: 2px
                          border-color: $grey

                    .el-input
                      .el-input__inner
                        margin-bottom: 0px
                        width: inherit
                        height: 28px
                        padding-left: 10px
                        font-size: 14px
                        border-radius: 2px
                        border-color: $grey

                        &:focus
                          padding-left: 10px
                          box-shadow: none
                          border: 1px solid $grey

            .modal-footer
              .btn.btn-primary
                display: flex
                align-items: center
                font-size: 14px
                line-height: 1.5
                border-radius: 2px
                padding-top: 7px
                padding-bottom: 7px

                &:active
                  box-shadow: none
                  border-color: unset

        .panel.panel-primary
          .panel-heading
            .panel-heading-buttons
              .el-button.btn.btn-xs
                display: flex
                align-items: center
                font-size: 12px
                border-radius: 2px
                border: none

              .el-button.is-disabled
                color: $primary-font-color
                &:focus
                  color: $primary-font-color
                &:hover
                  color: $primary-font-color

          .panel-body
            .order_steps
              padding-bottom: 10px
              margin-top: 5px

              .filled
                text-decoration: line-through

      #attachments_container
        margin-bottom: 10px

        .panel.panel-primary
          .panel-heading
            .panel-heading-buttons
              .el-button.btn.btn-xs
                display: flex
                align-items: center
                font-size: 12px
                border-radius: 2px
                border: none

                i.fad
                  font-size: 16px

              .el-button.is-disabled
                color: $primary-font-color
                &:focus
                  color: $primary-font-color
                &:hover
                  color: $primary-font-color

        #attachments_table
          width: 100%
          height: 100%
          display: flex
          flex-direction: column
          flex: 1
          overflow-y: hidden

          #attachments_table_header
            display: flex
            flex-direction: row
            font-weight: 600
            border-bottom: 1px solid #efefef
            z-index: 1
            width: 100%
            overflow: hidden
            text-overflow: ellipsis
            min-height: 25px

          #attachments_table_body
            overflow-y: auto
            position: relative
            flex: 4

          .attachment_row
            display: flex
            flex-direction: row
            width: 100%
            &:nth-of-type(even)
              background-color: #f9f9f9

            div
              flex-grow: 3
              flex-shrink: 3
              flex-basis: 0px

            .date
              flex: 1 0 30px
              width: unset
              padding: 5px 0px 5px 0px

            .options
              max-width: 35px
              padding: 5px 0px
              margin-right: $indent-mid

              .btn
                width: 35px
                height: 32px

              .attachment-preview-btn
                margin-left: 2px
                margin-right: 0px
                color: white
                font-size: 12px
                padding-bottom: 5px
                padding-top: 1px
                &:hover
                  text-decoration: none
                &:active
                  text-decoration: none
                &:focus
                  text-decoration: none
                &.fad
                  font-size: 16px

            .title
              flex-basis: 3 1 300px
              max-width: unset
              padding: 5px 0px 5px 0px
