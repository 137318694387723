#head-desktop-component
  .cards-container
    .head-desktop__el-card
      .el-card__body
        .client-feedbacks-card
          position: relative
          .el-card__body__content
            height: 298px
            overflow: hidden
            .last-feedbacks
              color: #606267
              padding: 3px 10px 0px 10px
              font-size: 12px
              cursor: default
              .feedback
                margin-bottom: 10px
                box-shadow: 0px 0px 1px 1px #eee
                padding: 5px 10px
                border-radius: 2px
                height: 66px
                .flex-row
                  display: flex
                  align-items: center
                  border-bottom: 1px solid #eee1
                  .cell
                    margin: 0 5px
                  .rate
                    .el-rate
                      height: 14px
                      .el-rate__item
                        .el-rate__icon
                          font-size: 13px
                  .doctor
                    flex: 1
                  .status
                    flex: 1
                .client-comment
                  font-style: italic
                  position: relative
                  top: 10px
              .list-item
                transition: all 0.5s
                position: relative
                top: 0
              .list-enter
                top: -66px
                height: 0
              .list-leave-to
                height: 0
                overflow: hidden
                padding: 0
                margin-bottom: 0
        .el-card__body__footer
          padding: 0 10px
          .el-checkbox
            .el-checkbox__label
              font-size: 13px
