.sidebar-filters
  display: flex
  flex-direction: column
  row-gap: 10px
  margin-bottom: 5px
  .si-control-btns
    display: flex
    column-gap: 5px
    justify-content: center
    width: 100%
    margin-top: 4px
    .component
      margin: 0
  .sidebar-filters__buttons
    display: flex
    column-gap: 4px
    > .btn
      height: 30px
.sidebar__filter
  width: 100%
  .filter__label,
  .label__text
    width: 100%
    margin-bottom: 2px
    margin-left: 2px
    > *
      width: 100%

  .filter__input
    width: 100%
    .el-select
      width: 100%

  .el-range-editor
    width: 100%
    height: 30px
