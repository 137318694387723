#report_orders_per_day {
  .paidStatus {
    @media screen {
      flex-grow: 0;
      --size: 160px;
    }
  }

  .finalSumOnlyWithNds {
    @media screen {
      flex-grow: 0;
      --size: 140px;
      text-overflow: unset;
      white-space: normal;
      word-wrap: break-word;
    }
  }

  .ndsSum {
    @media screen {
      flex-grow: 0;
      --size: 120px
    }
  }

  .sum {
    @media screen {
      --size: 160px;
    }
  }
}
