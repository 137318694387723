.catalog-container
  column-gap: 5px
  overflow: auto

  .panel
    max-width: 100%

  .modal
    background-color: transparent
    
  .modal-dialog
    margin: 25px auto !important
