.panel-collapsed
  width: 30px
  cursor: pointer
  transition: background-color .3s
  &:hover
    background-color: #efefef
  &-heading
    padding: 5px 9px
  &-body
    overflow-y: hidden
  &-content
    white-space: nowrap
    margin: 0
  &-icon
    margin-right: 8px
  .panel-collapsed-heading
    padding: 2px 10px
    justify-content: center !important
    span
      font-size: 2.6rem
  .panel-collapsed-content
    span
      font-size: 1.6rem
    h3
      font-size: 1.3rem
    span.panel-collapsed-count
      font-size: 13px
      &.count-more-zero
        font-weight: bold


  &.panel-collapsed--right
    margin-right: 5px
    .panel-collapsed-content
      transform: rotate(90deg)

  &.panel-collapsed--left
    margin-left: 5px
    .panel-collapsed-content
      transform: rotate(90deg)

  &.panel-collapsed--up
    margin-top: 5px

  &.panel-collapsed--down
    margin-bottom: 5px
    display: flex
    flex-direction: row
    height: max-content
    width: 100%
    .panel-collapsed-heading
      order: 2
      height: 31px
    .panel-collapsed-body
      width: 100%
      padding: 0
      display: flex
      align-items: center
      margin-left: 9px
