#client_widget_configurations_page
  .configuration_info_table
    tbody
      tr
        td
          height: 39px
          input.form-control
            width: 520px
          textarea.form-control
            width: 210mm
          .client-widget-color-scheme-container
            display: flex
            align-items: center
            min-height: 34px

            .theme-color-picker-wrapper
              display: flex
              align-items: center

            .checkbox
              top: -8px

            .use-default-scheme,
            .custom-color-picker
              cursor: pointer
              margin-right: 2px

            .color-scheme-item-wrapper
              display: flex
              .color-scheme-item
                display: flex
                padding: 1px
                border: 2px solid transparent
                border-radius: 1px
                .color-scheme-item-color
                  min-width: 28px
                  min-height: 28px
                  width: 28px
                  height: 28px
              .color-scheme-selected-item
                border-color: $grey-dark

      td:first-child
        width: 400px

      .theme-color-picker-wrapper
        input[type="color"]
          background-color: white
          border: none
          height: 34px
          min-width: 56px
