.documents-tree-modal {
  .el-dialog__body {
    flex-direction: column;
  }

  .m-prompt-notice {
    margin-bottom: $indent-small;
  }

  .documents-tree-modal__body {
    .m-icon.gray {
      color: $primary-font-color;
    }
  }
}
