.company-orders-registry
  height: 100%
  .registry-collapsed-right
    margin-left: 10px
  .orders-box
    height: inherit
    display: -webkit-box
    .panel-collapsed
      &.panel-collapsed--right
        margin-right: 10px
      &.panel-collapsed--left
        margin-right: 0
    .panel-inner
      display: flex
      flex-direction: column
      height: inherit
      .panel-heading-buttons
        cursor: pointer
    .float-transfer-button
      position: absolute
      right: 30px
      bottom: 50px
    .associated-orders,
    .unassociated-orders
      .panel-primary .panel-body
        overflow: hidden
      .panel-heading-buttons span
        font-size: $size-arrow-icon
        margin-top: -3px
      height: inherit
      flex: 1 0 calc(50% - 25px)
      .panel-body
        height: inherit
    .order-transfer-manager
      flex: 0 0 50px
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      button
        display: flex
        height: 50px
        width: 28px
        margin: 0 0 5px 0
        padding: 0
        justify-content: center
        align-items: center
      &--medium
        margin-left: 40%

    .registry-orders-action
      margin-left: auto
      display: flex
      align-items: center
      .pay-btn
        margin-bottom: 0
      .registry-orders-sum
        margin-right: 10px
        font-size: 14px
        span
          font-weight: 800

    &.orders-box--twins
      .si-table .si-field > div
        flex-basis: 40px
      .si-table .si-field .paid-status
        flex-basis: 60px
    .si-footer
      align-items: center
      .right-container
        display: flex
        justify-content: flex-end
        align-items: center
        .orders-payment-progress
          flex: 1
          margin: 0px 15px
        .selected-order-info
          .selected-order-number
            margin-right: 5px
        .pay-btn
          margin: 0 10px

  .vue-modal-in
    .orders
      .modal-content
        .modal-body
          display: flex
          .si-table
            .ico-remove
              width: 30px
              font-size: 14px
              &:hover
                cursor: pointer
                color: #f32c1e

  .date
    flex-basis: 100px !important

@media screen and ( max-width: 1600px )
  .company-orders-registry .orders-box
    display: flex
    flex-direction: column
    justify-content: unset
    .orders-registry-collapsed
      transition: background 0.3s ease-in-out
      background-color: #3FAECA
      color: #ffffff
    .associated-orders,
    .unassociated-orders
      .panel-inner
        height: 100%
    .unassociated-orders
      margin-bottom: 10px
    .order-transfer-manager
      flex-basis: unset
      button
        width: 50px
        height: 28px
        &:first-child
          margin-right: $indent-small

      flex-direction: row
      .fad
        transform: rotate(90deg)
