.table
  &.flex-table
    width: 100%
    table-layout: fixed
    tr
      display: flex

      th
        font-weight: bold

      td, th
        flex-grow: 6
        flex-basis: 100px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        &.fixed
          flex-shrink: 0
        &.not-fixed
          flex-shrink: 1
        &.wraped
          white-space: normal
        &.number
          flex-grow: 3
          flex-shrink: 0
          flex-basis: 70px
          max-width: initial
        &.title
          flex-grow: 24
          flex-basis: 250px
          max-width: initial
        &.full-name
          flex-grow: 12
          flex-basis: 250px
          max-width: initial
        &.options
          flex: 0 0 40px
          flex-shrink: 0
          padding: 3px 10px 3px 10px
          max-width: initial
        &.date-time
          flex-shrink: 0
          flex-basis: 140px
          max-width: initial
        &.date
          flex-basis: 100px
          max-width: initial

