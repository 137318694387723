.under-field-error
  margin: 0 0
  font-style: italic
  font-size: small
  color: #ff5722
.client-form
  .client-field-btn
    font-size: 13px
    height: 30px
    min-width: 30px

#client_card_list
  .panel-body
    padding-left: $indent-small
    padding-right: $indent-small
