@import 'form_params'
@import 'service_item'
@import 'referrer_item'
@import 'stubs'

.d-flex
  display: flex

.vue-modal-in
  .modal-dialog.modal-xl
    height: calc(100% - 50px)
    margin: 20px auto 20px auto
    width: 90%
  .modal-clear
    background-color: #4aabff
    color: #ffffff

.appointment-form-flex
  gap: 1%
  .appointment-content,
  .client-form,
  .appointment-form-middle
    flex: 1 0 33%
    &.block-header-orange
      .form-block__title
        background-color: #e8a84b
  .form-label
    display: flex
    justify-content: flex-end
    align-items: center

  @media (max-width: 1600px)
    .form-label
      flex-basis: 120px
    .form-value
      flex-basis: calc(100% - 120px)

  .appointment-form-middle
    display: flex
    flex-direction: column
.appointment-form-flex
  .appointment-content
    display: flex
    min-height: 80vh
    width: 100%
    flex-basis: 35%

    .appointment__patient
      display: flex
      flex: 3

      .patient-personal
        display: flex
        flex-direction: column
        flex-grow: 1

      .patient-personal-extended
        display: flex
        flex-direction: column
        flex-grow: 1
        padding: 0 20px
