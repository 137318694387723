.document-field-writable-date {
  display: flex;
  gap: $indent-small;

  &__day {
    display: flex;
  }

  &__year {
    display: flex;
    align-items: center;
  }
}
