.order-boost {
  cursor: pointer;
  padding-left: $indent-small !important; // перебить оригинальные стили
  padding-right: $indent-small !important; // перебить оригинальные стили

  .order-boost__label {
    font-size: $primary-font-size;
    color: $primary-font-color;
  }

  .order-boost__label,
  .order-boost__content-wrapper {
    pointer-events: none;
  }

  .has-error {
    height: 34px;
  }

  .under_field_error {
    left: 0;
  }
}
