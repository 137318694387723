.personal-checkup-laboratory-research-results-modal {

  .el-dialog__body {
    flex-direction: column;
  }

  &__large-icon {
    font-size: x-large !important;
    height: min-content;
  }

  &__research {
    width: 400px;
  }
}

