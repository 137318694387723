.si-column
  cursor: pointer
  position: relative
.sort-state
  --fa-primary-opacity: var(--sorting-opacity)
  --fa-secondary-opacity: var(--sorting-opacity)
  --fa-primary-color: #6c6c6c
  --fa-secondary-color: #6c6c6c
  --sorting-opacity: 0.3
  @media print
    display: none

  &_active
    --sorting-opacity: 1
  &__container
    position: absolute
    right: 2px
    top: 0
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
.tablesorter-headerAsc
  .sort-state_asc
    --sorting-opacity: 1

.tablesorter-headerDesc
  .sort-state_desc
    --sorting-opacity: 1
