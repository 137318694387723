
.m-si-generator {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: $indent-mid;
  max-height: 100%;
  min-height: 120px;

  .m-si-generator__list-manage,
  .m-si-generator__filters,
  .m-si-generator__footer {
    display: flex;
    gap: $indent-small;
  }
}

@media print {
  .m-si-generator {
    height: unset;
    min-height: unset;
  }
}
