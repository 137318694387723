#report-acquiring {
  @media print {
    .col {
      &__paymentTypeCell {
        flex-basis: 45px !important;
      }

      &__operationTypeCell {
        flex-basis: 70px !important;
      }

      &__statusCell {
        flex-basis: 80px !important;
      }

      &__amountCell {
        flex-basis: 150px !important;
      }

      &__paymentCell {
        flex-basis: 80px !important;
      }

      &__userCell {
        flex-grow: 0;
        flex-basis: 100px;
      }

      &__completedByCell {
        flex-grow: 0;
        flex-basis: 100px;
      }

      &__payerCell {
        flex-grow: 0;
        flex-basis: 170px;
      }

      &__workplaceCell {
        flex-basis: 90px !important;
      }
    }
  }
}
