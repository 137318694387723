.document-bolt-dot {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  width: 18px;
  position: relative;
  overflow: hidden;

  &__wrapper {
    position: absolute;
    bottom: -3px;
  }
}
