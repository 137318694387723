#modal_call_show
  .modal-title
    .mdi
      color: white
      margin-right: 7px
  .tab-pane
    .call_info
      .add_appointment
        background-color: $green
      .btn
        align-items: flex-start
      .btn.btn-warning
        height: 30px
      .show_info_table
        .client
          display: flex
          align-items: center
          #client_selector
            max-width: fit-content
            margin-right: 5px
            font-size: 13px
          span
            margin-right: 5px


  .panel-primary.panel-inner
    margin-top: 10px
    font-size: 1.6rem
    .fad
      margin-right: 3px
  #appointments_table
    .appointment-checkbox-all-cell
      width: 30px
    .datetime
      width: 160px
    .appontments-status
      width: 160px
    .appointments-buttons
      width: 130px
    .doctor
      width: 130px
    .entry
      width: 150px
    .schedule-grid-appointment-move, .schedule-grid-appointment-edit
      margin-right: 5px
    .icon-status-reserved
      color: $primary-color
    .icon-status-done
      color: $green
    .icon-status-canceled
      color: $grey
    .icon-status-failed
      color: lightcoral
    .icon-status-arrived
      color: $orange
    .schedule-grid-appointment-btn-container
      .btn.btn-xs
        padding: 3px 10px
        width: 30px
      .btn.btn-xs.schedule-grid-appointment-cancel
        padding: 3px 9px

    .date-in-past
      color: #9e9e9e
