.not-found-result-container
  align-items: center
  display: flex
  flex-direction: column
  height: 100%
  justify-content: center
  max-height: 90%

  .not-found-result__image > img
    $imageSize: 120px
    height: $imageSize
    margin-bottom: 20px
    margin-top: -100px
    width: $imageSize

    &.size200
      $imageSize: 200px
      height: $imageSize
      width: $imageSize

  .not-found-result__text
    text-align: center
    font-size: 18px

    .not-found-result__secondary
      font-size: 14px
      font-weight: lighter
      color: lighten($primary-font-color, 20%)

    .not-found-result__reset
      margin: 0
      padding: 0
      text-transform: none
      display: inline-block
      font-size: inherit
      line-height: inherit
      vertical-align: baseline
      height: unset

  &.not-found-result-container_small
    margin-bottom: 10px

    .not-found-result__image  > img
      $imageSize: 40px
      height: $imageSize
      margin-bottom: 0
      margin-top: 10px
      width: $imageSize

    .not-found-result__text
      font-size: 14px

      .second-row
        font-size: 12px
