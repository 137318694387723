@mixin flex-form-sizes($leftPart: 30%, $rightPart: 70%)
  .form-label
    flex: 0 0 $leftPart !important

  .form-value
    flex: 0 0 $rightPart !important

.flex-form
  &.flex-form__30-70
    @include flex-form-sizes
  &.flex-form__35-65
    @include flex-form-sizes(35%, 65%)

.flex-form__centered
  margin-bottom: 0

  .form-label
    align-items: center
    display: flex
    justify-content: flex-end
    margin-top: -2px // выравние по строчным буквам
    padding-top: 5px
    padding-bottom: 5px
