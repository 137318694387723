.protocols-tab {
  .protocols-tab__period,
  .protocols-tab__doctor,
  .protocols-tab__specialty,
  .protocols-tab__clinic, {
    flex-grow: 1;

    .validation-entity {
      display: flex;

      & > div {
        flex-grow: 1;
      }
    }
  }


  .protocols-tab__list {
    [data-cant-delete="true"] {
      .col__optionDelete {
        display: none;
      }
    }
  }
}
