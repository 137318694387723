.m-range {
  display: flex;
  height: 30px;
  border: 1px solid $grey-light-border;
  padding: 0 7px;
  align-items: center;
  border-radius: $border-radius-base;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  .header {
    border-right: 1px solid $grey-light-border;
    padding: 0 7px 0 0;
    font-style: italic;
    color: $grey-light;
  }

  .el-slider {
    flex: 1;
    margin: 0 13px;
    width: 100px;

    .el-slider__runway {
      height: 4px;
      margin: 13px 0;

      .el-slider__bar {
        height: 4px;
        background-color: $primary-color;
      }

      .el-slider__button-wrapper {
        height: 34px;

        .el-slider__button {
          border: 2px solid $primary-color;
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .range {
    border-left: 1px solid $grey-light-border;
    padding: 0 0 0 7px;
  }

  &:hover {
    border-color: $grey;
  }

  &:focus {
    outline: none;
    border-color: $primary-color;
  }

  &.disabled {
    background-color: $primary-background;
    border-color: $el-grey;
    color: $grey;
    cursor: not-allowed;

    .el-slider {
      .el-slider__runway {
        .el-slider__bar {
          background-color: $grey;
        }

        .el-slider__button-wrapper {
          .el-slider__button {
            border-color: $grey;
          }
        }
      }
    }

    &:hover {
      border-color: $grey;
    }

    &:focus {
      outline: none;
      border-color: $primary-color;
    }
  }
}
