.personal-checkup-vaccinations-and-diseases {
  @media screen and (max-width: 1600px) {
    .m-panel__body {
      flex-direction: column !important;
    }

    &__item {
      flex-basis: 100%;
      max-width: calc(100% - $indent) !important;
    }
  }

  .m-panel__body {
    display: flex;
    flex-direction: row;
    gap: $indent;
  }

  &__item {
    flex-basis: 50%;
    max-width: calc(50% - ($indent / 2)); // для размещения двух MSIGenerator в ряд c отступами
  }

  .m-table__header {
    height: 0;
  }
}
