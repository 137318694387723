.common-select
  .el-select
    height: inherit
    .el-select__tags
      height: inherit
    .el-input
      height: inherit
      font-size: inherit
      .el-input__inner
        height: inherit
      .el-input__suffix
        display: flex
        align-items: center
