.feedback-module {
  padding-top: $indent-small;
  height: calc(100% - 35px);
  display: flex;
  flex-direction: column;

  .feedback-filters {
    display: flex;
    gap: $indent-mid;
    flex-wrap: wrap;

    .m-date-picker {
      max-width: 230px;
    }

    .feedback-filters__external-links {
      .el-input__inner {
        min-width: 240px;
      }
    }
  }

  .col__clientComment {
    min-width: 300px;
  }

  .m-table__header {
    .col__urls {
      .m-icon {
        margin-left: $indent-mid;
      }
    }
  }

  .m-table__body {
    .col__urls {
      .m-link {
        margin-right: $indent-mid;
      }
    }
  }

  .m-table__footer {
    .col__clientComment {
      justify-content: right;
    }
  }
}

.feedback-modal {
  .el-dialog {
    width: 600px;

    .el-dialog__body {
      flex-direction: column;

      .feedback-modal__label {
        color: lighten($primary-font-color, 5%);
        font-weight: bold;
        text-align: center;
      }

      .feedback-modal__client-comment-label {
        position: relative;
      }

      .feedback-modal__phone-value {
        width: fit-content;
        padding-top: $indent-small;
        padding-left: $indent-small;
      }

      .feedback-modal__scores {
        display: flex;
        justify-content: space-around;
      }

      .feedback-modal__client-comment-text {
        border: 1px solid $grey-light-border;
        border-radius: $border-radius-base;
        padding: $indent-small;
        min-height: $element-height;
      }
    }
  }
}
