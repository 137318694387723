.m-button
  text-transform: uppercase
  display: flex
  justify-content: center
  align-items: center
  padding: 0 $indent
  border-radius: $border-radius-base

  &.is-circle
    height: $element-height
    width: $element-height
    border-radius: 50%

  &.el-button--danger:not(.is-disabled):hover
    .m-icon
      color: white

  &.el-button--mini
    font-size: 12px

  &.el-button--primary
    .m-icon
      color: $primary-color

    &:hover,
    &:focus
      .m-icon, .m-button__text
        color: white

  span:not(.m-button__text)
    display: flex
    align-items: center

  .m-button__text
    // нормально не выравнивается flex
    margin-top: 1px

  &.button-with-icon
    max-height: $element-height
    min-width: 55px

    .m-button__text.use-indent
      margin-left: $indent-small

  &.el-button--small
    min-height: $element-height
    max-height: $element-height

    &.is-circle
      padding: 6px
      min-width: unset

  &.el-button--mini
    min-height: $element-height-small
    max-height: $element-height-small
    padding: 7px
    min-width: 35px

    .m-icon
      font-size: $icon-font-size-mini

  &.m-button__dark
    color: white
    background-color: $grey-dark
    border-color: $grey-dark

    &:not(.is-plain):hover
      opacity: 0.85

    &.is-plain
      color: #475264
      background: #edeef0
      border-color: #b5bac1

      &:hover
        color: white
        background-color: $grey-dark
        border-color: $grey-dark

    &.is-disabled
      opacity: 0.75

  @media print
    display: none

  &[fill="fill"]
    .m-icon
      color: white
