@import './variables'

.appointment-type-preview__cell
  height: 26px
  border: $border
  border-top: none

.appointment-type-color-setting
  .form-label
    text-align: center !important
