.emessage-top-left,
.emessage-top-right
  transform: none
  align-items: baseline
  max-width: 350px

  .el-message__content
    margin-left: 5px

  .el-message__closeBtn
    top: unset
    right: 6px
    font-weight: bold

.emessage-top-right
  left: unset
  right: 20px

.emessage-top-left
  left: 20px
  right: unset
