#scheduler_container
  .user-list-container, .cabinet-list-container
    display: flex
    flex-direction: column
    flex-basis: 210px
    padding-bottom: 5px
    .panel-heading
      .show-left-schedule-panel
        display: none
    .rolled-content
      display: none
    .panel-body
      padding: 5px
      height: 100%
      overflow-y: scroll
      .inner-container
        height: 100%
        overflow-y: visible

  .user-list-container.rolled, .cabinet-list-container.rolled
    flex-basis: 30px
    .panel-heading
      .hide-left-schedule-panel, .panel-title, .panel-icon
        display: none
      .show-left-schedule-panel
        display: flex
    .rolled-content
      display: block
    .panel-body
      display: none

  .schedule-grid-container
    padding-bottom: 5px
    .panel-body
      display: flex
      padding: 5px
      overflow-y: hidden
      padding-right: 0px
      .inner-container
        width: 100%
        overflow: visible
        position: relative
        .schedule-grid__permission-overlay
          position: absolute
          width: 100%
          height: 100%
          left: 0
          top: 0
          z-index: 2
          background-color: $white-overlay
          cursor: not-allowed

        .btn-container
          display: flex
          flex-direction: row

  .main-item-right
    flex-basis: 225px
    .panel-heading
      .show-right-schedule-panel
        display: none
    .rolled-content
      display: none

  .main-item-right.rolled
    flex-basis: 30px
    .panel-heading
      .hide-right-schedule-panel, .side-menu-caption
        display: none
      .show-right-schedule-panel
        display: flex
    .rolled-content
      display: block
    .schedule-right-panel
      display: none
