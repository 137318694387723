.clinic-logotypes {
  .max-file-size {
    font-style: italic;
    margin-bottom: $indent-mid;
  }

  .logo-container {
    margin-bottom: $indent;
  }

  .title {
    font-weight: 700;
    font-size: $primary-font-size;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    margin-bottom: $indent;
  }

  .logo-not-found {
    display: flex;
    align-items: center;
    position: relative;
  }

  .fact {
    margin-left: $indent-mid;
  }

  .logotype {
    .img-logo {
      max-width: 100%;
      height: auto;
      max-height: 150px;
      min-height: $element-height;
    }

    .upload-control {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .upload-control:hover:before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .3);
      position: absolute;
    }
  }

  .widget-logotype {
    .img-logo {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }

    .upload-control {
      max-width: 150px;
      height: 150px;
      background: $primary-background;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .upload-control:hover:before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .3);
      position: absolute;
      border-radius: 100%;
    }
  }

  .control {
    display: flex;
    position: absolute;
  }
}
