#doctor_areas_page
  #single_schedule
    display: flex
    flex-direction: column
    flex-basis: 270px
    overflow-y: hidden

    .panel-heading
      display: flex
      align-items: center
      justify-content: space-between
      min-height: 34px
      .right-panel-title
        display: inline-block
      .change-day-buttons
        display: none
      #week-schedule-grid
        border-radius: 2px
        padding: 4px 7px 2px 7px
        margin: 0
        font-size: 10px
        color: white

    .panel-body
      padding: 10px
      overflow-y: hidden
      height: 100%

      .inner-container
        display: flex
        flex-direction: column
        position: relative
        height: 100%
        #schedule-wrapper
          display: flex
          flex-direction: column
          height: 100%
          overflow: hidden
          .schedule-appearance-grid_step
            margin-bottom: 5px
            .select2Buttons
              text-align: center
              margin-left: unset
              .select-buttons
                display: flex
                flex-wrap: wrap
                justify-content: center
                margin: 0
                text-align: center
                li
                  min-width: 35px
                  margin: 2px
                  a
                    min-width: 35px
                  a.picked
                    background-color: $primary-color

          #show-calendar-button
            display: inline-block
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            position: relative
            width: 35px
            height: 35px
            background-color: #EFEFEF
            top: 33px
            left: 1px
            z-index: 20
            border: 1px solid $grey
            border-radius: 2px
            margin-top: -33px
            cursor: pointer
            &:hover
              background-color: #e2e2e2
          #schedule_block
            overflow-x: hidden
            overflow-y: scroll
            .schedule-free-record
              cursor: auto
            .schedule-not-work-record
              cursor: auto
            .schedule-free-record:hover
              outline: none

        +waiting-list-component

        #btn-trigger-group
          width: 100%
          .doctor-areas-trigger
            align-items: center
            display: flex
            padding: 6px 15px
            .trigger-group-title
              text-align: left

          .side-menu-trigger
            border-radius: 2px
            text-align: center
            cursor: pointer

            .trigger-icon, .trigger-group-title
              color: white
            .trigger-group-title
              font-size: 15px
            .trigger-icon
              margin-right: 5px
            .item-counter
              float: right
              display: inline-block
              height: 18px
              min-width: 31px
              padding: 3px 7px
              font-size: 12px
              font-weight: bold
              line-height: 1
              color: #fff
              text-align: center
              white-space: nowrap
              vertical-align: middle
              background-color: #777
              border-radius: 10px
          .schedule-trigger
            margin-bottom: 3px
            background-color: $primary-color
          .waiting-list-trigger
            background-color: $orange
          .active
            background-color: $grey-dark

        .doctor-areas__waiting-list-container
          height: 100%
          margin-bottom: 5px
          overflow: hidden
          #waiting-list-wrapper
            height: 100%
