$main-color: #ff5722

.validation-wrapper
  display: flex
  flex-direction: column

  .errors-content
    display: flex
    color: $main-color
    font-size: small
    font-style: italic

    .messages
      flex-grow: 1
      width: 0
      word-wrap: break-word

  &.fixed-height
    padding-bottom: 18px
    position: relative

    .messages
      bottom: 0
      position: absolute
      overflow-x: hidden
      width: 100%
      white-space: nowrap

  &.m-fixed-height
    padding-bottom: $indent-validation
    position: relative

    .messages
      bottom: 9px
      position: absolute
      overflow-x: hidden
      width: 100%
      white-space: nowrap
      font-size: 12px

.invalid-background
  background-color: rgba(255, 0, 0, 0.1) !important

  &:hover
    background-color: rgba(255, 0, 0, 0.2) !important

.validated__flex-form
  margin-bottom: 0

  .validated__form-label
    align-items: center
    display: flex
    justify-content: flex-end
    margin-top: -2px
    // выравние по строчным буквам
    padding-top: 0
    padding-bottom: 15px
  // fixed-height

  .validated__form-value
    padding-top: 0
    padding-bottom: 0

  .validated__form-label,
  .validated__form-value
    &.reset-paddings
      padding: 15px 5px
