.card-steps__table
  .col__created-at
    max-width: 110px

  .col__entries
    max-width: 100px

  .col__total
    max-width: 100px

  .col__register
    max-width: 135px

.step-services__table
  .col__date
    max-width: 90px

  .col__price
    max-width: 120px

  .col__options
    color: $primary-color
    cursor: pointer
    font-size: 16px
    max-width: 60px
    a
      text-decoration: none

.step-services__total
  width: 100%

.step-form
  .step-form__footer
    display: flex
    justify-content: space-between

  .step-form__client-label,
  .step-form__clinic-label
    padding-top: 4px !important
    padding-bottom: 15px

.col__options-popover
  .options-popover__message
    .message__icon
      margin-right: 10px
      margin-top: 2px
