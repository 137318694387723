@import "@/../sass/abstract/variables.sass"

.overpop-icons#AdministratorOverpopIcons
  height: 20px
  margin-top: $indent-small
  margin-bottom: $indent-small

  .overpop-icons__container
    height: 16px
    width: 100%
