.doctor_areas_page
  
  #form_waiting_list_modal
    .modal-dialog
      width: 90%
      .modal-content
        .modal-body
          .modal_form_appointment-new-container
            display: flex
            .appointment-column-client
              width: 30%
              #clients_form
                .form-left-containter
                  flex-basis: 100%
                .form-right-containter
                  display: none
            .appointment-column-client-match
              width: 40%
              padding: 0 10px 0 5px
              .appointment-similar-clients-container
                .client-form-with-similar-header
                  padding: 3px
                  text-align: center
                  margin-bottom: 5px
                  background: #e9a958
                  color: white
                  font-weight: bold
                table
                  tbody
                    .f-si-item
                      display: flex
                      .similar-clients-list-fullname
                        flex-grow: 1
              .appointment-client-info-container
                .client-form-with-similar-header
                  padding: 3px
                  text-align: center
                  margin-bottom: 5px
                  background: #e9a958 
                  color: white
                  font-weight: bold
                .client-info-list
                  .show_info_table
                    tbody
                      tr
                        .service-card-title
                          text-align: center
                          border: none
                          border-top: 1px solid $grey
                          border-bottom: 1px solid $grey
                        .medium-editor-show
                          border: none
                          text-align: left
                          display: table-cell
                          font-size: 12px
                          background-color: white
                          font-weight: normal
            .appointment-column-params
              width: 30%
          

