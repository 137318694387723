
.m-table {
  $scrollbar-shift: 7px; // выравнивание по скроллбару

  border-radius: $border-radius-base;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  overflow: auto;

  &.use-hidden-scrollbars {
    .m-table__body {
      overflow: hidden;
    }
  }

  .m-table-row {
    display: flex;
    min-height: $table-row-height;

    &:nth-of-type(odd) {
      background-color: $table-striped-odd;
    }

    &:hover {
      background-color: $table-striped-hover
    }
  }

  .m-table__header {
    border-radius: $border-radius-base;
    border-bottom: 1px solid $el-grey;
    font-weight: bold;

    .m-table-row {
      border-radius: inherit;
      background-color: unset;

      &:hover {
        background-color: unset;
      }
    }

    .m-table-row_sortable {
      background-color: $table-header-color;

      &:hover {
        background-color: $table-header-color;
      }

      .m-table-row-cell_sortable:first-child {
        border-top-left-radius: $border-radius-base;
      }
    }
  }

  .m-table__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: scroll;

    .m-table-row {
      &.is-active {
        background-color: $primary-background-color-dark !important;
        color: $primary-color;
      }
    }

    .darkest-green {
      color: $green-active;
    }
  }

  .m-table__footer {
    border-top: 2px solid $primary-color;
    cursor: default;
    display: flex;
    flex-direction: column;
    font-weight: bold;

    .m-table-row {
      background-color: unset;

      &:hover {
        background-color: unset;
      }
    }
  }

  .m-table__header,
  .m-table__footer {
    .m-table-row {
      padding-right: $scrollbar-shift;
      @media print {
        padding-right: 0;
      }
    }
  }

  .m-table-row-cell {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: $row-cell-basis;

    padding: $indent-small $indent-mid;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.col.col__optionEdit,
    &.col.col__optionDelete,
    &.col.col__tableCheckbox {
      flex-grow: 0;
      flex-basis: 40px;
      @media print {
        display: none;
      }
    }

    &.col.col__optionDelete {
      flex-basis: 30px;
      margin-right: 5px;
    }

    &.col.col__optionEdit {
      flex-basis: 30px;
    }

    &.cut {
      .m-table-row-cell__struct {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &.m-table-row-cell_sortable {
      background-color: $table-header-color;
      position: relative;

      .sort-state {
        --sorting-opacity: 0.3;
        --fa-primary-opacity: var(--sorting-opacity);
        --fa-secondary-opacity: var(--sorting-opacity);
        --fa-primary-color: $primary-font-color;
        --fa-secondary-color: $primary-font-color;

        @media print {
          display: none
        }


        &.sort-state_active {
          --sorting-opacity: 1
        }

        &.sort-state__container {
          position: absolute;
          right: $indent-small !important;
          top: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
}

@media print {
  .m-table {
    display: table;
    height: unset;
    page-break-inside: auto;

    .m-table__caption {
      display: table-row;
    }

    .m-table-row {
      page-break-inside: avoid;
      page-break-after: auto;
    }

    .m-table-row-cell {
      &.cut {
        .m-table-row-cell__struct {
          overflow: initial;
          text-overflow: initial;
          white-space: initial;
        }
      }
    }

    .m-table__header,
    .m-table__body {
      height: unset;
      flex-grow: unset;
    }

    .m-table__body {
      overflow-y: auto;
    }
  }
}
