=appointment-types-mixin
  .schedule-grid-appointment-status-need-approval.online-recording-source
    background-color: $appointment-status-need-approval

  .schedule-grid-appointment-status-approved
    background-color: $appointment-status-approved

  .schedule-grid-appointment-status-canceled
    background-color: $appointment-status-canceled

  .schedule-grid-appointment-status-billed
    background-color: $appointment-status-billed

  .schedule-grid-appointment-status-paid
    background-color: $appointment-status-paid

  .schedule-grid-appointment-status-serviced
    background-color: $appointment-status-serviced

  .schedule-grid-appointment-status-failed
    background-color: $appointment-status-failed

  .schedule-grid-appointment-status-arrived
    background-color: $appointment-status-arrived

  .disabled-appointment
    background-color: gainsboro
    opacity: .7
