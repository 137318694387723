.base-header {
  display: flex;
  margin-bottom: $indent-validation;

  &__info {
    flex-basis: 60%;
  }

  &__logo {
    flex-basis: 40%;

    img {
      max-width: 300px;
      max-height: 300px;
    }
  }
}

@mixin checkup-document-field-mixin {
  display: flex;
  margin-bottom: $indent-mid;

  &__title {
    white-space: nowrap;
  }

  .document-field-writable__input {
    width: 100%;
  }
}

.checkup-document-field {
  @include checkup-document-field-mixin;
  gap: $indent;

  &_column {
    @include checkup-document-field-mixin;
    flex-direction: column;
    gap: $indent-small;
  }
}

@mixin checkup-document-inline-field-mixin {
  display: flex;
  gap: $indent-small;
  margin-bottom: $indent-mid;
}

.checkup-document-inline-field {
  @include checkup-document-inline-field-mixin;

  &_column {
    @include checkup-document-inline-field-mixin;
    
    flex-direction: column;
    align-items: flex-start;
  }
}

.checkup-document-table-container {
  page-break-inside: avoid;
  display: flex;
  flex-direction: column;
  gap: $indent-mid;
}

.checkup-document-header {
  text-align: center;
  text-decoration: underline;
  width: 100%;
  color: $primary-font-color;
}
