.online-recording-list-transition-enter-active
  transition: all 200ms linear, opacity 300ms linear
.online-recording-list-transition-leave-active
  transition: all 200ms linear, opacity 100ms linear

.online-recording-list-transition-enter,
.online-recording-list-transition-leave-to
  max-height: 0 !important
  opacity: 0 !important
  transform: scaleY(0)
  padding-top: 0 !important
  padding-bottom: 0 !important

=online-recording-list-component
  #online-recording-list-wrapper
    display: flex
    flex-direction: column
    position: relative
    height: 0
    flex-basis: 100%
    .online-recording-add-btn
      margin: 0
    .online-recording-list-control-element
      width: 100%
      margin-bottom: 5px
      .online-recording-list-only-need-approval-appointments
        display: flex
        justify-content: center
        align-items: center
        width: 100%
        height: 30px
        cursor: pointer
        .checkbox
          height: 16px
          width: 14px
          cursor: pointer !important
        .checkbox.disabled:checked:before
          border-color: #DEDCDC
        label
          margin-bottom: 0
          cursor: pointer

    .online-recording-list
      height: 100%
      overflow-y: scroll
      ul
        list-style: none
        margin: 0
        padding: 0
        li
          padding: 5px
          cursor: pointer
          border-bottom: 1px solid $grey
          overflow: hidden
          max-height: 47px
          &:hover
            background-color: #fdebf1
          .date-client-line
            text-align: left
          .doctor-line
            display: flex
            justify-content: space-between
            align-items: center
            .doctor-line-short-name
              display: inline-block
              width: 100%
              text-align: left
            .online-recording-list-command-icons
              display: flex
              min-height: 18px
              min-width: 50px
              max-width: 50px
              margin-left: 5px
              border-radius: 2px
              cursor: pointer
              font-size: 14px
              align-items: center
              .online-recording-list-command-icon
                display: flex
                justify-content: center
                width: 100%
                cursor: default
                .fad
                  color: $primary-font-color
                &:hover
                  .fad
                    color: $crimson

              .online-recording-list-command-icon.disabled
                background-color: transparent
                .fad
                  color: #DEDCDC
                &:hover
                  .fad
                    color: #DEDCDC
