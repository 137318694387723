.fixed_doc_form_container.f_289 {
    .filters {
        .params {
            flex-wrap: wrap;
        }

        .selectors {
            .clinics_container {
                width: 200px;
                margin-right: 5px;
            }

            .clients_container {
                width: 200px;
            }
        }

        .printed-receipts-only-checkbox {
            margin: 5px;
        }
    }
}
