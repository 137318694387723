@import 'discount_form'

td.tax-scheme,
td.tax-scheme-sum,
th.tax-scheme,
th.tax-scheme-sum
  min-width: 90px
  width: 90px
  word-wrap: normal
  overflow-wrap: normal

td.entry-comment,
th.entry-comment
  width: 180px !important
  min-width: 180px
