.document-dates
  max-width: 420px

  .date-container
    align-items: center
    display: flex
    margin-bottom: 10px

    .date-container__label
      flex-basis: 170px
