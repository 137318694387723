@import "FinanceModuleIndex";
@import "OrdersQuickAddition";

.f-order-entry-tax-scheme-sum {
  margin-left: 0;
}

#order_entries_area {
  .f-order-entry-tax-scheme {
    min-width: 80px;
    @media print {
      min-width: unset;
    }
  }

  .f-order-entry-tax-scheme-sum {
    justify-content: left;
    min-width: 80px;
    @media print {
      min-width: unset;
    }
  }
}

#order_entries_table_container {
  .f-order-entry-tax-scheme-sum {
    text-align: center;
  }
}

.entry-tax-scheme {
  text-align: center;
}
