.yes-no
  label
    margin-bottom: 0

  .yes-no-radio
    .el-radio-button__inner
      width: 50px
      &:hover
        color: $primary-color

    &.is-active
      .el-radio-button__inner
        background-color: white
        border-color: $primary-color
        box-shadow: -1px 0 0 0 $primary-color
        color: $primary-color
      &:hover
        .el-radio-button__inner
          color: $primary-color
