.filters-profiles {
  border: $border-base;
  border-color: $grey;
  padding: $indent $indent-small;
  border-radius: $border-radius-base;
  background-color: #f9f9f9;

  &__name-input {
    width: 100%;
    margin-top: 12px;
  }

  &__delete-btn {
    margin-left: auto;
  }

  &__self-checkbox {
    display: inline-flex;
    margin: $indent-mid 0 0 $indent-small;
  }
}
