.documents_page {
  .print-preview-page-center {
    border-right: 1px dashed $grey;
    padding-right: $indent;
    @media print {
      border-right: none;
      padding-right: 0;
    }
  }
}

.fixed-document__container {
  background-color: white;
  padding: $indent;
  border-radius: $border-radius-ext-borders;
  box-shadow: $box-shadow;
}

.documents_edit_page,
.documents_new_page {
  .panel-body {
    position: relative;
    display: flex;
    overflow-y: hidden;
    padding: 0;
    flex-direction: column;

    background-image: url('assets/hip-square.png');
    @media print {
      background-image: unset;
    }

    .fixed_doc_form_container {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      padding: 0 $indent 0;
      margin-top: $indent;
      @media print {
        padding: 0;
        margin: 0;
        overflow: initial;
      }
    }

    #fixed_doc_form,
    .old-document-footer-app {
      background-color: white;
      box-shadow: $box-shadow;
    }

    #fixed_doc_form {
      border-top-right-radius: $border-radius-ext-borders;
      border-top-left-radius: $border-radius-ext-borders;
      border-left: none;
      border-right: none;
      max-width: 220mm;
      padding: 6mm 7mm 6mm 6mm;
      width: 100%;
      flex-grow: 1;

      @media print {
        padding: 0;
      }
    }

    .old-document-footer-app {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 220mm;
      min-width: 220mm;

      gap: $indent-small;
      margin: 0 calc((100vw - 220mm) / 2 - 3px);
      padding: $indent;
      border-bottom-right-radius: $border-radius-ext-borders;
      border-bottom-left-radius: $border-radius-ext-borders;
    }

    .fixed_document_back_btn_top {
      background-color: white;
      padding: $indent;
      box-shadow: $box-shadow;
      border-radius: $border-radius-ext-borders;
      margin: $indent calc((100vw - 220mm) / 2 - 3px) 0;
    }
  }
}

#document_form {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  flex-grow: 1;

  .form-body {
    flex-grow: 1;
    display: flex;
    overflow-y: hidden;
    flex-direction: column;
    align-items: center;

    .document_title {
      width: 211mm;
    }

    #document_editor {
      overflow-y: scroll;
      flex-grow: 1;
    }

    .form-body__container {
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      padding: $indent;
      background-color: white;
      margin: $indent;
      box-shadow: $box-shadow;
      border-radius: $border-radius-ext-borders;
      flex-grow: 1;

      .form-body__footer {
        display: flex;
        gap: $indent-small;

        .js_redirect_back {
          margin-top: 0;
          font-size: 14px;
          text-transform: uppercase;
          height: 33.81px;
          width: unset;
          padding: 6px 12px;
          border-radius: $border-radius-base;
        }
      }
    }
  }
}

#document_editor_toolbar {
  display: block !important;
  padding-left: 1px;

  .mce-tinymce-inline {
    display: block !important;
  }

  .mce-tinymce-inline {
    width: 210mm !important;
  }

  height: 105px !important;
}

#document_editor {
  outline: none;
  padding: $indent;
  border: 1px solid $grey;
  border-top: none;
  width: calc(210mm + 3px);

  p {
    margin: 0;
    font-size: 12px;
    word-break: break-word;
  }
}

.doc-body {
  .print-preview-page-center {
    max-width: 210mm;
    @media print {
      max-width: unset;
    }
  }
}

.form_074_y {
  .old-document-footer-app {
    min-width: 297mm !important;
    margin: 0 calc((100vw - 297mm) / 2 - 3px) 0 !important;
  }
}
