#order_goods_list
  display: flex
  flex-grow: 1
  height: inherit

  .si
    width: 100%

  .main-item-left
    .panel-heading
      display: flex
      align-items: center
    .panel-body
      tr
        cursor: pointer

  .panel.panel-primary
    display: flex
    flex-direction: column
    flex-grow: 1

  .order_goods_list_wrapper
    display: flex
    flex-direction: column
    height: inherit

  .order_goods_si_column
    flex: 1
    word-break: break-word

  .measure_unit
    flex-grow: 0.75

  .amount
    flex-grow: 0.45

  .critical_amount
    flex-grow: 1.1

  .list-index-panel
    .list-index-header
      color: $turquoise !important

  @media print
    .order_goods_list_wrapper
      display: flex
      flex-direction: column
      height: inherit

    .order_goods_si_column
      flex: 1
      word-break: break-word

    .si-table
      display: flex
      overflow-y: hidden
      flex-direction: column
      flex: 1

      .header
        font-weight: 600
        padding-right: 8px
        border-bottom: 1px solid #efefef
        box-shadow: -8px -5px 5px 6px #eee
        z-index: 1

      .body
        overflow-y: scroll
        flex: 1
        position: relative

      .footer
        display: flex
        padding-right: 8px
        z-index: 1

      .si-field
        display: flex
        padding: 5px

#search_stocks,
#receipt_search_document_by_good_name,
#receipt_search_document
  border: none
  border-bottom: 1px solid $el-grey
  border-radius: 0
  &:focus
    border-color: $primary-color
