.changelog-container
  display: flex
  height: 100%
  width: 100%

  .changelog-container__list
    $block-width: 400px
    overflow-y: scroll
    width: 100%

    @media screen and (min-width: 650px)
      min-width: $block-width - 100px
      width: $block-width - 100px
      margin-right: 15px

    @media screen and (min-width: 900px)
      min-width: $block-width
      width: $block-width

    .changelog-card
      background-color: white
      border: 1px solid #EBEEF5
      border-radius: 4px
      cursor: pointer
      margin-bottom: 10px
      margin-right: 15px
      padding: 10px
      user-select: none

      @media screen and (min-width: 900px)
        padding-right: 15px

      &.changelog-card_active
        background-color: #ecf9fe

      .el-card__header
        padding: 6px

      .changelog-card__title
        display: flex
        font-size: 16px
        font-weight: bold
        margin-bottom: 5px

        .changelog-card__title-text
          color: black
          max-width: $block-width - 100px
          text-overflow: ellipsis
          white-space: nowrap
          overflow: hidden

  .changelog-container__viewer
    font-size: 14px
    overflow-y: auto
    width: 100%

    @media screen and (min-width: 900px)
      padding-right: 15px

    .viewer-close-container
      display: flex
      align-items: center
      padding: 10px 0 15px

      .viewer-close__icon,
      .viewer-close__text
        font-size: 16px
        color: $red

    .viewer-header__title
      color: black
      font-size: 20px
      font-weight: bold
      margin-bottom: 10px

    .viewer-header__labels
      display: flex
      flex-wrap: wrap
      margin-bottom: 15px

    .viewer__viewer
      max-width: 700px
      img
        max-width: 100%

  .changelog-badge
    border-radius: 5px
    color: white !important
    font-size: 12px !important
    font-weight: bold !important
    line-height: 1
    margin: 0 6px 6px 0
    padding: 4px 5px
    text-transform: uppercase
