$si-field-active-color: #d3eef585
$error-text-color: #e94437
$offset: 8px

.si-table
  position: relative
  overflow: auto
  display: flex
  flex-direction: column
  flex: 1
  .header
    font-weight: bold
    padding-right: $offset

  &.dashed
    .body
      .si-field
        > div
          border-right: 1px dashed $grey
          // override because of high specificity of #medods selector
          // see app/javascript/sass/application.sass
          align-items: stretch !important
          &:last-child
            border-right: none

  &.striped
    .body .si-field
      &:nth-child(odd)
        background-color: $table-striped-odd
      &:hover
        background-color: $table-striped-hover


  &.horizontal
    margin-bottom: 1rem
    .header
      min-width: max-content
      position: sticky
      top: 0
      z-index: 10
      padding-right: 0

    .body
      overflow-y: initial
    .footer
      position: sticky
      bottom: 0
      background-color: #fff
      margin-bottom: 0
      // в обычной таблице 8px, в горизонтальной это сбивает колонки
      padding-right: 0
    .si-field
      max-width: initial
      > div
        flex-shrink: 0
        min-width: var(--size)

  .body
    overflow-y: scroll
    flex: 1
    position: relative

    .error
      padding: 8px 5px
      .error-message
        color: $error-text-color
        font-style: italic

    .not-found
      padding: 8px 5px
      .not-found-message
        font-style: italic
    .si-not-found
      .si-not-found__message
        font-style: italic
      .si-not-found__controls
        margin-top: 4px
        .btn
          margin: 0
          font-style: normal

      &.active-field
        background-color: $si-field-active-color
        &:nth-of-type(odd)
          background-color: $si-field-active-color
        &:hover
          background-color: $si-field-active-color
      &.section-totals
        background: $green
        color: white
        .report-column-locked
          background-color: $green
      .report-column-locked
        align-self: stretch
        background-color: $primary-background

  .footer
    display: flex
    padding-right: $offset
    z-index: 1
    &:not(:empty)
      border-top: 2px solid $primary-color
      margin-bottom: 16px

  .si-field
    align-items: center
    > div
      padding: 5px
      padding-left: 10px
    .checkbox:after
      top: 4px
      background-color: transparent
      visibility: initial
    .checkbox:before
      margin-top: 4px
      visibility: initial
    .checkbox
      cursor: pointer
      visibility: hidden
      &:disabled
        cursor: not-allowed
        &:after
          background-color: #ededed
      &:nth-of-type(odd)
        background-color: #f9f9f9
      &:hover
        background-color: $primary-background-color

    .paid-status
      flex-grow: 3
      flex-basis: 150px
      flex-shrink: 0
      &--fully
        color: #8BC34A
      &--not
        color: #ff5722
      &--credit
        color: #e8a84b
      &--free
        color: inherit
    &.active
      background-color: $primary-background-color-dark !important
      color: $primary-color !important
      i
        color: $primary-color !important
        &.fa-check-circle
          color: $success !important
@media print
  .si-table
    max-width: 100vw !important
    overflow-x: hidden !important

    .header
      padding-right: 0

    .body
      overflow: visible

    .footer
      padding-right: 0
