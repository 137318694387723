@import 'med_documents/_'
@import 'cards/_'
@import 'entries_history'
@import 'EgiszCases/_'

.egisz-case
  max-width: 1000px

  .egisz-case__card
    margin-bottom: 15px

    .panel-body
      padding: 0

      .egisz-case__flex-form
        margin-bottom: 0

        .egisz-case__form-label
          align-items: center
          display: flex
          justify-content: flex-end
          margin-top: -2px // выравние по строчным буквам
          padding-top: 0
          padding-bottom: 15px // fixed-height

        .egisz-case__form-value
          padding-top: 0
          padding-bottom: 0

          .el-select,
          .el-date-editor--date
            min-width: 236px

        .egisz-case__form-label,
        .egisz-case__form-value
          &.reset-paddings
            padding: 15px 5px

  .egisz-case__card_conclusion
    .conclusion__conclusion-text
      margin: 15px
      max-width: calc(100% - 30px)

    .conclusion__diagnosis-date
      .el-date-editor
        max-width: 215px


  .egisz-case__card_steps,
  .egisz-case__card_med-documents
    .panel-body
      padding: 10px

  .egisz-case__footer
    display: flex
