.m-alert {
  word-break: normal;

  .el-alert {
    min-height: 40px;

    &__description {
      margin-top: 0;
    }

    &[custom-type="primary"] {
      background-color: $primary-background-color;
      color: $primary-color;
    }

    &[custom-type="danger"] {
      background-color: $red-lighten;
      color: $red-light
    }

    &[custom-type="grey-dark"] {
      background-color: $grey-dark-background-color;

      .m-icon {
        color: $grey-dark
      }
    }

    &[size="small"] {
      min-height: 30px;
      padding-top: 0;
      padding-bottom: 0;

      .el-alert__icon {
        font-size: 20px;
      }
    }
  }
}
