#time_finder_modal
  // 7jt4kp fix inherit white text color
  color: $primary-font-color

  .modal-dialog
    width: 90%
    height: calc(100% - 50px)
    margin-top: 20px

    .modal-content
      height: 100%

      .modal-header
        .modal-buttons
          .modal-header-fullscreen
            font-size: 14px

      .modal-body
        overflow-y: hidden
        height: 100vh

    &.confirmation-modal
      .modal-content
        height: unset
