.entry-types-form
  .el-input__inner
    height: 28px!important
  .el-tag.el-tag--small
    height: 20px
  .vue-items-list .vue-list .vue-list-item
    background-color: #EFEFEF
    border: 1px solid #ccc
    color: #474747
    &:hover
      background: #e2e2e2
      border-color: #ccc
    &.active
      background: #3FAECA
      border-color: #3FAECA
      color: #ffffff
      &:hover
        background-color: #34a0bc
        border-color: #34a0bc
.hide-caret
  display: none