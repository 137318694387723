.fixed-document {

  .m-panel__body {
    align-items: center;
  }

  &__container {
    display: flex;
    flex-direction: column;
    max-width: max-content;
  }

  %dashed-border {
    $border-size: 2px;

    border-left: $border-size dashed $grey;
    border-right: $border-size dashed $grey;
  }

  &-dashed-border {
    @extend %dashed-border;

    padding-left: $indent-mid;
    padding-right: $indent-mid;
  }

  &-block {
    display: flex;
    flex-direction: column;
  }

  &-printable-container {
    $browser-default-print-page-margin: 0.4in; // дефолтный margin страницы при печати в хроме

    width: calc(210mm + $browser-default-print-page-margin * 2);
    padding: $browser-default-print-page-margin;
  }
}
