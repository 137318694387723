@import "styles/paymentPage";
@import "styles/acquiringModal";
@import "styles/acquiringFilters";

.finance-module {
  display: flex;
  flex-grow: 1;

  .finance-module__left-panel-menu {
    @media print {
      display: none;
    }

    margin-right: $indent-small;

    .m-panel__head {
      padding-left: $indent-mid;
    }

    .m-panel__body {
      padding: 0;

      .m-menu {
        overflow: visible;
      }
    }
  }

  .m-si-generator__table {
    max-width: 96vw;

    .col__kind {
      min-width: 35px;
    }
  }

  .expense-modal {
    .el-dialog__body {
      flex-direction: column;

      .validation-wrapper {
        width: 100%;

        .m-select {
          width: 100%;
        }
      }
    }
  }
}
