.stub-wrapper
  .stub-content
    cursor: pointer !important
    label
      cursor: pointer

  .stub-modal
    &.appointment-modal
      left: -5vw
      top: -20px

    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    justify-content: center
    height: 100vh
    position: fixed
    width: 100vw
    z-index: 1051

    .modal-title
      text-align: left

    .modal-dialog
      margin-top: 40px !important
      width: 700px !important

    .missing-item
      margin-bottom: 30px
      &:last-child
        margin-bottom: 0

      .missing-item__title
        align-items: baseline
        display: flex
        .title__icon
          color: #b8b8b8
          font-size: 18px
          margin-right: 5px

      .missing-item__body
        font-size: 15px
        text-align: left
        .missing-item__capabilities
          font-size: 15px

        .btn-light-outline
          background-color: white
          border: 1px solid #eaeaea
          color: $grey-dark !important
          &:hover
            border-color: darken(#eaeaea, 10%)

          i
            color: inherit

      .el-collapse-item
        .el-collapse-item__header
          font-size: 16px
          color: $primary-color !important
          i
            font-size: 14px
            margin-right: 5px
