@import 'MSelect'
@import 'MCheckbox'
@import 'MDatePicker'
@import 'MSearchSelectInputDeprecated'
@import "MSwitch"
@import "MInput"

.el-select-dropdown
  .option-icon
    top: 2px

  .el-select-dropdown__wrap
    max-height: 40vh

.m-input.el-input-group--append
  .el-input__inner
    border-top-right-radius: 0 !important
    border-bottom-right-radius: 0 !important

.m-input,
.m-select
  .el-input__prefix
    display: flex
    align-items: center

  .el-input__inner
    height: $element-height
    min-height: $element-height

    &:focus
      border-color: $primary-color

.m-option
  &.is-disabled
    font-style: italic

  height: auto
  .m-option__container
    line-height: 1.5
    padding-bottom: 6px
    padding-top: $indent-small
    &::after
      top: -4px

  .m-option__label
    white-space: normal

  .m-option__notice
    padding-top: 0
    padding-bottom: $indent-mid
    line-height: 1


.m-select
  &.m-select_search-style
    .el-input__inner
      border-top: none
      border-left: none
      border-right: none
      border-radius: 0

  .el-tag
    height: $m-select-tag-height
    line-height: $m-select-tag-height

  .el-select__tags
    .el-select__input
      height: $m-select-tag-height

.m-input.el-input-number
  width: 100%

@mixin m-input-border
  border: 1px solid $el-grey
  border-radius: $border-radius-base
  outline: none
  padding-left: $indent
  &:focus
    border-color: $primary-color

.m-input_like
  @include m-input-border

input.m-input
  height: $element-height
  min-height: $element-height
  @include m-input-border

.m-label.label-bordered.is-disabled
  color: $grey-light

  &:before
    background-color: #E4E7ED

.m-label.label-bordered
  padding: 0 2px
  position: absolute
  left: 8px
  top: -6px
  line-height: 11px
  font-size: 11px
  font-weight: bold
  letter-spacing: 1px
  z-index: 0
  color: lighten($primary-font-color, 5%)

  &:before
    display: flex
    content: ' '
    background-color: white
    width: 100%
    height: 1px
    position: absolute
    top: 6px
    left: 0
    z-index: -1

  &.is-focused
    transition: color 200ms ease-in
    color: $primary-color

.has-error
  .label-bordered
    color: $red

  & > .m-input > .el-input__inner,
  & > .m-date-picker
    border-color: $red

/* Для input */
.to-m-input
  border: 1px solid $el-grey
  border-radius: $border-radius-base
  padding-left: $indent-mid
  outline: none
  &:hover
    border-color: $grey
  &:active, &:focus
    border-color: $primary-color

.m-icon.m-label__clarification
  font-size: $small-font-size !important

.to-m-input::placeholder
  font-style: italic
  color: $grey-hover
