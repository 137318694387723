.analysis_laboratories_page
  .si-control
    margin-bottom: 10px

  .catalog-container
    .el-collapse
      .el-collapse-item__header
        height: 34px
        margin-bottom: 10px


.col__icon
  max-width: 33px

.flex-direction-column
  flex-direction: column

.labeller-fields-checkbox
  margin-bottom: 10px

  .panel
    box-shadow: none

    .panel-body::-webkit-scrollbar
      display: none

  .col__icon
    max-width: 33px

  .flex-direction-column
    flex-direction: column

  .labeller-fields-checkbox
    margin-bottom: 10px

  .errors
    input
      border: red 1px solid !important

  .clinics-auth-errors
    .messages
      color: $red

  .has-error
    .el-input__inner
      border-color: $red !important

.labels-numbers-range
  .labels-numbers-range__title
    font-weight: bold
    padding-bottom: $indent
    color: lighten($primary-font-color, 5%)

  .numbers-range__fields
    display: flex
    align-items: center

    .validation-wrapper
      margin-right: $indent

    .labels-numbers-left
      padding-bottom: $indent-validation
