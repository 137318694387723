.doctor_areas_schedule_page

  .schedule-grid-container
    margin-left: 0 !important
    .panel-heading
      .panel-heading-icon
        font-size: 17px
      .panel-heading-left-block
        .panel-title
          .title
            padding-right: 5px
      .panel-heading-buttons
        .main-item-entry-client-search
          margin: 0 5px

  #calendar-panel
    .panel-heading
      display: flex
      align-items: center
      justify-content: space-between
      min-height: 34px
      padding: 0 15px
      .right-panel-title
        display: inline-block

