.vue-modal-default-transition-enter-active
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0)

.vue-modal-default-transition-leave-active
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0)

.vue-modal-in.vue-modal-default-transition-enter,
.vue-modal-in.vue-modal-default-transition-leave-to
  transform: translateY(-10px)
  opacity: 0
  height: calc(100% + 10px)
  .modal-dialog
    padding-bottom: 10px

.vue-modal-in
  overflow: hidden
  background-color: rgba(0,0,0,0.5)
  display: block
  .modal-dialog
    --modal-offset: 80px
    margin: var(--modal-offset) auto var(--modal-offset) auto
    height: calc(100% - var(--modal-offset) - var(--modal-offset))
    max-height: calc(100% - 50px)
    &.modal-dialog-fullscreen
      max-height: 100%
      & > .modal-content
        height: 100%
        .modal-body
          height: 100%

    .modal-content
      .modal-body
        flex-grow: 1
        height: auto
        padding: 15px
        margin-bottom: 0
        overflow-y: scroll
      .modal-footer
        padding: 15px
        border-top: 1px solid #F6F6F6
