@media print {
  .payment-page {
    &__body {
      gap: 0 !important;
    }

    .m-form-show__label {
      min-width: 215px !important;
    }
  }
}
