.doctor_schedules_page, .doctor_areas_schedule_page
  .main-item-entry-client-search,
  .main-item-clinic-selector
    input.el-input__inner
      height: 24px
      min-height: 24px

  .main-item-clinic-selector
    .el-input__suffix
      .el-input__icon
        line-height: 24px
      .el-icon-arrow-up
        margin-top: 2px
        &.is-reverse
          margin-top: unset


  .main-item-entry-client-search
    input.el-input__inner
      width: 240px
    .el-input__suffix
      top: -3px


  #scan-plugin-extension-root
    right: 400px !important

  .scan-plugin-scan-button-container
    .scan-plugin-scan-button,
    .scan-plugin-scan-button + div
      border-radius: 2px !important
      background-color: $primary-color !important
      font-family: $primary-font
      height: 36px
      text-transform: uppercase
      span
        font-weight: normal !important
        font-size: 14px

      &:hover
        opacity: 0.9


  .ui-autocomplete
    &.client-search
      .ui-menu-item
        .item
          display: flex
          flex: 1
          .item-label
            flex: 1
          .item-laboratory
            width: 90px
          .item-price
            width: 60px

  .company-header-text, .company-header-logo
    display: none
  .main-container
    .main-item-left
      display: flex
      flex-direction: column
      flex-grow: 0
      flex-basis: 215px
      padding-left: 0
      padding-right: 0
      text-align: center
      .panel-heading
        padding-right: 10px
      .panel-body
        width: 215px
        padding-left: 7px
        padding-right: 3px
        .flex-doctors-container
          display: flex
          flex-direction: column
          overflow-x: hidden
          padding-right: 3px
          .fa-times
            top: -5px
          &:focus
            outline: none
          .jspHorizontalBar
            display: none
          .jspVerticalBar
            width: 5px
          .btn_roll_specialities
            float: right
            padding-top: 2px
    .cabinet-panel
      display: none
    .main-item-center
      flex-grow: 1
      margin-right: 5px
      margin-left: 5px
      overflow-x: auto
      overflow-y: hidden
      .panel-heading
        padding-bottom: 5px
        display: flex
        justify-content: flex-end
        .panel-heading-left-block
          flex-grow: 1
        .panel-heading-search-block
          flex-grow: 0
          #client_search
            color: white
            border-color: white
            width: 250px
            &::-webkit-input-placeholder
              color: white

        .panel-heading-cancel-block
          flex-grow: 0
          margin-right: 25px
        .panel-heading-right-block
          flex-grow: 0
          text-align: right
        .panel-heading-print-block
          .dropdown
            // 9aw23k
            .schedule_print_menu
              display: flex
              align-items: center
              height: 22px

            ul
              font-size: 13px

      .panel-body
        padding: 10px

    .main-item-right
      display: flex
      flex-direction: column
      flex-grow: 0
      flex-basis: 225px
      text-align: center

      .panel-heading
        .side-menu-caption
          display: none

        .doctor-areas-side-menu-caption
          display: flex

        // 7emu1n - fix aligning and colorizing doctor_schedules right panel-heading
        =side-menu-styles($color)
          height: 34px
          background-color: $color
          border-color: $color
          display: flex
          align-items: center

        #side-menu-caption-calendar
          +side-menu-styles($primary-color)

        #side-menu-caption-reminders
          +side-menu-styles($green)

        #side-menu-caption-waiting-list
          +side-menu-styles($orange)

        #side-menu-caption-online-recording
          +side-menu-styles($crimson)

      .panel-body
        overflow-y: auto
        padding-left: 7px
        padding-right: 7px
        .inner-container
          display: flex
          height: 100%
          flex-direction: column

    .main-item-left.rolled, .main-item-right.rolled
      width: 30px
      .panel-heading
        padding: 0
        height: 34px
      .panel-title
        display: none

      // fix icon hide
      .far,
      .fas,
      i,
      .fad,
      .fal,
      .fa
        display: none

      .panel-body
        display: none

      .btn_roll_scheduler-left, .btn_roll_scheduler-right
        margin: 7px 5px 5px 7px

    .main-item-right
      .btn_roll_scheduler-right
        margin: 7px 5px 5px 7px


  .rolled-content
    transform: rotate(90deg)
    margin-top: 20px
    white-space: nowrap

  #scheduler_info_area
    display: none
    position: absolute
    top: 1px
    right: 20px
    z-index: 2000
    min-width: 250px
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 5px 5px rgba(0, 0, 0, 0.12)

  #schedule_block
    overflow-y: scroll
    overflow-x: hidden
    border-radius: 2px
    background-color: white
    &:focus
      outline: none

  #schedule_client_window
    display: none
    background-color: white
    .form-control
      width: 100%

  .doctor_schedules_page .live_client_input_group
    width: 100%

  .schedule_inner_busy_record
    border-bottom-color: $green

  .ui-dialog
    border-radius: 2px
    border: 0px
    .ui-dialog-titlebar
      border-radius: 2px
      background: none
      background-color: $primary-color
      color: white
      border: 0px
      padding: 10px

  .schedule-not-work-record
    background: #F0F0F0
    border-color: #F0F0F0

  .scheduler_table_element
    text-align: center

  .scheduler_table_element:hover .scheduler_render_replace_sign
    color: green

  #schedule_table
    transition: opacity 0.2s ease-out

  #schedule_block_wrapper
    .modal-dialog
      width: 630px

  .scheduler_render_replace_sign
    color: $primary-color

  .schedule-free-record:hover
    outline: 1px solid $grey-dark

  .schedule-busy-record
    color: #eee
    background: $grey-dark
    border-bottom: 1px solid $grey-dark
    text-align: left
    padding-left: 20px
    &:hover
      color: white
      background-color: rgba(71, 82, 100, 0.85)
    .fad
      color: #eee
      font-size: 17px
      padding-bottom: 1px

  .schedule_busy_record_border_bottom
    border-bottom: 1px solid #eee

  #scheduler_modal
    .modal-footer
      margin-top: 0

  #schedule_cancel_paste
    padding: 2px 7px 2px 7px
    margin-right: 5px

  #client_full_name
    white-space: nowrap

  .client_full_name
    white-space: nowrap
    width: 210px

  .printable_scheduler_area-note, .printable_scheduler_area-entry_type
    max-width: 400px

  .capable-user-search, .capable-cabinet-search
    position: relative
    width: 100%
    margin-bottom: 1rem
    .form-control-feedback
      position: absolute
      background-color: #eee
      height: 29px
    .fa-times:before
      top: -5px
      position: absolute
      height: 0

  #reminder_form_submit_btn
    display: none

  #reminders_container
    min-height: 50px

  #form_waiting_list_modal
    .modal_form_appointment-new-container
      .client-form-with-similar-header
        background: #e8a84b
    .fs-appointment-date-label,
    .fs-appointment-date-container,
    .fs-appointment-time-label,
    .fs-appointment-time-container,
    .fs-appointment-user-label,
    .fs-appointment-user-container
      display: none

  .modal_form_appointment-new-container
    display: flex
    flex-wrap: nowrap

    .appointment-column-client
      flex-basis: 30%
      #clients_form
        .form-label
          flex: 0 0 35%
        .form-value
          flex: 1 1 65%
          max-width: 65%
          &#client_groups_value
            width: 200px

    .appointment-column-client-match
      flex-basis: 30%
      display: flex
      flex-direction: column

    .appointment-column-params
      flex-basis: 40%
      padding: 0 5px
      #appointment-form
        .form-label
          flex: 0 0 30%
        .form-value
          flex: 1 1 70%

    .client-form-with-similar-header
      padding: 3px
      text-align: center
      margin-bottom: 5px
      background: #3faeca
      color: white
      font-weight: bold

    .similar-clients-list-fullname
      width: 230px

    .similar-clients-list-phone
      display: none

    #clients_form
      .cient_additional_fields
        display: none

      .form-left-containter
        flex-basis: 100%
        padding-right: 10px

      .form-right-containter
        flex-basis: 0

    .appointment-column-client.show_right_form_container
      flex-basis: 60%
      #clients_form
        .cient_additional_fields
          display: initial
        .form-left-containter
          flex-basis: 50%
        .form-right-containter
          flex-basis: 50%
          padding-left: 0

    .switch_right_container_btn
      margin: 0 0 0 5px
      width: 20px
      height: 28px
      padding: 7px 0 0

  #form_company_modal
    z-index: 2000
    #company_submit_btn
      display: none

  .appointment-client-summary-table
    .service-card-title
      text-align: center
      border: none
      border-top: 1px solid #ccc
      border-bottom: 1px solid #ccc

    &.show_info_table
      td:first-child
        width: 80px
        font-size: 11px
        &.medium-editor-show
          border: none
          text-align: left
          display: table-cell
          font-size: 12px
          background-color: white
          font-weight: normal
      td.last_visit
        font-size: 11px

    .last_visit_to_the_same_doctor_days_ago
      color: $red-light

    .client-groups-list
      .label
        margin-right: 5px
        font-size: 11px
        max-width: 230px
        display: inline-block
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

  .special-marks
    font-size: 11px

  .previous_appointments_show-text
    color: $red-light
    text-decoration: underline

  .form-value.by_dms
    padding-left: 10px
    label
      font-weight: bold

  .search-caption
    background: #475264
    color: #fff
    cursor: default
    &:hover
      background: #475264

  .tippy-box
    .tippy-content
      .calculation-time
        color: #7b7b7b


  .client-birthday-picker
    @media screen and (max-height: 767px)
      left: 440px !important

  .client-passport-date
    @media screen and (max-height: 767px)
      left: 640px !important

.loading-ellipsis
  font-size: 13px
  min-height: 13px
.loading-ellipsis:after
  overflow: hidden
  display: inline-block
  vertical-align: bottom
  -webkit-animation: scheduler-ellipsis steps(4,end) 1500ms infinite
  animation: scheduler-ellipsis steps(4,end) 1500ms infinite
  content: "\2026" /* ascii code for the ellipsis character */
  width: 0

@keyframes scheduler-ellipsis
  to
    width: 1.25em
