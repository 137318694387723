#entry_list_area {
  #attachments-tabs {
    display: flex;
    flex-direction: column;
    width: 100%;

    .attachment-preview-inline-download,
    .print-attachment {
      display: none;
    }

    .m-si-generator {
      max-height: 100%;
    }

    [data-mime-type-print-js="false"] {
      cursor: default;
    }

    .el-tabs__header {
      margin-bottom: $indent-mid;

      .el-tabs__nav-wrap.is-top {
        padding: 0;
      }

      .el-tabs__item {
        height: $element-height;
        line-height: 28px;

        &.is-active {
          background-color: $primary-background-color;
        }
      }
    }

    .attachments-tabs-hyperlink-modal {
      &__body {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
