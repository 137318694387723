.personal-checkup-laboratory-and-instrumental-research {
  .m-panel__body {
    display: flex;
    flex-direction: column;
    gap: $indent;
  }

  .m-table__header {
    height: 0;
  }
}
