.clinical-guidelines-catalog {
  display: flex;
  gap: $indent-small;
  flex-grow: 1;

  &-tree {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__search-container {
      display: flex;
      gap: $indent-small;
    }

    &__actions {
      display: flex;
      gap: $indent-small;
    }
  }

  &-editor {
    &-entry-modal {
      .el-dialog__body {
        flex-direction: column;
      }
    }
  }

  &-import-modal {
    &__body {
      display: flex;
      flex-direction: column;
      gap: $indent-mid;
      width: 100%;
    }
  }

  &__line-container {
    display: flex;
    gap: $indent;

    .validation-wrapper {
      flex-basis: 50%;

      .m-select {
        width: 100%;
      }
    }

    .m-button-wrapper, .m-button {
      width: 100%;
    }
  }
}
