.clients_index_page {
  #clients_table {
    td.number,
    th.number {
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 120px;
    }
  }
}
