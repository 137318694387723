.messages-configurations {
  // Title
  .title {
    font-weight: bold;
    max-width: initial;
  }

  .title_huge {
    font-size: 15px;
  }

  .title_unifying {
    color: $grey-dark;
    margin-bottom: 24px;
    font-size: 14px;
  }

  // Explanation
  .explanation {
    font-style: italic;
    color: $grey-light;
  }

  .explanation_bold {
    font-weight: bold;
  }

  .explanation_small {
    font-size: 12px;
  }

  .explanation_solo {
    margin-bottom: $indent-validation;
  }

  .m-panel:first-child {
    flex: 0 0 25%;
  }

  .si-table .body {
    overflow-y: auto;
  }

  .whatsapp-common-settings-page {
    .webhook-button {
      display: flex;
      align-items: center;

      .webhook-status {
        margin-left: $indent-validation;
        font-weight: bold;
      }
    }

    .whatsapp-common-settings-page__patient-agreement {
      margin-bottom: $indent-validation;
    }

    .whatsapp-common-settings-page__sending-time {
      .el-date-editor.el-input {
        min-width: 235px !important;
      }
    }

    .whatsapp-common-settings-page__failure-notification {
      margin-bottom: $indent-small;
    }
  }

  .common-sms-settings {
    .account-info__sending-time {
      .el-date-editor.el-input {
        min-width: 235px !important;
      }
    }
  }

  .messages-settings-notification-checkbox {
    display: flex;
    flex-direction: column;
    gap: $indent;
    margin-bottom: $indent-validation;
  }

  .channel-priority__title {
    margin-bottom: $indent-validation;

    .title {
      margin-bottom: 0;
    }

    .clarification {
      max-width: 50%;
    }
  }

  .channel-priority__buttons {
    max-width: 400px;

    .high-priority,
    .low-priority {
      display: flex;
      gap: $indent;
      font-weight: bold;
    }
  }

  .whatsapp-timeout-settings {
    margin-bottom: $indent-validation;
    max-width: fit-content;

    .whatsapp-timeout-settings__title {
      font-size: $indent;
    }
  }

  .remind-time-clarification {
    max-width: 70%;
  }

  .clients-age__inputs {
    display: flex;
    gap: $indent;
    align-items: baseline;

    .clients-age__from {
      max-width: 60px;
    }

    .clients-age__to {
      max-width: 60px;
    }
  }

  .client-widget__messages-limit {
    display: flex;
    flex-direction: column;
    gap: $indent-small;
    align-items: baseline;

    .messages-limit__title {
      font-weight: bold;
    }

    .messages-limit__input {
      max-width: 50px;
    }
  }

  .client-widget__waiting-time {
    .waiting-time__title {
      font-weight: bold;
      margin-bottom: $indent-small;
    }

    .waiting-time__select {
      max-width: 110px;
    }
  }

  .autorization-parameters__title {
    font-size: $header-font-size;
  }

  .account-info__account-balance {
    .account-balance__title {
      font-size: 14px;
    }
  }

  .send-from__title,
  .send-to__title {
    display: flex;
    flex-direction: column;
    margin-bottom: $indent-small;
    line-height: 12px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #797979;
  }

  .common-calls-settings {
    .call-settings_clarification {
      font-style: italic;
    }

    .call-settings_notice {
      display: flex;
      width: 80%;
      align-items: center;
      gap: $indent;
      margin: $indent-validation 0;

      .call-settings_notice__text {
        font-style: italic;
      }
    }

    .common-calls-settings__login {
      width: 40%;
    }

    .common-calls-settings__password {
      width: 40%;
    }

    .check-connection {
      display: flex;
      gap: 15px;
      align-items: center;
      margin-bottom: $indent-validation;

      .check-connection__clarification {
        width: 50%;
        font-style: italic;
      }
    }

    .check-balance {
      margin-bottom: $indent-validation;

      .check-balance__title {
        font-size: $header-font-size;
      }

      .check-balance__info {
        display: flex;
        gap: $indent;
        align-items: center;
      }
    }
  }

  .clients-feedback {
    .aggregator-feedback {
      &__switch-wrapper {
        margin-bottom: $indent-validation;
      }

      &__question-icon {
        font-size: $header-font-size;
        margin-left: 8px;
        margin-top: 2px;
      }
    }
  }

  .aggregator-feedback-settings {
    &__feedback-params-wrapper {
      max-width: fit-content;
    }

    &__required-score,
    &__required-text-length {
      max-width: 80px;
    }
  }
}

.priority-card-container {
  margin-bottom: $indent-validation;
  max-width: 400px;

  .priority-card {
    align-items: center;
    display: flex;
    min-height: $element-height;

    text-transform: uppercase;

    border: 1px solid $grey;
    border-radius: $border-radius-base;

    padding: $indent-small;
    margin-bottom: $indent-small;
  }
}

.between-text {
  &__body {
    align-items: baseline;
  }

  &__question-icon.m-icon {
    font-size: $header-font-size !important;
    margin-left: -7px;
  }

  &__elem-wrapper {
    max-width: 80px;
    text-align: center;

    &_glued-to-right {
      margin-left: auto;
    }
  }
}

.messages-configuration-section-title {
  &__subtitle {
    max-width: 50%;
  }
}
