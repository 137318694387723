.workplaces-configuration {
  display: flex;
  flex-direction: column;
  gap: $indent;

  .m-buttons-group {
    max-width: 600px;
    flex-wrap: wrap;
  }
}
