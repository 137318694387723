#entry_list_area {
  display: flex;
  width: 100%;
  max-width: 39%; // не спрашивайте

  .nav {
    display: flex;
  }

  .tab-pane {
    height: 100%;
  }

  .tab-content {
    display: flex;
  }

  table:not(#entries_table) {
    margin-top: $indent-small;
  }

  #documents,
  #dental_orders,
  #analysis_orders {
    overflow-y: scroll;
  }

  #entries {
    width: 100%;
  }

  .medical-records-tab-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    #entry_type_select_container {
      .flex-container {
        flex-grow: 1;
        padding-right: 0;
      }

      .new-entry-selector {
        display: flex;
        flex-grow: 1;

        .entry_type_selector {
          display: flex;
          flex-grow: 1;
        }

        .entry_type_selector_icon {
          vertical-align: unset;
        }
      }
    }

    .sort_items {
      flex-grow: 1;
      overflow-y: hidden;

      #entry_sort {
        .form-control {
          margin-left: 0 !important;
        }

        .sort-control-sort-date-period-simple {
          flex-grow: 1;
        }
      }

      #entry_tooth_select {
        margin-bottom: $indent-small;
      }

      .items-sort-control-area {
        gap: $indent-small;
      }

      #kind {
        max-width: 100px;
      }
    }
  }

  #mr_client_entries_container {
    .dent-tooth-container {
      height: 35px;
      width: 35px;

      .dent-num {
        left: 6px;
      }
    }

    .dent-container .dent-upper-jaw {
      .dent-tooth-container {
        .dent-tooth {
          transform: rotate(180deg);
        }

        .dent-num {
          top: 14px;
        }
      }
    }

    .search.entry_type_selector_input {
      height: 30px;
    }

    #only_main_container {
      display: flex;
      align-items: center;
      border: 1px solid $el-grey;
      border-radius: $border-radius-base;
      height: 30px;
      padding: $indent-small;

      #only_mine {
        margin-top: 0;
      }

      .only-main-label {
        position: relative;
      }
    }

    .teeth_map {
      width: 80px;
    }

    .amount {
      width: 30px;
    }

    @media screen and (max-width: 1600px) {
      $small-screen-filters-tooth-width: 28px;

      .dent-tooth-container {
        width: $small-screen-filters-tooth-width;
        height: $small-screen-filters-tooth-width;

        .dent-tooth {
          width: $small-screen-filters-tooth-width;
          height: $small-screen-filters-tooth-width;
        }

        .dent-num {
          font-size: 12px;
          top: 6px;
        }
      }

      .dent-upper-jaw {
        .dent-num {
          top: 12px !important;
        }
      }
    }
  }

  #files {
    width: 100%;
  }

  #mr_client_dental_orders_container {
    .do-custom-number {
      width: 90px;
    }
  }

  #documents,
  #dental_orders,
  #analysis_orders {
    overflow-y: hidden;
  }

  #client_attachments-area,
  #client_treatment_plans_area,
  #client_documents_area,
  #client_dental_orders_area,
  #client_analysis_order_area {
    height: 100%;
    display: flex;
    flex-direction: column;

    .pagination {
      margin: 12px 0;
    }

    #attachments-containter,
    #mr_client_treatment_plans_container,
    #mr_client_documents_container,
    #mr_client_dental_orders_container,
    #mr_client_analysis_orders_container {
      overflow-y: scroll;
      flex-grow: 1;
    }
  }

  #entries_total_by_page {
    border-top: 2px solid $primary-color;
    height: 30px;
    display: none; // --> flex
    align-items: center;
  }
}
