@import "paymentPagePrint";

.payment-page {
  $gap-indent-3: $indent * 3;

  .m-panel__body {
    max-width: 100vw;
    min-width: 1200px;
  }

  &__body {
    display: flex;
    gap: $gap-indent-3;
    margin-bottom: $indent-validation;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: $indent-validation;
    flex-basis: 50%;
    margin-bottom: $indent-mid;
    max-width: calc(50% - $gap-indent-3);
  }

  .payment-fields {
    $field-width: 450px;

    &__field {
      max-width: $field-width;
    }

    &__card {
      display: flex;
      gap: $indent-mid;

      .payment-fields__field {
        .m-input {
          min-width: $field-width - 55px - $indent-mid;
        }
      }
    }
  }

  .m-form-show {
    .m-form-show__label {
      min-width: 300px;
    }
  }

  .payment-order {
    &__assistant-list {
      display: flex;
      flex-wrap: wrap;
      column-gap: $indent-small;

      .m-link_span:not(:last-child)::after {
        content: ",";
        color: $primary-font-color;
      }
    }
  }
}
