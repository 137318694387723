.document-black-square {
  $size: 21px;

  min-width: $size;
  width: $size;

  min-height: $size;
  height: $size;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  font-size: 50px;
  font-family: monospace;

  user-select: none;
  color: black;
}
