.head_desktops_page
  .el-select
    &.selected
      .el-input__inner
        background: #cddc3987

  .el-input__inner
    &:focus
      padding-left: 15px

  .el-input--small
    .el-input__inner
      height: 30px
      line-height: 33px
      width: 130px
      margin-right: 5px
      font-size: 12px
      padding-left: 10px
      padding-right: 20px
    &:focus
      padding-left: 15px

  .entry-type-selector
    .el-input__inner
        width: 105px

  .el-range-editor--small
    &.el-input__inner
      width: 180px
      padding: 0px 3px
      height: 30px
      .el-range__icon
        display: none
      .el-range-input
        width: 80px
        font-size: 11px
      .el-range-separator
        line-height: 30px
        width: 14px
      .el-range__close-icon
        display: none

  .head-desktop__card-selector
    .el-input__inner
      width: 100%
