.variables-editor {
  align-items: center;

  .variables-editor__title {
    margin-bottom: $indent;

    .variables-editor__editorTitle {
      font-size: $header-font-size;
      color: $grey-dark;
      font-weight: bold;
    }

    .variables-editor__titleClarification {
      color: $grey-light;
      font-style: italic;
    }
  }

  .variables-editor__buttons {
    display: flex;
    gap: $indent-small;
    max-width: 70px;
    align-items: center;
    padding-bottom: $indent-validation;

    .sms-templates-catalogs-modal {
      display: flex;
      gap: $indent-small;

      .el-dialog__body {
        flex-direction: column;
      }
    }

    button {
      margin-bottom: $indent-small;
    }
  }
  
  .approximate-characters-and-messages-number {
    font-weight: bold;
  }
}
