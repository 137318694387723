.guarantee-letter-select
  align-items: center
  display: flex
  gap: 3px
  .el-select.base-select
    max-width: 250px
    width: 250px
  .form-add-btn
    font-size: 16px
    margin-left: 0
    width: 40px
  .gaurantee-number
    .el-input__inner
      height: 30px
      &:focus
        border-color: #c0c4cc
  .gaurantee-date, .gaurantee-date .el-input
    width: 100%
