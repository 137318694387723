.front-book
  width: 100vw
  display: flex
  gap: $indent-small

  .front-book__left-panel
    width: 250px
    min-width: 250px
    flex-grow: 0 !important
    &.m-panel-collapsed
      min-width: $element-height

  .front-book__right-panel
    flex-grow: 1

  .elements-section
    margin-bottom: 20px

  .m-button-page
    .m-button-wrapper
      margin: 0 $indent 0 0

      &:first-child
        margin-left: 0

      &:last-child
        margin-right: 0

  .m-icon-page
    margin-bottom: 50px

    .icons-table
      max-width: 1000px

      .icon
        float: left
        width: 33%
        text-align: center
        height: 100px
        line-height: 100px
        color: #666
        border: 1px solid #eee
        margin-right: -1px
        margin-bottom: -1px

        span
          display: inline-block
          min-width: 75px

  h2
    margin-top: 30px
    margin-bottom: 20px
    padding-bottom: 15px
