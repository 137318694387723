= blockWidth
  max-width: 1570px
  width: 100%

.canny-feedback
  display: flex
  flex-direction: column

  .canny-feedback__board-selector
    align-items: center
    display: flex
    justify-content: flex-end
    margin-bottom: 8px
    +blockWidth

    .board-selector__label
      font-size: 14px
      font-weight: bold

    .board-selector__selector
      margin-left: 8px
      margin-right: 40px
      width: 100%
      .el-input--suffix
        width: 270px
      .el-input__inner
        font-size: 14px
        width: 270px
        height: 38px

  .canny-feedback__iframe
    +blockWidth

  .canny-feedback__email-error
    font-size: 15px
    i
      color: $red

  .epic-spinner-inner-element
    width: 10px !important
    height: 3px !important

.canny-board-items
  .el-select-dropdown__item
    font-size: 14px
