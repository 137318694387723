.image-cropper {
  display: flex;
  justify-content: center;
  width: 100%;

  .cropped-image, .preview-box {
    flex-basis: 50%
  }

  .preview-box {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    background-color: $primary-background;

    .preview {
      overflow: hidden;
      width: 300px;
      height: 300px;

      &--rounded {
        border-radius: 100%;
      }
    }
  }
}
