#fixed_doc_form.f_095_y {
  .header {
    .header__description {
      text-align: right;
      font-weight: bold;
      margin: 0;
    }

    .doc-table {
      td {
        border: 1px solid #000;
        font-weight: bold;
      }
    }
  }
}
