.appointment-type-indicator__wrapper
  display: block
  position: absolute
  right: -1px
  top: -1px
  width: 12px
  height: 12px
  z-index: 1
  filter: drop-shadow(0 1px 0 rgba(255, 255, 255, .8))
  .appointment-type-indicator__corner
    display: block
    height: 100%
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 0%)
