i.m-icon
  font-size: $icon-font-size

  &.button-icon
    font-size: $buttons-icon-font-size

  &.default
    color: $white

  &.primary
    color: $primary-color

  &.success
    color: $green

  &.warning
    color: $orange-bright

  &.danger
    color: $red

  &.grey
    color: $grey

  &.grey-select-text
    color: $select-text

  &.purple
    color: $purple

  &.brown
    color: $brown
