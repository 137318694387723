.ip-addresses-container
  &.d-flex
    display: flex

  display: grid
  grid-template-areas: "ip-addresses__form ip-addresses__table" "ip-addresses__switcher ip-addresses__table"
  grid-template-columns: 300px auto
  grid-gap: 15px
  height: 100%

  .ip-addresses__form
    grid-area: ip-addresses__form
    margin-right: 15px
    max-width: 300px
    width: 100%
    height: max-content

  .ip-addresses__table
    grid-area: ip-addresses__table
    display: flex
    flex-grow: 1
    height: 100%

    .el-card__body
      display: flex
      flex-grow: 1
      flex-direction: column
      padding: 4px 0
      overflow-x: hidden

      .ip-table
        flex-grow: 1

  .ip-addresses__module-enabled-switcher
    grid-area: ip-addresses__switcher
    align-self: self-end
    max-height: 42px
    max-width: 300px
    width: 100%
    font-size: 14px

    &.disabled-module
      align-self: unset

    &.outlined
      background-color: white
      border: 1px solid $primary-color
      color: $primary-color
      i
        color: inherit

      &:hover
        background-color: $primary-color
        color: white
        i
          color: inherit
