.communications_phone_popover
  position: absolute
  font-size: 19px
  display: flex
  align-items: center
  margin-left: 10px
  z-index: 9999
  &:focus
    outline: none
  .arrow-left
    width: 14px
    height: 14px
    transform: rotate(45deg)
    background-color: $grey-dark
    border-color: $grey-dark
    box-shadow: 4px 4px 5px 0 rgba(50, 50, 50, 0.75)
  .buttons-container
    background-color: $grey-dark
    border-color: $grey-dark
    border-radius: 10px
    box-shadow: 0 5px 5px 0 rgba(50, 50, 50, 0.75)
    display: flex
    justify-content: space-evenly
    left: -7px
    min-width: 100px
    min-height: 30px
    padding: 7px $indent-mid
    position: relative

    =btns_styles($color)
      background-color: $color
      border: none
      color: white
      font-size: 15px
      height: 32px
      width: 32px
      i
        position: relative
        top: -4px
        left: -3px

    .call_out_btn
      +btns_styles($green)
    .custom_message_btn
      +btns_styles($blue)
    div
      display: flex
      align-items: center
