$white-list-left-width: 280px;

.octet-input {
  flex: 1 1 24px;
}

.ip-address-input {
  &__octet-input {
    .el-input__inner {
      border-color: $el-grey;
    }
  }

  &__delimeter {
    align-self: flex-end;
    margin: 0 4px;
    margin-bottom: -2px;
  }
}

.network-module {
  height: 100%;

  &__caution-text {
    max-width: 650px;
    font-size: 14px;
  }

  &__caution-icon {
    font-size: 94px !important;
  }

  &__toggle-btn {
    .m-button.button-with-icon {
      width: 100%;
    }

    margin-top: auto;
  }

  &__switch {
    .el-switch__label {
      margin-top: -$indent-small;
    }

    .el-switch {
      height: $element-height;
      line-height: 15px;
    }
  }
}

.white-list-editor {
  &__delete-btn {
    margin-left: auto;
  }
}

.white-list-record-form {
  &__title {
    font-size: $header-font-size;
  }

  &__active-checkbox {
    align-self: start;
  }
}

.white-list-page {
  height: 100%;

  &__left {
    flex: 0 0 $white-list-left-width;
  }

  &__list-wrapper {
    height: 100%;
    flex-grow: 1;

    .el-card__body {
      width: 100%;

      .m-si-generator {
        height: 100%;
      }
    }
  }
}

.white-list-page__list-wrapper {
  .m-card__body {
    flex-direction: column;
  }
}
