.m-search-input-lazy {
  & > [role="button"] {
    cursor: text;
  }

  .validation-wrapper {
    .messages {
      bottom: initial;
    }
  }
}
