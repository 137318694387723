#employees-schedule
  .panel-body
    height: 100%
    overflow-y: hidden
    padding: 5px
    .inner-container
      overflow-y: scroll
      .employees-schedule-grid
        .modal
          .modal-time
            background-color: $grey-dark
            color: white
            border-radius: 2px
            padding: 2px 3px 2px 2px

          $background-transition: background-color 100ms linear

          =schedule-template-form-mixin
            .schedule-template-form-item:first-of-type
              margin-top: 0
            .schedule-template-form-item
              display: flex
              margin-top: 5px
              .schedule-template-form-left
                display: flex
                align-items: center
                flex: 2
              .schedule-template-form-right
                display: flex
                flex: 5
                align-items: center
                .el-select
                  width: 100%
                .el-date-editor
                  width: auto
                  flex: 4
                .time-picker-divider
                  flex: 1
                  display: inline-block
                  text-align: center
                  line-height: 40px
                .el-date-editor--date
                  width: 100%
                .el-range-editor
                  display: flex
                  justify-content: space-between
                  height: 30px
                  .el-range__close-icon
                    width: 13px
                    padding-top: 5px
                  .el-range-separator
                    line-height: 23px

              .schedule-template-form-right.template-expired
                .el-date-editor
                  input, .el-input__prefix
                    color: $red

          =schedule-template-mixin
            .schedule-template
              display: flex
              flex-direction: column
              min-height: max-content
              & + .schedule-template
                border-top: 1px solid #888
              &:last-of-type
                border-bottom: none
                padding-bottom: 0
              .schedule-template-header
                display: flex
                justify-content: space-between
                min-height: max-content
                width: 100%
                padding: 10px 0px
                font-size: 14px
                cursor: pointer
                padding-left: 5px
                padding-right: 5px
                background-color: #F6F6F6
                .interval
                  font-weight: bold
                  .interval-time
                    color: #555
                .remove-work-time
                  cursor: pointer
                  color: $red
              .schedule-template-form-body
                display: flex
                flex-direction: column
                min-height: max-content
                margin-top: 15px
                margin-bottom: 10px
                padding-left: 5px
                padding-right: 5px
                padding-bottom: 20px
                +schedule-template-form-mixin

          .modal-dialog
            width: min-content
            .modal-content
              .modal-header
                max-height: 40px
                min-height: 40px
                span
                  white-space: nowrap
              .modal-body
                .extend-schedule-modal-body, .add-vacation-modal-body
                  .extend-schedule-form-item, .add-vacation-form-item
                    display: inline-block
                    width: 100%
                    .extend-schedule-form-left, .add-vacation-form-left
                      padding-top: 8px
                      float: left
                      width: 30%
                    .extend-schedule-form-right, .add-vacation-form-right
                      float: right
                      width: 70%
                      .el-date-editor--date, .el-date-editor--daterange
                        width: 100%
              .modal-footer
                min-height: max-content
                display: flex
                flex-wrap: nowrap

          .schedule-template-modal
            margin: 100px auto 20px auto
            height: calc(100% - 120px)
            width: 70%
            .modal-content
              width: 100%
              .modal-body
                display: flex
                .schedule-template-modal-body
                  display: flex
                  width: 100%
                  .schedule-template-body-left
                    display: flex
                    flex-direction: column
                    width: 50%
                    min-height: max-content
                    padding-right: 15px
                    +schedule-template-form-mixin
                  .schedule-template-body-right
                    display: flex
                    flex-direction: column
                    width: 50%
                    padding-left: 15px
                    border-left: 1px solid $el-grey
                    .folding-buttons
                      display: flex
                      justify-content: space-between
                      min-height: max-content
                      display: none
                      button
                        width: 49%
                        margin: 0 0 5px 0
                    .schedule-templates
                      height: 100%
                      width: 100%
                      padding-right: 2px
                      overflow-y: scroll
                      .user-template-group
                        border: 1px solid $el-grey
                        padding: 5px
                        border-radius: 2px
                        margin-bottom: 5px
                        .template-group-header
                          display: flex
                          justify-content: space-between
                          background: #adadad
                          color: white
                          padding: 3px 7px
                          cursor: pointer
                          border-radius: 2px
                          .user-name-container
                            font-size: 14px
                            font-weight: bold
                            line-height: 1.7
                          .template-group-visibility-button
                            cursor: pointer
                            font-size: 25px
                        .schedule-templates-container
                          +schedule-template-mixin

          .remove-schedule-template-modal
            .modal-content
              .modal-body
                .schedule-template-form-item
                  .main-message
                    display: flex
                    align-items: center
                    min-width: max-content
                  .action-select
                    display: flex
                    width: 100%
                    justify-content: center
                +schedule-template-form-mixin

          .update-schedule-template-modal
            .modal-content
              width: 500px
              .modal-body
                .user-names-container
                  margin-top: 10px
                  .user-name:first-of-type
                    border-top: none
                  .user-name
                    display: flex
                    justify-content: start
                    align-items: center
                    height: 25px
                    border-top: 1px solid #F6F6F6

          .work-time-modal
            margin: 100px auto 20px auto
            height: calc(100% - 120px)
            .modal-content
              width: 650px
              .modal-body
                display: flex
                .employees-schedule-modal-body
                  display: flex
                  flex-direction: column
                  width: 100%
                  .information
                    display: flex
                    width: 100%
                    min-height: min-content
                    .calendar-day
                      display: flex
                      flex-direction: column
                      min-width: 100px
                      min-height: 100px
                      background-color: #F6F6F6
                      font-size: 16px
                      text-align: center
                      .month
                        padding: 4px
                        background-color: $success
                        color: white
                      .day
                        .date
                          font-size: 31px
                        .week-day
                          margin-bottom: 4px
                    .profile
                      display: flex
                      flex-direction: column
                      margin-left: 10px
                      .full-name
                        font-size: 20px
                      .specialties
                        margin-top: 2px
                  .schedule-templates::-webkit-scrollbar
                    width: 5px
                  .schedule-templates
                    display: flex
                    flex-direction: column
                    margin-top: 10px
                    overflow-y: scroll
                    height: 100%
                    +schedule-template-mixin
                    .schedule-template
                      margin-right: 5px

                  .add-control-elements
                    display: flex
                    justify-content: space-between
                    min-height: min-content
                    padding-top: 10px
                    font-size: 14px
                    .add-working-interval
                      color: $success
                      font-weight: bold
                      cursor: pointer
                    .add-vacation
                      color: $primary-color
                      font-weight: bold
                      cursor: pointer

          .remove-work-time-modal, .remove-vacation-modal
            .modal-content
              max-width: min-content
              .modal-footer
                width: max-content

          .update-work-time-modal
            .modal-content
              max-width: min-content
              min-width: 450px
              align-items: flex-end
              .modal-header
                width: 100%
              .modal-footer
                width: max-content

          .remove-schedule-template-modal
            .modal-content
              min-width: 550px

          .confirmation-modal
            .modal-content
              width: 400px

          .extend-schedule-template-modal, .add-vacation-modal
            .modal-content
              width: 650px

          .select-vacation-modal
            .modal-content
              width: 400px
              .modal-body
                .vacations-list
                  list-style: none
                  margin-bottom: 0
                  padding: 0
                  .vacation-item
                    text-align: center
                    cursor: pointer
                    margin-top: 1px
                    margin-bottom: 1px
                    padding: 3px
                    transition: $background-transition
                    &:hover
                      background-color: #f8f8f8
                      transition: $background-transition

