.m-date-picker
  height: $element-height !important
  line-height: $element-height !important
  .el-input__inner
    height: inherit
    line-height: inherit
    &:focus,
    &:active
      border-color: $primary-color
      &~.el-input__prefix
        color: $primary-color

  .el-input__icon
    line-height: 24px

  &.w-230
    width: 230px

.m-date-picker_range
  &.is-disabled
    background-color: unset
    .el-range-input
      color: #75757c
      background-color: unset
