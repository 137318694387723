.si-table-generated
  display: flex
  flex: unset

  &.si-table-generated_hide-scrollbars
    .body
      overflow: hidden

  .si-field
    .col.col_full
      white-space: normal
      overflow: visible
      text-overflow: unset
      padding-left: 0
