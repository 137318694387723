.vue-items-list

  $background-transition: background-color 100ms linear

  .vue-list
    list-style-type: none
    height: min-content
    margin: 2px 0 0
    clear: left
    padding: 0
    .vue-list-item
      min-width: 30px
      border-radius: $border-radius-base
      color: #555
      background: $grey-default-button
      border: 1px solid $grey
      transition: $background-transition
      text-decoration: none
      padding: 4px
      max-width: 200px
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      display: inline-block
      margin: 2px 5px 3px 0
      vertical-align: middle
      text-align: center
      float: left
      cursor: pointer
    .vue-list-item:hover
      background-color: $grey-default-button-hover
      border-color: $grey-hover
      transition: $background-transition
    .active
      color: white
      background: $primary-color
      border-color: $primary-color
      transition: $background-transition
    .active:hover
      background: $primary-color-hover
      border-color: $primary-color-hover
      transition: $background-transition
    .disabled
      cursor: not-allowed
      opacity: .65

.validation-entity.has-error
  .vue-list
    position: relative
    z-index: 1
  .vue-items-list::after
    content: ''
    display: block
    border: 1px solid $red-light
    position: absolute
    right: 0
    left: -3px
    top: -1px
    bottom: -2px
    border-radius: 2px
    z-index: 0

