.custom-service-tree-node {
  padding-top: 1px;

  .el-loading-mask {
    .el-loading-spinner {
      top: -5%;
      margin-top: 0;

      .circular {
        height: 21px;
        width: 21px;
      }
    }
  }
}

.serviceRatePopover {
  max-width: 400px !important;

  .m-buttons-group_legacy {
    .m-button {
      min-width: 45px;
    }
  }
}

.serivce-rate-sync-modal {
  .el-dialog__body {
    padding-bottom: 0;
    display: block;
  }
}
