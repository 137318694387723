.egisz-cases-table
  .cases-col
    cursor: pointer
    flex-grow: 0

    &.cases-col_number
      --size: 70px

    &.cases-col_episodes
      --size: 90px

    &.cases-col_date
      --size: 120px

    &.cases-col_status
      --size: 40px

    &.cases-clinic
      --size: 250px

    &.cases-col_fio
      --size: 250px

    &.cases-col_errors
      flex-grow: 1

    &.cases-col_actions
      cursor: default
      text-align: center
      .action_send
        color: $primary-color
        cursor: pointer
        font-size: 14px
