// crutches and bicycles

.el-pagination button,
.el-pager li
  font-size: 12px
  line-height: 29px
  min-width: 33.5px
  height: 29px

.el-select,
.el-date-editor,
.el-select-tree,
.el-input
  &:hover
    .el-input__inner
      border-color: $primary-color

  .el-input__inner
    all: unset
    -webkit-appearance: none
    background-color: $select-bg
    background-image: none
    border-radius: 4px
    border: 1px solid $grey
    box-sizing: border-box
    color: $select-text
    display: inline-block
    height: 30px
    line-height: 30px
    outline: 0
    font-size: 13px
    padding: 0 6px
    -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)
    width: 100%

  .el-tag
    display: flex
    flex-direction: row
    align-items: center

    .el-select__tags-text
      overflow: hidden
      text-overflow: ellipsis
      width: 100%

    .el-tag__close
      min-width: 15px

input[type=text].el-input__inner
  max-height: unset !important

.el-select
  .el-input__inner
    max-height: unset

.el-input__icon
  line-height: normal

.el-select .el-input.is-disabled .el-input__inner
  cursor: not-allowed

.el-input.is-disabled .el-input__inner
  background-color: $select-option-hover
  border-color: #E4E7ED
  color: #75757c
  cursor: not-allowed

.el-input__inner
  padding-left: 10px

.el-input__inner:focus
  outline: none
  box-shadow: none

.el-range-editor.is-active
  border-color: #3faeca

  &:hover
    border-color: #3faeca

.el-date-table td.end-date span, .el-date-table td.start-date span
  background-color: #3faeca

  &.today span
    color: #3faeca

.el-input--prefix .el-input__inner
  padding-left: 30px

.el-input--suffix .el-input__inner
  padding-right: 30px

.el-input__inner:hover
  border-color: #c0c4cc

.el-picker-panel
  all: unset
  color: $select-text
  border: 1px solid #e4e7ed
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
  background: $select-bg
  border-radius: 4px
  line-height: 30px
  margin: 5px 0

.time-select
  margin: 5px 0
  min-width: 0

.time-select-item
  padding: 8px 10px
  font-size: 13px
  line-height: 20px

  &:hover
    font-weight: normal

  &.selected:not(.disabled)
    &:hover
      font-weight: 700

.time-select-item.selected
  color: $primary-color


.time-select-item.disabled
  color: #e4e7ed
  background-color: white
  cursor: not-allowed

.el-date-table__row
  .disabled
    background-color: white

    .today
      background-color: aliceblue

.el-date-range-picker
  .el-picker-panel__body
    width: 513px

.el-notification
  border-radius: 0

.el-loading-spinner
  font-size: 30px

  .el-icon-loading
    color: #9E9E9E

.el-select-dropdown__item, .el-select-tree__item
  font-size: 13px

.el-select-dropdown__item.selected, .el-select-tree__item.selected
  color: #3faeca
  font-weight: 400
  font-size: 13px

.el-select-dropdown
  .el-scrollbar
    .is-vertical
      display: none

.el-picker-panel__body-wrapper
  .el-picker-panel__sidebar
    width: 120px
    overflow: hidden

    .el-picker-panel__shortcut
      margin: 3px
      border-radius: 2px
      padding: 2px 5px
      width: 110px
      font-size: 12px

      &:hover
        background: #f3f3f3
        color: inherit

  .el-date-range-picker__header
    div
      font-size: 15px

.el-checkbox
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner
    background-color: #3faeca
    border-color: #3faeca

  .el-checkbox__input.is-checked + .el-checkbox__label
    color: #3faeca

  .el-checkbox__inner:hover
    border-color: #3faeca

.el-textarea__inner
  font-size: 13px
  padding: 3px 6px
  height: auto

  &[readonly="readonly"]
    cursor: default

  &:hover,
  &:focus
    border-radius: 4px
    border-color: $primary-color
    box-shadow: none
    outline: none

.el-collapse
  .el-collapse-item
    .el-collapse-item__header, .el-collapse-item__content
      color: #6c6c6c

.el-select.base-select,
.el-select.filterable-select
  max-width: 230px
  width: 100%

.el-select__tags
  .el-tag
    max-width: 100%

.el-input-style-fixer
  &.el-input-group
    &.el-input-group--prepend
      .el-input__inner
        border-top-left-radius: 0 !important
        border-bottom-left-radius: 0 !important

    &.el-input-group--append
      .el-input__inner
        border-top-right-radius: 0 !important
        border-bottom-right-radius: 0 !important

  .el-input__inner
    height: 30px
    border-color: $el-grey !important
    border-radius: 4px !important
    font-size: 13px
    padding-left: 15px

    &:hover
      border-color: $el-grey-hover !important

.has-error
  .el-input-style-fixer
    .el-input__inner
      border-color: $red !important
  .m-select
    .el-input__inner
      border-color: $red !important

.el-checkbox.el-checkbox_big
  .el-checkbox__inner
    height: 16px !important
    top: 2px !important
    width: 16px !important

    &::after
      border-width: 2px !important
      height: 9px !important
      left: 5px !important

  .el-checkbox__label
    padding-left: 5px
    position: relative
    top: 2px

.el-date-editor.el-range-editor
  height: 30px !important

.el-input.is-focus
  .el-input__inner
    border-color: $primary-color !important

.el-switch.is-checked
  .el-switch__core
    border-color: $primary-color
    background-color: $primary-color


.el-checkbox
  &.is-bordered.is-checked
    border-color: $primary-color

  .el-checkbox__inner
    height: 17px
    top: 1px
    width: 17px

    &::after
      border-width: 2px
      height: 9px
      left: 5px

.el-tree
  .el-tree-node
    .el-checkbox__inner
      top: 2px

    .el-checkbox__inner + .el-tree-node__label
      margin-left: 10px

.el-popper:not(.el-picker-panel)
  max-width: 600px

.el-tabs
  &.remove-margin
    &.el-tabs--top
      .el-tabs__item
        $tab-item-height: 20px
        $tab-item-mb: 5px
        height: $tab-item-height
        line-height: $tab-item-height
        margin-bottom: $tab-item-mb

  .el-tabs__active-bar
    background-color: $primary-color

  .el-tabs__item
    &.is-active, &:hover
      color: $primary-color

.el-switch
  .el-switch__label
    span
      font-size: 13px !important

  .el-switch__label--right
    &.is-active
      color: $primary-color

.el-checkbox-group
  .el-checkbox
    margin-right: unset

.files-loader
  display: flex
  flex-direction: column

  .el-upload
    flex-grow: 1

  .el-upload-list
    height: 100%
    margin-top: 5px
    max-height: 60vh
    overflow-y: auto
    padding-right: 10px

  .el-upload__input
    display: none

  .el-upload-dragger
    width: unset

    &:hover, &:active
      border-color: $primary-color

    .el-upload__text
      padding: 0 60px

      em
        color: $primary-color

  .el-upload-list__item.is-success
    .el-upload-list__item-name:hover
      color: $primary-color

.el-button--primary:not(.m-button)
  background-color: $primary-color
  border-color: $primary-color
  color: white

  &:hover, &:active, &:focus
    background-color: $primary-color
    color: white
    opacity: 0.8

.el-loading-mask
  .el-loading-spinner
    .path
      stroke-width: 7px
      stroke: $primary-color

.el-checkbox.is-bordered.el-checkbox--small
  padding: 4px 15px 5px 10px
  height: 30px

.el-radio-button,
.el-checkbox-button
  .el-radio-button__inner,
  .el-checkbox-button__inner
    &:hover
      color: $primary-color

  &.is-active,
  &.is-checked
    .el-checkbox-button__inner
      background-color: white
      border-color: $primary-color
      box-shadow: -1px 0 0 0 $primary-color
      color: $primary-color

    &:hover
      .el-radio-button__inner,
      .el-checkbox-button__inner
        color: $primary-color

  &.is-focus
    border-color: $primary-color

// фикс от протекших стилей
.el-select
  .el-select__input
    margin-left: 4px
    border: none
    background-color: transparent


.dark
  &.el-popover
    background-color: $grey-dark
    border: none
    box-shadow: 0 0 5px 0 lighten(#000, 26%)

  &.dark-top
    &.el-popover
      .popper__arrow
        border-bottom-color: lighten($grey-dark, 10%)
      // bottom - особенности реализации
      .popper__arrow::after
        border-bottom-color: $grey-dark

  &.small
    .el-menu-item
      height: $menu-item-height
      line-height: $menu-item-height

  .el-menu-item
    &:hover
      background-color: $orange-bright !important
// перебивка js стилей

.el-popover.menu
  padding: 5px 0

.el-menu-item
  height: 40px
  line-height: 40px

.el-menu
  border-right: none

.el-select-multi
  .el-input__inner
    height: 30px !important

.el-input-number
  line-height: 28px

// протечка стилей из panel-heading
.el-pagination
  .btn-prev[disabled="disabled"],
  .btn-next[disabled="disabled"]
    i, li
      color: $grey

  i, li
    color: $primary-font-color

  i
    font-size: $primary-font-size

.el-dialog__body
  word-break: normal

.el-input__icon
  line-height: 30px

.el-message-box__wrapper
  @media print
    display: none

input[type="password"].el-input__inner
  height: 30px

.el-dropdown.m-dropdown.m-dropdown_dark
  cursor: pointer

.el-dialog
  .el-dialog__header
    padding: $indent $indent 0

  .el-dialog__body
    padding: $indent

  .el-dialog__footer
    padding: 0 $indent $indent

  .el-dialog__close
    vertical-align: top

.el-radio-button-fix-color
  .el-radio-button.is-active
    .el-radio-button__inner
      background-color: $primary-color
      border-color: $primary-color
      box-shadow: -1px 0 0 0 $primary-color
      color: white

      &:hover
        opacity: 0.9

// todo: удалить после руби 3
.el-input-group--append
  .el-input__inner
    border-top-right-radius: 0 !important
    border-bottom-right-radius: 0 !important

// todo: удалить после руби 3
.custom-emk-input-deprecated
  .el-input__inner
    padding-left: 5px

  .el-input-group__append
    font-size: 11px
    padding-left: 5px
    padding-right: 5px

.el-tabs
  border-radius: $border-radius-base

  .el-tabs__item.is-top
    border-top-left-radius: $border-radius-base
    border-top-right-radius: $border-radius-base

.el-message-box__btns
  display: flex
  justify-content: flex-end
  .m-button
    min-width: 60px

// проклятый бутстрап
input[disabled][type="text"]:not(.form-control)
  background-color: unset

.el-collapse-item:last-child
  margin-bottom: 0

.el-message-box
  vertical-align: top
  margin-top: 40px
  .el-message-box__header
    display: none

  .el-message-box__title
    font-size: $header-font-size
    font-weight: bold
  .el-message-box__content
    font-size: $primary-font-size

  &.with-title
    .el-message-box__header
      display: block
      padding: $indent-mid $indent-mid $indent-mid $indent
    .el-message-box__headerbtn
      top: $indent-small
      right: $indent-mid

// тяжёлое наследие бутстрапа
input:not([type="checkbox"]):not([type="radio"]):not(.el-range-input)
  min-height: 30px
  box-shadow: none

.el-range-separator
  padding: 0 3px

.el-upload__input
  display: none

.el-tree-node__content:hover
  background-color: $primary-background-color

.el-input__prefix
  .fad
    vertical-align: middle


.el-select-tree__item.is-selected
  color: $primary-color

.el-tree-node__loading-icon
  position: absolute

.el-message-box__status
  top: 13px

.el-select-group__title
  color: lighten($primary-font-color, 5%)
  font-weight: bold
  padding-left: 20px
  padding-right: 20px

.el-picker-panel
  .el-picker-panel__sidebar
    overflow-y: scroll

.el-collapse
  border: none

.el-collapse-item 
    &__header
      height: $element-height
      line-height: $element-height

    &__content
      padding-bottom: $indent-mid
