.company-types-list
  .vue-list .vue-list-item
    overflow: hidden
    position: relative
    z-index: 1
  &.loading .vue-list .vue-list-item
    background: none
  &.loading .vue-list-item:before
    content: ""
    position: absolute
    width: 570px
    height: 279px
    top: -50%
    left: -50%
    z-index: -1
    transform: rotate(45deg)
    animation-duration: 1.7s
    animation-fill-mode: forwards
    animation-iteration-count: infinite
    animation-timing-function: linear
    animation-name: placeholderAnimate
    background: linear-gradient(to right, #3FAECA 2%, #eee 18%, #3FAECA 33%)
    background-size: 1000px

@keyframes placeholderAnimate
  0%
    background-position: -650px 0
  100%
    background-position: 650px 0

.si-control
  .reset-btn
    margin-bottom: 2px

.payments-btn-refund
  margin-left: auto


.company-payments
  .si-footer
    flex-direction: column
    .footer-total
      border-top: 2px solid #3FAECA
      div
        font-weight: bold
        font-size: 13px
      .options
        overflow: inherit
        z-index: 111
    .footer-pagination
      display: flex
