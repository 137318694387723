.sidebar-left
  &-container
    flex-basis: 242px
    height: 100%
    min-width: 242px
    width: 242px
    &--collapsed
      flex-basis: 30px
      min-width: 30px
      width: 30px
  &-collapsed
    height: 100%
    margin-right: 0!important
  &-panel
    display: flex
    flex-direction: column
    flex-grow: 0
    flex-basis: 242px
    height: 100%
    padding-left: 0
    padding-right: 0
    text-align: center
    .panel-body
      overflow-y: scroll
    .panel-heading-title span
      font-size: 17px
    .panel-heading-buttons span
      font-size: 2.6rem
      cursor: pointer
    .user-cabinet-switch-control
      display: flex
      justify-content: space-between
      margin-bottom: 10px
      margin-top: 10px
      button
        display: flex
        justify-content: center
        align-items: center
        min-height: 28px
        background-color: $primary-color
        color: white
        border: none
        border-radius: 3px
        outline: none
        width: 49%
        .button-text
          margin-left: 3px
        .fad
          color: white
      .selected-switch-button
        background-color: $grey-dark

.schedule-entry-search
  max-width: 400px
