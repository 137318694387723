@import '../../analysis_laboratories/styles/_'

.catalog-container
  column-gap: 5px
  overflow: auto

  .el-collapse-item__header
    background: $orange
    color: white !important
    font-size: 15px
    padding-left: 10px
    i
      margin-right: 5px

.catalogs-form__btns
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%

.catalog-container
  display: flex
  .m-panel
    flex-grow: 1

  .m-panel.catalog-container__list
    display: flex
    flex-basis: 45%

  .m-panel.catalog-container__editor
    display: flex
    flex-basis: 55%
