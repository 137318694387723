#referrals_page {
  .panel-heading {
    min-height: $panel-title-height;
    height: $panel-title-height;
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
  }
}


.referrals-catalog {
  .referrals-catalog-edit,
  .referrals-catalog-new {
    .col__phone,
    .col__companyTitle {
      display: none;
    }
  }

  .referrals-catalog__list {
    max-height: 100%;
  }

  .col__iconOrAvatar {
    flex-grow: 0;
    flex-basis: 45px;
    padding-left: 15px !important;
  }

  .catalog-container__editor {
    .m-panel__body {
      padding-top: 0;
    }
  }
}

