.analysis_orders_new_page
  .el-input--suffix .el-input__inner
    border: solid 1px $grey
    border-radius: $border-radius-base

  .el-popper[x-placement^=bottom]
    margin-top: 5px

  .el-picker-panel
    all: unset
    color: #606266
    border: 1px solid #e4e7ed
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
    background: #fff
    border-radius: 4px
    line-height: 30px
    margin: 5px 0
    width: 315px

    .el-date-table
      td.current:not(.disabled)
        span
          background-color: $primary-color

      td.today:not(.current)
        span
          color: $primary-color

      td.available:hover
        color: $primary-color

    .el-picker-panel__footer
      .el-button.el-picker-panel__link-btn
        span
          color: $primary-color

        &.is-plain
          span
            color: #606266

          &:hover
            border-color: $primary-color

            span
              color: $primary-color

    .el-picker-panel__body
      .el-date-picker__header
        .el-picker-panel__icon-btn
          &:hover
            color: $primary-color

        .el-date-picker__header-label
          &:hover
            color: $primary-color

      .el-date-picker__time-header
        padding-bottom: 8px

        .el-input__inner
          margin-bottom: 0px

          &:focus
            height: 32px
            padding-left: 10px
            box-shadow: none

        .el-time-panel
          .el-time-panel__btn.confirm
            color: $primary-color

#analysis_order_form
  display: flex
  flex-direction: column
  flex: 1

  #select_joined_entries_modal
    .modal-body
      overflow-y: hidden

      #select_joined_entries_modal_body
        display: flex
        flex-direction: column

        #select_joined_entries_table_header
          width: calc(100% - 7px)

        #select_joined_entries_table_body
          max-height: calc(100vh - var(--main-navbar-height) - 300px)
          overflow-y: scroll

        .description
          white-space: pre

        .entry-row
          display: flex
          flex-direction: row
          width: 100%

          &:nth-of-type(even)
            background-color: #f9f9f9

          &:hover:not(.selected_entry):not(.header)
            cursor: pointer

          &.header
            font-weight: 600
            border-bottom: 1px solid #efefef

          div.row-column
            padding: 5px 5px 5px 10px

            input.checkbox
              cursor: pointer

          .title
            flex: 6 1 300px
            max-width: unser

          .number
            flex: 1 0 30px

          .price
            flex: 1 0 40px

          .options
            flex: 0 0 30px
            width: unset

            .fad
              color: $link-color
              font-size: medium

              &:hover
                color: $link-color-hover
                text-decoration: underline
                cursor: pointer

  #analysis_order_laboratory_form
    display: flex
    flex-direction: row
    justify-content: space-between
    height: calc(100vh - var(--main-navbar-height) - 61px)

    .col-md-3
      padding-right: 0px
      padding-left: 0px

    .col-md-9
      padding-right: 0px
      padding-left: 0px

    #analysis_order_area
      flex: 2
      display: flex
      flex-direction: column
      max-height: 100%

      .kdl-entries-clarification
        font-weight: bold
        font-style: italic
        padding-top: $indent

      .btn.btn-primary:not(.btn-back)
        padding: 8px 30px
        border: 0
        margin: 10px 1px
        cursor: pointer
        border-radius: 2px
        outline: none !important
        width: fit-content
        background-color: $primary-color
        box-shadow: unset

        &:hover
          color: #FFF
          background-color: darken($primary-color, 10%)

      #entry_type_selector
        margin-top: 10px
        margin-bottom: 10px
        display: flex
        min-height: fit-content

        .el-autocomplete
          flex: 1

          .el-input
            input
              border: none
              border-radius: 0px
              border-bottom: 1px solid #ccc

              &:focus
                border-bottom: $border-base

                &::placeholder
                  color: $primary-color

              &::placeholder
                font-style: italic
                // font-size: 13px
                color: $grey-light


      #entries_container
        margin-bottom: 10px
        height: calc(100vh - var(--main-navbar-height) - 375px)

        .panel
          height: 100%
          box-shadow: none

          .panel-body
            height: calc(100% - 30px)

            #entries_table
              width: 100%
              height: 100%
              display: flex
              flex-direction: column
              flex: 1
              overflow-y: hidden

              .entry_row
                display: flex
                flex-direction: row
                width: 100%

                &:nth-of-type(even)
                  background-color: #f9f9f9

                div.row_column
                  padding: 5px 5px 5px 10px

                .title
                  flex: 6 1 300px
                  max-width: 42%
                  min-width: 200px

                .biomaterial
                  min-width: 200px
                  max-width: 200px

                  .el-select
                    width: inherit

                    &.error
                      .el-input__inner
                        border-color: $red

                    .el-select__tags
                      .el-tag
                        width: 95%

                .biomaterial-type
                  min-width: 50px

                .number
                  flex: 1 0 30px
                  min-width: 70px

                .price
                  flex: 1 0 40px

                .due_date
                  flex: 1 1 100px
                  text-align: center

                .sampling_date
                  flex: 2 1 50px
                  max-width: 160px

                  .el-date-editor.el-input
                    width: inherit

                    .el-input__inner
                      padding-right: 5px
                      padding-left: 5px

                    .el-input__prefix
                      i
                        display: none

                .options
                  flex: 0 0 30px
                  width: unset

              #entries_table_header
                display: flex
                flex-direction: row
                font-weight: 600
                border-bottom: 1px solid #efefef
                z-index: 1
                width: 100%
                flex: 0 0 28px
                overflow: hidden
                text-overflow: ellipsis
                min-height: 25px

              #entries_table_body
                overflow-y: auto
                position: relative
                flex: 4

              #entries_table_footer
                display: flex
                flex-direction: row
                flex: 0 0 28px
                z-index: 1
                width: 100%
                font-weight: 600
                border-top: 1px solid #efefef
                min-height: 25px

    #entry_type_tree
      flex: 1
      flex-basis: 200px
      min-width: 100px
      max-width: 500px
      margin-left: 10px
      height: 100%

      .panel.panel-primary
        .panel-body
          margin-top: 0px

      .el-radio-group
        margin-left: 15px
        margin-top: 10px

        .el-radio-button
          .el-radio-button__inner
            &:hover
              color: #606266
              background-color: darken(#FFF, 10%)

          &.is-active
            .el-radio-button__inner
              background-color: $primary-color
              border: 1px solid $primary-color
              box-shadow: unset

              &:hover
                color: #FFF
                background-color: darken($primary-color, 10%)

      #tree_container:nth-child(1)
        // border: 1px solid $grey
        height: calc(100vh - var(--main-navbar-height) - 92px)

      #tree_container:nth-child(2)
        // border: 1px solid $grey
        height: calc(100vh - var(--main-navbar-height) - 133px)

      #tree_container
        .el-tree__empty-text
          margin-top: 10px

.entry-types-suggestion-list
  .el-scrollbar
    .el-scrollbar__view.el-autocomplete-suggestion__list
      display: flex
      flex-direction: column
      justify-content: flex-start

      li
        margin: 0
        padding: 0px 1em 2px .4em
        cursor: pointer
        min-height: 0
        line-height: 27px

        &.highlighted
          background: white

        &:hover
          cursor: unset

        .suggestion
          display: flex
          width: 100%
          align-content: flex-start

          &:hover
            cursor: pointer

          .number
            background: $grey
            color: white
            margin-right: 15px
            padding: 0px 5px 0px 6px
            flex: 0.1
            min-width: 100px

          .title
            flex-grow: 2
            max-width: unset
            flex: 1

          .price
            color: $grey-dark
            flex-grow: 0.01
            flex: 0.1

        .message
          display: flex
          width: 100%
          text-align: center
          font-style: italic

          .title
            flex-grow: 2
            max-width: unset
            flex: 1
