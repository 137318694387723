.el-select-dropdown
  .el-scrollbar
    .el-select-dropdown__wrap
      .el-select-dropdown__item
        .status-dropdown
          .appointment-status-icon
            margin-right: 5px
            width: 15px

.el-popover.el-popper
  .appointment_note_popover
    .el-textarea__inner
      border-radius: 2px

  .appointment_note_popover_btns
    display: flex
    margin-top: 5px
    justify-content: space-between

    #save_note_btn
      border-radius: 2px

    #close_not_btn
      border-radius: 2px
      height: 30px

  p.confirmation_popover
    white-space: pre-line
  div.appointment_entry_types_popover
    padding: 6px 6px 10px 6px
    .entry_type
      padding: 2px
      &:nth-of-type(odd)
        background-color: #f9f9f9

      .number
        width: 100px
        padding: 5px

      .title
        width: 350px
        padding: 5px
  div.appointment_entry_types_popover_footer
    display: flex
    justify-content: flex-end

#appointment_call_log_table
  height: 100%
  .si
    .si-control
      flex-wrap: unset

      .search
        .el-input__inner
          border: none
          border-bottom: 1px solid $grey
          border-radius: 0
          &:focus
            box-shadow: none
            border-bottom: 1px solid $primary-color
            padding-left: 10px

      .component
        margin-right: 5px
        &.date-range-picker-btn
          min-width: fit-content

        &.el-select
          .el-select__caret
            cursor: default
            &:hover
              cursor: default

        .el-input__inner
          height: 30px !important

        .el-select__tags
          flex-wrap: unset
          .el-tag
            width: 70%

    .si-control-btns
      .btn
        padding: 6px
        min-width: 45px
        justify-content: center

    .si-table
      .body
        overflow-y: auto

      .header
        .si-field
          .appointment_call_log_table_si_column.without_padding
            padding: 5px 0 5px 0

      .si-field
        display: flex
        flex-direction: row
        justify-content: space-evenly
        width: 100%
        line-height: 1.42857143
        padding: 2px 0 2px 0

        .appointment_call_log_table_si_column:not(.without_padding)
          padding: 5px 5px 5px 10px

        .clinic
          flex-basis: 150px
          flex-grow: 1

        .date
          flex-basis: 125px
          flex-shrink: 0
          max-width: 130px

        .status
          flex-basis: 150px
          flex-shrink: 0
          white-space: nowrap
          max-width: 150px

        .client
          flex-basis: 120px
          flex-grow: 3

        .user
          flex-basis: 80px
          flex-grow: 1

        .legal_representative
          flex-basis: 80px
          flex-grow: 1

        .phone
          flex-basis: 130px
          flex-grow: 1
          flex-shrink: 0
          max-width: 150px

        .confirmation_status
          flex-basis: 125px
          flex-grow: 1

          .el-select__caret
            cursor: default
            &:hover
              cursor: default

          .el-input__inner
            height: 30px !important
            border-radius: 2px

        .specialty
          flex-basis: 110px
          flex-grow: 1
          flex-shrink: 2
          text-overflow: ellipsis

        .options
          flex-basis: 132px
          display: flex
          margin-left: 5px
          .fad
            color: $link-color
            font-size: 16px
            margin-left: 9px
            margin-top: 5px
            &:focus
              outline: none

            &:hover
              color: $link-color-hover
              text-decoration: underline
              cursor: pointer

            &.fa-comment-alt
              font-size: 15px
              margin-top: 7px

            &.fa-comment-alt-lines
              font-size: 15px
              margin-top: 7px
