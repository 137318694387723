#doctor_schedules_page
  .el-select-dropdown
    .el-select-dropdown__empty
      padding: 10px

    .el-scrollbar
      .el-scrollbar__view
        .el-select-dropdown__item
          max-width: 1000px

          .entry-type-dropdown
            display: flex

            .entry-type-dropdown-number
              margin-right: 5px
              background: $grey
              color: white
              padding: 0px 5px 0px 5px
              width: 75px

            .entry-type-dropdown-title
              flex-grow: 2
              max-width: unset
              flex: 1

#appointment_time_finder_table
  height: 100%

  .si
    .si-control
      flex-wrap: unset
      width: 100%

      .si-control-rows
        flex-direction: column
        display: flex
        .si-control-row
          flex-direction: row
          display: flex

      .si-control-btns
        display: flex
        flex-direction: row
        align-self: flex-start

      .el-select
        .el-select__input
          border: 0
          margin: 0
          padding: 0
          padding-left: 10px
          &:focus
            box-shadow: none

      .search
        .el-input__inner
          border: none
          border-bottom: 1px solid $grey
          border-radius: 0px
          &:focus
            box-shadow: none
            border-bottom: 1px solid $primary-color
            padding-left: 10px

      .component
        &.el-input-number
          .el-input
            line-height: 30px
        &.date-range-picker-btn
          min-width: fit-content
        &.duration
          width: 150px
          .el-input__inner
            font-size: 13px
            text-align: center
            &:focus
              padding-left: 10px
        &.checkbox_container
          margin-top: 7px
          input.checkbox
            margin-top: 0
            vertical-align: middle
            label
              margin-bottom: 0
          .checkbox, label
            cursor: pointer
        &.time_select
          width: 150px
          .el-input__inner
            padding-right: 0px

        .el-input__inner
          height: 30px !important
          border-radius: 2px
          border: 1px solid $grey
          &:focus
            box-shadow: none

        .el-select__tags
          flex-wrap: unset
          .el-tag
            width: 70%

    // 7jt4kp - fix buttons sizes
    .si-control-btns
      .btn
        padding: 6px
        min-width: 45px
        justify-content: center

    .si-table
      .body
        overflow-y: auto

      .si-field
        display: flex
        flex-direction: row
        justify-content: space-evenly
        width: 100%

        .clinic
          flex-basis: 70px
          flex-grow: 1

        .date
          flex-basis: 20px
          flex-shrink: 0
          flex-grow: 1

        .user
          flex-basis: 80px
          flex-grow: 1

        .specialty
          flex-basis: 110px
          flex-grow: 1
          flex-shrink: 2
          text-overflow: ellipsis

        .options
          flex-basis: 64px
          display: flex
          .fad
            color: $link-color
            font-size: medium
            margin-left: 10px
            &:hover
              color: $link-color-hover
              text-decoration: underline
              cursor: pointer

      .recording_points_table
        &.no_data
          justify-content: center
          display: flex
          height: 100%
          align-items: center
          .message
            white-space: pre-line
