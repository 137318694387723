.btn-create-customer
  width: 40px
  span
    font-size: 14px
.input-button
  min-width: 30px
  height: 30px
  margin: 0 0 0 2px
  padding: 0
  &_for-select-multiple
    min-width: 30px
    height: 30px
  &_full-height
    height: 100%
