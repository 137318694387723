.m-dropdown {
  max-height: $element-height !important;
  border-radius: $border-radius-base;

  &.m-dropdown_is-button {
    display: flex;
    flex-basis: 50px;
    flex-shrink: 1;

    .el-button-group {
      display: flex;
      flex-basis: 50px;


    }

    .el-button {
      max-height: $element-height !important;
      height: $element-height;
      text-transform: uppercase;
      font-size: $primary-font-size;
      padding: unset;

      &.el-button--small {
        padding: 0 $indent;
      }

      &.el-dropdown__caret-button {
        padding: 0 $indent-small;
      }

      @include all-button-kinds-to-element();

      &.el-button--primary:last-child::before {
        background-color: $primary-color;
      }

      &.el-button--success:last-child::before {
        background-color: $success-color;
      }

      &.el-button--warning:last-child::before {
        background-color: $warning-color;
      }

      &.el-button--danger:last-child::before {
        background-color: $danger-color;
      }

      &.el-button--dark:last-child::before {
        background-color: $dark-color;
      }
    }
  }
}

.m-dropdown__menu {
  overflow-y: auto;
  max-height: 30vh;
  min-width: 230px;

  .m-dropdown__item {
    position: relative;

    &.m-dropdown__item_active {
      background-color: $primary-background-color;
      color: $primary-color
    }
  }
}

.el-dropdown-menu {
  @media print {
    display: none;
  }
}
