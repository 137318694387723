@mixin mFieldsFW
  .m-select
    width: 100%

  .m-date-picker
    width: 100%


.el-select-multi
  .el-select__tags
    flex-wrap: nowrap
    padding-right: 30px

    > span
      display: flex
      max-width: 70%

  &.more-than-1
    span+.el-select__input
      margin-left: 50px
      width: 60px !important

.m-select
  .el-select__tags
    .el-select__input
      min-height: unset !important
      height: 18px

.el-select-dropdown__empty
  padding: $indent-mid
  font-size: $primary-font-size
  color: $number-color-disabled

