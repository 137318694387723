.col-container
  display: flex
  flex-grow: 1

  .panel-primary
    display: flex
    flex-direction: column
    flex-grow: 1

  .panel-body
    overflow-y: auto
    height: 100%
    &::-webkit-scrollbar
      width: 5px

  .panel-list-body
    padding-right: 10px

  .panel-heading
    flex-shrink: 0

  ul.fancytree-container li
    width: 200px

  .show_info_table td:first-child
    width: 150px

  .flex-form:not(.table-form) .form-label
    flex: 1 1 100%
    padding: 6px 0px 0px 0px
    text-align: left
    background-color: white
    border-right: none

  .flex-form:not(.table-form) .form-value
    flex: 1 1 100%
    padding-left: 0px
    padding-right: 0px
    padding-top: 0px

  .form-btns
    display: flex
    flex-grow: 1
    justify-content: space-between
    padding-top: 15px

  input[type="submit"]
    //margin-top: 15px

  #delete-record,
  .delete-record-button
    color: $red
    font-size: 16px
    cursor: pointer
    &:hover
      color: darken($red, 10%)
      transition: 0.1s

  .panel_close_button
    &:hover
      text-decoration: none
      color: $grey-dark
      transition: 0.1s

  @mixin col-item-base
    display: flex
    overflow-x: hidden
    flex-grow: 1

  .col-item
    @include col-item-base

  .col-item-25
    @include col-item-base
    flex-basis: 25%

  .col-item-35
    @include col-item-base
    flex-basis: 35%

  .col-item-40
    @include col-item-base
    flex-basis: 40%

  .col-item-45
    @include col-item-base
    flex-basis: 45%

  .col-item-55
    @include col-item-base
    flex-basis: 55%

  .col-item-60
    @include col-item-base
    flex-basis: 60%

  .col-item-65
    @include col-item-base
    flex-basis: 60%

  .col-item-3, .col-item-30
    @include col-item-base
    flex-basis: 30%

  .col-item-4, .col-item-40
    @include col-item-base
    flex-basis: 50%

  .col-item-5, .col-item-50
    @include col-item-base
    flex-basis: 50%

  .col-item-6, .col-item-60
    @include col-item-base
    flex-basis: 60%

  .col-item-7, .col-item-70
    @include col-item-base
    flex-basis: 70%

  .col-item-75
    @include col-item-base
    flex-basis: 70%

  .tab-content > .tab-pane.flex.active
    display: flex
    flex-wrap: wrap
