@media print {
  .document-page-break {
    height: 0;
    overflow: hidden;
    border: none !important;
    margin: 0 !important;
  }
}

.document-page-break {
  display: flex;
  justify-content: center;
  margin-bottom: $indent;

  width: 100%;
  border-bottom: 1px dashed $grey;
  page-break-after: always;
}
