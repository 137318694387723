.schedule-grid-preview
  text-align: center
  display: flex
  flex-direction: column
  .schedule-grid-preview-subcontainer
    text-align: left
    display: flex
    margin-bottom: 5px

  button.btn
    display: block
    width: 100%
    margin: 2px 0
    height: 30px
    font-size: 0.85em

  .schedule-grid-preview-left
    display: flex
    flex-wrap: wrap
    flex-grow: 1
    width: 225px
    text-align: left
    padding: 2px
    border-right: 1px solid #eeeeef
    padding-right: 7px

    .schedule-grid-preview-title-container
      font-style: italic

    .schedule-grid-preview-entry_type-container
      color: $red-light
      padding-left: 0
      font-size: 12px
      margin-top: 5px
      max-width: 220px
      margin-bottom: 5px
      flex-basis: 100%
      .schedule-grid-preview-entry_type
        text-align: left
        font-style: italic
        max-height: 36px
        overflow: hidden

    .note-container
      color: $orange
      display: flex
      flex-basis: 500px
      font-style: italic
      height: 34px
      overflow: hidden

      .note-text-wrapper
        flex-grow: 1

      .note-container__text
        max-width: 219px
        word-wrap: anywhere
      
      .note-container__edit-button
        cursor: pointer
        font-size: 14px
        margin-top: 3px
        margin-left: 3px

    .note-edit-container
      display: none
      flex-direction: column
      margin-top: 5px
      width: 100%

      .note-edit-container__text
        height: 194px
        font-size: 13px

      .note-edit-container__buttons
        display: flex
        justify-content: flex-end
        margin-top: 5px
        button
          margin-left: 5px
          width: unset
          i
            font-size: 14px
            margin-right: 0
            &.fa-times
              font-size: 15px

    .schedule-grid-preview-button-container
      margin-top: 10px
      flex-grow: 1
      align-self: flex-end
      .fad
        margin-right: 5px
        color: $primary-font-color
        font-size: 13px
      .btn.btn-primary
        background-color: #EFEFEF
        color: $primary-font-color
        padding-left: 55px
        text-align: left
        &:hover
          background-color: darken(#EFEFEF, 5%)

  .schedule-grid-preview-type-container,
  .schedule-grid-preview-last-appointments-container
    font-style: italic
  .schedule-grid-latest-appointments
    height: initial !important
    font-style: initial !important
  .preview-icon
    font-size: 14px !important
    line-height: 15px !important
  .preview-text
    margin-left: 2px !important

  .schedule-grid-preview-right
    flex-grow: 1
    text-align: left
    padding: 2px
    margin-left: 5px
    display: flex
    flex-direction: column
    .schedule-grid-preview-emk-container
      flex-grow: 1

    .schedule-grid-preview-client-groups
      font-style: italic
      max-width: 105px
      color: #ff5722

    .schedule-grid-preview-emk
      font-style: italic

    .add-reminder-with-client
      padding: 3px
      height: 22px

    .select2Buttons
      margin: 0
      li
        display: block
        clear: both
        margin: 1px 0
        width: 100%
        a
          width: 100%
          font-size: 11px
          &:hover
            transition: none

  .creator-container, .updator-container
    text-align: left
    font-size: 11px
    font-style: italic
    color: #979797
    padding-left: 5px


