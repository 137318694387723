.company-documents
  height: 100%
  .si-control
    .v-search-input
      width: 70%
      margin-bottom: 10px
    .btn-add-document
      margin-left: 40px
      margin-bottom: 12px

