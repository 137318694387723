@import '@/../sass/abstract/colors.sass'

.native-datetime-picker
  display: flex
  input
    padding: 4px
    border-radius: 4px
    border: 1px solid #dcdfe6

    &:active, &:focus
      border: 1px solid darken(#dcdfe6, 10%)
      outline: none

    &.simple-datetime-picker__date
      padding-left: 15px
      max-width: 150px

    &.simple-datetime-picker__time
      margin-left: 2px
      max-width: 80px

.has-error
  .native-datetime-picker
    input
      border-color: $red-light
