.dental-companies-form
  .form-value
    padding-bottom: 0

    .guarded-control
      width: 37px
      height: 28px
      font-size: 13px
      display: flex
      align-items: center
      justify-content: center
  
  .dental-companies-form__select
    width: 100%
    cursor: pointer

  .catalogs-form__btns
    margin-top: 7px
