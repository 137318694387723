.legal-form
  gap: 1%
  .form-label
    display: flex
    justify-content: flex-end
    align-items: center
  @media (max-width: 1500px)
    --flex-form-label-size: 110px
    --flex-form-value-size: calc(100% - 110px)
  .legal-form-middle,
  .form-block
    flex: 1 0 33%
