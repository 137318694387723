@mixin entry_block
  margin-bottom: 10px
  padding: 10px
  border: 1px solid $grey

#workplaces_page
  .modal-body
    #receipt_form
      .receipt_entry
        @include entry_block

        .calculated_sum
          padding-left: 20px

          .value
            padding: 5px
            font-size: 14px

      .receipt_payments
        @include entry_block

      .title
        min-height: 18px

        abbr.required
          color: $red

      .value
        .el-button.btn-danger
          &:focus
            border: 3px
          &:active
            border: 3px

        .el-input
          .el-input__inner
            border: 1px solid $grey
            border-radius: $border-radius-base
            height: 28px
            line-height: $primary-line-height
            box-shadow: none

          .el-input__inner[type="number"]
            &:focus
              padding-left: 15px
              padding-right: 15px

  .modal-footer
    #send_receipt_btn
      &:focus
        border: 3px
      &:active
        border: 3px

