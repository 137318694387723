.kdl-waybill-table
  width: 100%
  border-collapse: collapse
  border-spacing: 0
  height: auto
  margin-top: $indent-validation
  .table-header
    font-weight: bold

  tr
    min-width: 100%
    td
      text-align: center
      border: 1px solid #595959
      height: 35px
      padding: 3px
    .demarcation-line
      height: 10px
      background: lightgrey

    .number
      width: 50px

.kdl-waybill_print-button
  text-align: end
