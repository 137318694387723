table.scrollable-table
  display: flex
  flex-flow: column

  thead, tbody tr
    display: table
    table-layout: fixed

  thead
    flex: 0 0 auto

    label, input
      margin: 0

    input
      margin-top: 3px
      float: right

  tbody
    display: block
    flex: 1 1 auto
    overflow-y: scroll

    tr
      width: 100%
