#med_cases
  height: calc(100% - 44px)

.egisz-cases-tab
  height: 100% !important
  .egisz-cases-tab__controls
    align-items: stretch
    display: flex
    flex-direction: column

    .controls__filters
      display: flex
      align-items: center
      justify-content: space-between
      gap: 3px
      @media (max-width: 1400px)
        flex-wrap: wrap
        flex-grow: 1
        justify-content: flex-start

      .filters__date-range
        .el-date-editor--daterange
          max-height: 30px

  .egisz-cases-tab__table
    .col__case-closed
      font-size: 16px
      max-width: 40px

    .col__status
      max-width: 40px

    .col__period
      max-width: 200px

    .col__step_count
      max-width: 40px

    .col__options
      color: $red
      font-size: 16px
      max-width: 40px

  .egisz-cases-tab__footer
    padding-left: 8px
