.checkup {
  @include mFieldsFW;

  display: flex;
  flex-direction: column;
  height: 100%;
  .m-panel__body {
    overflow-y: scroll;
  }

  .checkup__content {
    display: flex;
    gap: $indent;
    flex-grow: 1;

    .checkup__left-side {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .checkup__right-side {
      flex-basis: 50%;
      flex-shrink: 0;
    }
  }

  .checkup__buttons {
    display: flex;
    gap: $indent-small
  }
}
