#entryTypeBreadcrumbsShow.EntryTypesBreadcrumb,
#entryTypeBreadcrumbsEdit.EntryTypesBreadcrumb
  height: 16px
  margin-bottom: $indent
  .breadcrumbs-body
    display: flex
    align-items: center
    font-style: italic
    height: 100%
    line-height: 100%
    width: 100%

