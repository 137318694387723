.text-primary
  color: $primary-color !important

.background-primary
  background-color: $primary-background-color

.p
  &-indent
    padding: $indent

  &t-
    &6
      padding-top: 6px

    &8
      padding-top: 8px !important

    &15
      padding-top: 15px !important

    &indent
      padding-top: $indent !important

    &indent-mid
      padding-top: $indent-mid !important

    &indent-small
      padding-top: $indent-small !important

  &b-
    &15
      padding-bottom: 15px !important
    &indent
      padding-bottom: $indent !important

    &indent-mid
      padding-bottom: $indent-mid !important

    &indent-small
      padding-bottom: $indent-small !important
  &l-
    &indent-small
      padding-left: $indent-small !important

  &r-
    &indent-small
      padding-right: $indent-small !important
    &indent-mid
      padding-right: $indent-mid !important
    &indent
      padding: $indent !important

  &y-
    &indent-small
      padding-top: $indent-small !important
      padding-bottom: $indent-small !important
    &indent-mid
      padding-top: $indent-mid !important
      padding-bottom: $indent-mid !important
    &indent
      padding-top: $indent !important
      padding-bottom: $indent !important

.m
  &-indent
    margin: $indent

  &t-
    &4
      margin-top: 4px

    &05
      margin-top: 5px

    &6
      margin-top: 6px

    &10
      margin-top: 10px

    &15
      margin-top: 15px !important

    &indent-validation
      margin-top: $indent-validation

    &indent
      margin-top: $indent

    &indent-mid
      margin-top: $indent-mid

    &indent-small
      margin-top: $indent-small


  &b-
    &0
      margin-bottom: 0 !important

    &10
      margin-bottom: 10px !important

    &15
      margin-bottom: 15px

    &25
      margin-bottom: 25px

    &indent-validation
      margin-bottom: $indent-validation

      &-important
        margin-bottom: $indent-validation !important

    &indent
      margin-bottom: $indent

    &indent-mid
      margin-bottom: $indent-mid

    &indent-small
      margin-bottom: $indent-small

  &r-
    &0
      margin-right: 0

    &5
      margin-right: 5px

    &10
      margin-right: 10px

    &15
      margin-right: 15px

  &l-
    &0
      margin-left: 0

    &5
      margin-left: 5px

    &10
      margin-left: 10px

    &15
      margin-left: 15px

    &auto
      margin-left: auto

  &x-
    &15
      margin-left: 15px
      margin-right: 15px

.font
  &-size
    &-12
      font-size: 12px

    &-13
      font-size: 13px

    &-14
      font-size: 14px

    &-15
      font-size: 15px

    &-17
      font-size: 17px

    &-24
      font-size: 24px

    &-32
      font-size: 32px

    &-72
      font-size: 72px

.align
  &-center
    align-items: center

  &-start
    align-items: flex-start !important

.inline-block
  display: inline-block !important

.min
  &-w
    &-35
      min-width: 35px

    &-80
      min-width: 80px

    &-95
      min-width: 95px

.max
  &-h
    &-30
      max-height: 30px

  &-w
    &-100
      max-width: 100%

.vh-100
  height: 100vh

.danger
  color: #ff5722

.grey
  color: $grey

.grey-dark
  color: $grey-dark

.canceled
  color: $canceled

.primary
  color: $primary-color

  &-important
    color: $primary-color !important

    &:hover
      color: lighten($primary-color, 10%) !important

.success-important
  color: $green !important

.primary-font-color
  color: $primary-font-color

.disabled-color
  color: $grey

.rotate
  &-z
    &-90
      transform: rotateZ(90deg)

.bold
  font-weight: bold

.width-fit-content
  width: fit-content

.width-auto
  width: auto

.no-gap
  gap: 0 !important

.gap-indent
  gap: $indent

.gap-indent-validation
  gap: $indent-validation

.gap-indent-mid
  gap: $indent-mid

.gap-indent-2
  gap: $indent * 2

.gap-indent-3
  gap: $indent * 3

.gap-indent-small
  gap: $indent-small

.opacity-65
  opacity: 0.65

.cursor-default
  cursor: default

.cursor-grab
  cursor: grab

.cursor-context-menu
  cursor: context-menu

.cursor-pointer
  cursor: pointer

.text-align-center
  text-align: center

@media print
  .print-flex
    display: flex !important

.showed-print
  display: none !important
  @media print
    display: flex !important

.showed-print-table
  display: none !important
  @media print
    display: table !important

.min-content
  width: min-content

.max-height-100
  max-height: 100%

.hover-underline
  &:hover
    text-decoration: underline

.word-break-all
  word-break: break-all

.overflow-wrap-anywhere
  overflow-wrap: anywhere

.absolute
  position: absolute

.striked
  text-decoration: line-through
  font-style: italic
  color: $grey

.destroyed
  font-style: italic
  color: $grey

.page-break
  border-top: 2px dashed $grey
  @media print
    page-break-before: always
    border-top: none

.flex-grow-0
  flex-grow: 0 !important

.flex-basis-50
  flex-basis: 50%

.height-max-content
  height: max-content

.overflow-x-scroll
  overflow-x: scroll

.overflow-x-hidden
  overflow-x: hidden

.overflow-y-hidden
  overflow-y: hidden

.w-default-input-width
  min-width: 230px
  width: 230px

.border-radius-0
  border-radius: 0 !important

.break
  &-inside
    &-avoid
     break-inside: avoid
.pointer-events-none
  pointer-events: none

.pointer-events-default
  pointer-events: auto

.w-500px
  width: 500px

.w-600px
  width: 600px

.w-700px
  width: 700px

.w-800px
  width: 800px

.background-hip-square
  background: url('assets/hip-square.png')
  @media print
    background: none

.display-none
  display: none !important
  @media print
    display: none !important
