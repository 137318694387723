#form_waiting_list_modal
  display: none
  .modal-dialog
    width: 90%
    height: calc(100% - 50px)
    margin-top: 20px
    .modal-content
      display: flex
      flex-direction: column
      .modal-header
        background-color: $orange
        max-height: 40px
      .modal-footer
        min-height: max-content
      .modal-body
        height: 100%
        overflow-y: scroll
        .modal-form-waiting-list-container
          display: flex
          flex-wrap: nowrap

          =form-elements-mixin
            .form-label
              display: flex
              justify-content: flex-end
              align-items: center
              flex: 0 0 35%
            .form-value
              display: flex
              align-items: center
              flex: 1 1 65%
              max-width: 65%
              padding-right: 0
              .form-group
                width: 100%

          /* ################### COLUMN-CLIENT ################### */

          .waiting-list-column-client
            flex: 1
            #clients_form
              margin-bottom: 0
              #clients_creation_form
                padding-right: 15px
                flex-basis: 100%
                .form-value
                  padding-right: 0
                .fs-appointment-date-label,
                .fs-appointment-date-container,
                .fs-appointment-time-label,
                .fs-appointment-time-container,
                .fs-appointment-user-label,
                .fs-appointment-user-container
                  display: none
              +form-elements-mixin

              #cient_additional_fields
                display: none

          /* ################### COLUMN-CLIENT-MATCH ################### */

          .waiting-list-column-client-match
            flex: 1

            =header-mixin($margin-top)
              background-color: $orange
              padding: 3px
              text-align: center
              margin-top: $margin-top
              margin-bottom: 5px
              color: white
              font-weight: bold
              font-size: 13px

            .waiting-list-similar-clients-container,
            .waiting-list-info-container
              .client-form-with-similar-header
                +header-mixin(0)
              .client-info-list
                table
                  tbody
                    tr
                      .medium-editor-show
                        display: table-cell
                        border: none
                        background: white
                        font-weight: normal
                        text-align: start
                        .client_service_card
                          width: 100%
                          .medium-editor-element
                            height: 185px
                            word-break: break-word

                      .service-card-title
                        border: none
                        padding: 0
                        background-color: white
                        div
                          +header-mixin(5px)

          /* ################### COLUMN-PARAMS ################### */

          .waiting-list-column-params
            flex: 1
            padding-left: 15px
            #waiting_list_form
              margin-bottom: 0
              .form-value
                padding-right: 0
                .waiting_list_additional
                  textarea
                    height: 150px
                    overflow-y: scroll
                  textarea::-webkit-scrollbar
                    width: 5px

              .fs-appointment-creator-container
                flex-wrap: wrap
                div
                  width: 100%
            +form-elements-mixin

