.tax-certificate-2024-printable-page-two {
  &__footer {
    display: flex;
    flex-direction: column;
  }

  @media print {
    &__footer {
      margin-top: 700px; // костыль для футера при печати в конце последней страницы
    }
  }
}
