.suggestion-search + .suggestions-wrapper
  display: block
  .suggestions-suggestions
    border: 1px solid #{$select-border-color}
    border-radius: 4px
    background-color: $select-bg
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
    margin: 5px 0
    padding: 6px 0
    position: relative
    transform: translateY(7px)

    // arrow
    &::before,
    &::after
      content: ''
      position: absolute
      display: block
      width: 0
      height: 0
      border-color: transparent
      border-style: solid
      border-width: 6px
      border-top-width: 0
      left: 35px
    &::before
      top: -6px
      border-bottom-color: $select-border-color
    &::after
      top: -5px
      border-bottom-color: $select-bg

  .suggestions-suggestion, .suggestions-hint
    font-size: 14px
    padding: 0 20px
    position: relative
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    color: $select-text
    cursor: pointer

  .suggestions-suggestion
    line-height: $select-line-height
    &:hover
      background-color: $select-option-hover

  .suggestions-subtext
    line-height: 1
    margin-bottom: 8px
    .suggestions-subtext
      margin-bottom: 0

  .suggestions-hint
    color: #C0C4CC
    cursor: not-allowed
    line-height: $select-line-height
    margin-bottom: 0
