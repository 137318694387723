
.appointment-call-log-wrapper {
  .el-dialog {
    height: 100%;
    max-height: 90vh;
    margin-top: 5vh !important; // перебить js стили

    &.is-fullscreen {
      max-height: 100vh;
      margin-top: unset !important;
    }
  }

  .m-date-picker.m-date-picker_range {
    max-width: 260px;
  }

  .filter-date-picker-buttons {
    margin-top: 0;

    .m-button-wrapper {
      margin-bottom: 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .filters__clinics {
    .m-select {
      width: 100%;
    }
  }

  .si-table {
    .si-field {
      cursor: default;
    }

    &-hide-clinicTitle {
      .col__clinicTitle {
        display: none;
      }
    }
  }

  .col {
    &__clinicTitle {
      flex-grow: 1;
    }

    &__dateTime {
      flex-grow: 0;
      flex-basis: 150px;
    }

    &__doctorNameWithSpecialties {
      flex-grow: 2;
    }

    &__clientData {
      flex-grow: 4;
    }

    &__phone {
      flex-grow: 0;
      flex-basis: 200px;
      @media screen and (max-width: 1600px) {
        flex-basis: 160px;
      }
    }

    &__status,
    &__callStatus,
    {
      flex-grow: 0;
      flex-basis: 100px;
      @media screen and (max-width: 1600px) {
        flex-basis: 60px;
      }
    }

    &__note,
    &__entryTypes,
    &__findAppointment,
    {
      flex-grow: 0;
      flex-basis: 30px;
    }

    &__editAppointment {
      flex-grow: 0;
      flex-basis: 40px;
    }

    &__note {
      .m-icon {
        margin-top: 2px; // нюансы отрисовки
      }
    }
  }
}

.appointment-status-icon {
  &__need_approval {
    color: $primary-color
  }
  &__approved {
    color: $primary-color
  }
  &__arrived {
    color: $appointment-status-arrived
  }
  &__serviced {
    color: $appointment-status-serviced
  }
  &__billed {
    color: $appointment-status-billed
  }
  &__paid {
    color: $appointment-status-paid
  }
  &__canceled {
    color: $appointment-status-canceled
  }
  &__failed {
    color: $appointment-status-failed
  }
  &__confirmed_by_administrator {
    color: $appointment-status-confirmed-by-administrator
  }
}

.appointment-call-status-icon {
  &__pending {
    color: $appointment-call-status-pending;
  }
  &__confirmed {
    color: $appointment-call-status-confirmed;
  }
  &__client_canceled {
    color: $appointment-call-status-client-canceled;
  }
  &__failed_to_connect {
    color: $appointment-call-status-failed-to-connect;
  }
}
