$document-width: 180mm;
$semd-panel-width: 500px;

.checkup-document-editor-new,
.checkup-document-editor-edit,
.checkup-document-editor-show {
  > .m-panel__body {
    align-items: center;
  }

  > .m-panel__footer {
    display: flex;
    gap: $indent-small
  }

  > .m-panel__footer,
  > .m-panel__head {
    padding-left: calc(50vw - $document-width / 2);
    padding-right: calc(50vw - $document-width / 2);
    @media print {
      padding: unset !important;
    }
  }

  @media print {
    display: block;
    height: unset !important;
    > .m-panel__body {
      flex-grow: 0;
    }

    > .m-panel__footer,
    > .m-panel__head {
      display: none !important;
    }
  }
}

.checkup-document-wrapper {
  height: 100%;

  @media print {
    height: unset !important;
  }

  & > .m-panel__body {
    overflow-x: hidden;
  }

  .checkup-document {
    min-width: $document-width;
    max-width: $document-width;
    width: 100%;
    flex-basis: $document-width;

    display: flex;
    flex-direction: column;
  }
}

.checkup-document-editor-show.checkups-module__content_is-semd {
  > .m-panel__footer,
  > .m-panel__head {
    padding-left: calc((100vw - $document-width - $semd-panel-width - $indent) / 2);
    padding-right: calc((100vw - $document-width - $semd-panel-width - $indent) / 2);
    @media print {
      padding: unset !important;
    }
  }

  .checkup-document-semd-stub {
    display: flex;
    flex-basis: $semd-panel-width;
  }

  .semd-modal-wrapper {
    position: fixed;
    right: calc((100vw - $document-width - $semd-panel-width - $indent) / 2);
    height: fit-content;
    flex-grow: 0 !important;
    width: $semd-panel-width;
  }
}
