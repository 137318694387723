.mdlp_documents_page
  .btn_with_options_dropdown
      .popper__arrow
        display: none
        
  #mdlp_documents_page_root
    display: flex
    flex-grow: 1

    .main-item-left
      .panel-heading
        display: flex
        align-items: center

      .catalog-item
        cursor: pointer


    .panel.panel-primary:last-child
      display: flex
      flex-direction: column
      flex-grow: 1

      .mdlp_documents_component_wrapper
        display: flex
        flex-direction: column
        height: inherit
        
    #documents_list
      .si-table
        .documents_list_si_column
          flex: 1
    .list-index-panel
      .list-index-header
        color: $turquoise !important
    
