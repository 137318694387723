@media print {
  .fixed-document {

    .m-panel__body {
      align-items: flex-start;
      padding: 0;
      border-radius: 0 !important;
    }

    &-print-header, &-print-footer {
      display: none;
    }

    &-printable-container {
      border: none !important;
      padding: 1px;
      width: 210mm;
    }
  }
}
