.client-form
  .el-date-editor.el-input, .el-select.base-select
    width: 100%
    max-width: initial
  .el-input__inner
    border-radius: $border-radius-base
  .form-label
    display: flex
    align-items: center
    justify-content: flex-end
  .form-value
    .flex
      height: 100%
      .form-control, .validation-wrapper
        flex-grow: 1
  .filterable-select
    max-width: 100%
    width: 100%

  .input-container
    display: flex
    .input-button
      height: auto

.client-form-tabs
  .el-tabs__content
    overflow: visible
    color: $primary-font-color

.legal-representation-select
  .el-input__inner
    max-height: unset
