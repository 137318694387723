@mixin button-margins {
  margin-right: $indent-small;
}

.btn.math-panel__control {
  @include button-margins;
}

#variables-container {
  display: flex;
  gap: $indent-small;
  flex-wrap: wrap;
}
