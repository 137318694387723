table.merge-client-table
  thead
    display: block
  tbody
    display: block
    overflow-y: auto
    height: 350px
  .checked
    width: 50px
  .number
    width: 80px
  .fullname
    width: 395px
  .phone
    width: 150px
  .birthdate
    width: 120px
  .created_at
    width: 120px