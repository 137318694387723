.client-tabs
  .input-button-icon
    min-width: 28px
    height: auto
    margin: 0 0 0 2px
    padding: 0
  .el-date-editor.el-input, .el-select.base-select
    width: 100%
    max-width: initial
  .suggestions-wrapper
    display: block

