.appointment-time-finder-wrapper
  .el-dialog
    height: 100%
    max-height: 90vh
    margin-top: 5vh !important // перебить js стили
    &.is-fullscreen
      max-height: 100vh
      margin-top: unset !important

  .remove-mr
    margin: unset
    .el-input__inner
      margin: unset

  .el-input-view-fixer
    .el-input__inner
      border-radius: 4px
      border-color: $grey
      &:active, &:focus
        border-color: $primary-color

  .si-control-btns
    .component
      margin: unset
    .btn
      padding: 5px 6px !important // протечка
      min-width: 45px
      &:first-child
        margin-right: 2px
      &.reset_btn
        display: flex
        justify-content: center

  .time-finder__filter-by
    max-width: 150px

  .time_select,
  .duration
    max-width: 100px

  .clinics,
  .doctors,
  .specialties,
  .entry-types
    flex-grow: 1
    .el-select
      width: 100%
    .el-select__input
      padding-left: 0 !important

  .recording-points-no-data
    display: flex
    justify-content: center
    height: 100%
    align-items: center

  .col__scheduleActions
    max-width: 60px

  .el-select-multi
    .el-select__tags
      flex-wrap: nowrap
      padding-right: 30px

      > span
        display: flex
        max-width: 70%

    &.more-than-1
      span+.el-select__input
        margin-left: 50px
        width: 60px !important


