.duration-selector-container
  display: flex
  .new_value_input_container
    display: inline-block
    border: 1px solid #ccc
    height: 25px
    background: #eeeeef
    border-radius: 2px
    overflow: hidden
    margin-top: 2px

    input
      margin: 0
      margin-top: 1px
      padding: 4px
      text-align: center
      height: 23px
      min-height: unset !important
      outline: none
      background: #eeeeef
      border: none


    .new-value-submit
      margin-left: 10px
      cursor: pointer
      color: #8BC34A

    .new-value-cancel
      margin-left: 10px
      margin-right: 10px
      color: #F44336
      cursor: pointer
  
  .vue-items-list
    .vue-list
      li
        min-width: 37px
        background-color: #EFEFEF
        border: 1px solid #ccc
        padding: 3px
        color: #474747
        transition: none
        -webkit-transition: none
        
        &:hover
          background: #e2e2e2
          border: 1px solid #ccc

        &.active
          background: #3FAECA
          border-color: #3FAECA
          color: white
          text-decoration: none
          transition-delay: 0
          transition: none
