.checkup__left-side,
.checkup__right-side {
  display: flex;
  flex-direction: column;
  gap: $indent;
  max-width: calc(50% - 7.5px);
}

.checkup__left-side {
  .personal-checkup-info,
  .personal-checkup-conclusion {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 0 !important;

    .m-panel__body {
      flex-direction: row;
      gap: $indent;
    }

    .personal-checkup-info__left-side,
    .personal-checkup-info__right-side,
    .personal-checkup-conclusion__left-side,
    .personal-checkup-conclusion__right-side {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
    }
  }

  .personal-checkup-info {
    .m-panel__body {
      flex-direction: column;
      width: 100%;
      gap: 0;
    }

    &__link-container {
      position: absolute;
      top: 30px;

      display: flex;
      gap: $indent-mid;
      padding-left: $indent-mid;
    }

    &__comment {
      @mixin comment($height) {
        min-height: $height;
        height: $height;
      }

      .el-textarea__inner {
        @include comment(84px !important);
      }

      &_with-position-profile {
        .el-textarea__inner {
          @include comment(138px !important);
        }
      }
    }
  }
}

.personal-checkup-conclusion {
  flex-wrap: nowrap !important;
  max-width: 100%;

  @media screen and (max-width: 1600px) {
    .m-panel__body {
      flex-direction: column !important;
    }

    &__left-side,
    &__right-side {
      max-width: 100% !important;
    }
  }

  &__left-side,
  &__right-side {
    max-width: calc(50% - ($indent / 2)); // для размещения двух MSIGenerator в ряд c отступами

    .m-si-generator {
      .m-table__header {
        height: 0;
      }
    }
  }
}

.personal-checkup-nested-item-modal__question-mark-tooltip {
  ul {
    list-style-type: none;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: $indent-small;
  }
}

