@import "entityDraggableWindow";

.protocol-editor-view {
  border-radius: $border-radius-ext-borders;
  box-shadow: $box-shadow;


  #frontend-toolbar {
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    min-height: 165px;

    & > .mce-tinymce {
      width: calc(100% - 2px) !important;
      border-radius: $border-radius-base $border-radius-base 0 0;

      & > .mce-container-body {
        width: inherit !important;

        .mce-toolbar {
          width: inherit !important;
        }
      }
    }
  }

  #editor-body {
    border-top: 0;
    min-width: 210mm;
    max-width: 210mm;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    margin-top: unset;
    border-radius: 0 0 $border-radius-base $border-radius-base;
  }

  .protocol-editor-view__form {
    $protocol_form_width: 350px;
    max-width: $protocol_form_width;
    min-width: $protocol_form_width;
  }

  .protocol-draft-card {
    .el-card__body {
      padding: $indent-mid;
    }

    .m-card__body {
      gap: $indent-mid;
      overflow-y: hidden;
      margin-top: -4px;

      .m-button__text {
        margin-top: unset;
      }
    }

    &__icon {
      font-size: 54px;
      margin-top: $indent-small - 3px;
      opacity: 0.70;
    }

    &__content {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &__content-title {
      flex-grow: 1;
    }
  }

  .protocol__form {
    flex-grow: 1;
    flex-direction: column;
    gap: 0;

    .protocol__form-section {
      flex-basis: unset;
    }
  }


  .protocol-entity-connector {
    display: contents;
  }
}
