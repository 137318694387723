.entry-types-tree-app {
  overflow-x: hidden;

  .el-tree-node__content {
    height: $element-height;
    display: flex;
    align-items: center;
  }
}

