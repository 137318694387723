.entry-packs-catalog {
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  display: flex;
  gap: $indent-small;

  .entry-packs-catalog__tree {
    flex-basis: 40%;
  }

  .entry-packs-catalog__edit {
    flex-basis: 60%;
  }

  .entry-packs-editor__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .entry-packs-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: scroll;
    margin-bottom: $indent-mid;
  }

  .entry-packs-container__totals-counter {
    margin-left: auto;
    font-style: italic;
  }

  .entry-pack-entry-wrapper {
    display: flex;
    flex-direction: column;
    padding: $indent-small 0 $indent-mid;

    &:hover {
      background-color: $primary-background-color-dark;
    }

    &__allowed-clinics {
      margin-left: 30px;
    }
  }

  .entry-pack-entry {
    display: flex;
    align-items: center;
    max-width: 100%;
    width: 100%;
    gap: $indent-mid;

    &__kind {
      flex-basis: 20px;
      flex-shrink: 0;
    }

    &__title {
      flex-grow: 1;
    }

    &__price,
    &__sum {
      flex-basis: 80px;
      flex-shrink: 0;
    }

    &__amount {
      flex-basis: 85px;
      flex-shrink: 0;
    }

    &__action {
      text-decoration: none !important;
      cursor: pointer;
      flex-basis: 30px;
      flex-shrink: 0;
    }

    &__measure-unit {
      flex-basis: 50px;
      flex-shrink: 0;
    }
  }
}

.entry-packs-tree {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .el-tree-node__content {
    height: $element-height;
    padding-right: $indent;
  }

  .is-drop-inner > .el-tree-node__content {
    background-color: $primary-background-color-dark;
    color: $primary-color
  }
}

.entry-pack-add-confirm-modal {

}

.entry-pack-entry-type-wrapper {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  flex-grow: 1;
}

.entry-pack-entry-types {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
}

.entry-pack-entry-type {
  display: flex;
  align-items: center;
  height: $element-height + $indent-small;
  min-height: $element-height + $indent-small;
  gap: $indent;

  &__kind {
    flex-basis: 20px;
    flex-shrink: 0;
  }

  &__amount {
    flex-basis: 80px;
    flex-shrink: 0;
  }

  &__price, &__sum {
    flex-basis: 100px;
    flex-shrink: 0;
  }

  &__remove {
    flex-basis: 30px;
    flex-shrink: 0;
  }

  &__measure-unit {
    flex-basis: 50px;
    flex-shrink: 0;
  }
}
