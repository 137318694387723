@import 'components/EntryTypesTransfer';
@import 'components/TreatmentPlansEntriesTransfer';

.entries-tab {
  .entries-tab__filters-wrapper {
    display: flex;
    flex-direction: column;
    gap: $indent-mid;
  }

  .entries-tab__filters {
    display: flex;
    gap: $indent-small;
    align-items: center;
    flex-wrap: wrap;

    .entries-tab__filter {
      flex-basis: 150px;
    }

    .entries-tab__filter {
      &.entries-tab__filter_date {
        max-width: 250px;

        .m-date-picker {
          max-width: 250px;
        }
      }

      &.entries-tab__filter_clinics {
        flex-grow: 1;

        .m-select {
          width: 100%;
        }
      }

      &.entries-tab__filter_mine {
        flex-basis: unset;
        align-items: center;
        margin-bottom: 0;
      }
    }
  }
}
