.entry-protocols {
  min-height: 120px;

  > .m-si-generator {
    max-height: 400px;
  }
}


.entry-protocols__client-protocols {
  .el-dialog {
    height: 100%;
  }

  .el-dialog__body {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    padding: $indent-small;
    position: relative;

    .client-protocols__notice {
      position: absolute;
      left: 58px;
      top: 12px;
    }

    .m-si-generator {
      flex-grow: 1;
    }
  }

  .el-dialog__footer {
    padding-top: $indent;
  }
}
