.employee-schedule-grid-right-panel
  margin-left: 5px
  padding-bottom: 5px

  $background-transition: background-color 30ms linear, border 30ms linear

  =background-border-color($background-color, $border-color)
    background-color: $background-color
    border: 1px solid $border-color
    transition: $background-transition

  =list-style
    display: flex
    justify-content: space-between
    align-items: center
    width: 200px
    height: 32px
    max-height: 32px
    border-radius: 3px
    color: white
    cursor: pointer
    padding: 0 5px
    margin-bottom: 2px
    span
      color: white

  .panel-heading
    .show-control-elements, .hide-control-elements
      cursor: pointer
      margin-left: 9px
      font-size: 1.5em
    .show-control-elements
      display: none

  .panel-body
    padding: 5px
    display: flex
    flex-direction: column
    overflow-y: scroll
    .inner-container
      height: 100%
      .rolled-content
        display: none
      #calendar-hide-button
        +list-style
        +background-border-color($grey-dark, $grey-dark)
        .item-left
          span
            margin-right: 5px
        .item-right
          span
            line-height: unset
        &:hover
          +background-border-color($grey-dark-hover, $grey-dark-hover)

      .schedule-calendar
        max-width: 200px
        +jquery-calendar-component
        .datepicker
          min-width: auto
          div table thead tr
            .prev, .next
              width: 32px !important
              min-width: 32px !important

      .control-component
        ul
          margin-top: 10px
          margin-right: 0
          padding-left: 0
          background: white
          list-style: none
          li
            +list-style
            .item-left
              span:first-of-type
                margin-right: 5px
            .item-left, .item-right
              display: flex
              align-items: center
              span
                line-height: unset
              .selected-counter
                display: inline-flex
                justify-content: center
                align-items: center
                max-width: min-content
                min-width: 16px
                height: 16px
                border-radius: 16px
                padding: 0 3px 0 2px
                margin-right: 5px
                background-color: $grey-dark
                color: white
                white-space: initial
            .item-right
              min-width: max-content

          li.with-avatar
            .user-avatar-container
              img
                border: 1px solid white
                border-radius: 50%
                height: 22px
                width: 22px

          li.specialty-item
            +background-border-color($primary-color, $primary-color)
            &:hover
              +background-border-color($primary-color-hover, $primary-color-hover)

          li.cabinet-item
            +background-border-color($green, $green)
            &:hover
              +background-border-color($green-hover, $green-hover)

          li.user-item
            +background-border-color(white, $grey)
            color: $primary-font-color
            span
              color: $primary-font-color
            &:hover
              +background-border-color(#f8f8f8, $grey)

          li.active
            +background-border-color($grey-dark, $grey-dark)
            color: white
            span
              color: white
            &:hover
              +background-border-color($grey-dark-hover, $grey-dark-hover)

.employee-schedule-grid-right-panel.hide-control-elements
  width: 30px
  .panel-heading
    padding-left: 10px
    .hide-control-elements
      display: none
    .show-control-elements
      display: inline-block
  .panel-body
    overflow: hidden
    height: 200px
    .inner-container
      overflow-y: hidden
      padding-top: 0
      .schedule-calendar, .control-component
        display: none
      .rolled-content
        display: block
        margin-top: 20px
        white-space: nowrap
        transform: rotate(90deg)

