.v-company-form
  display: flex
  .box
    flex: 1
    &.form-tabs
      padding-left: 15px
      .checkbox:not(:disabled)
        cursor: pointer
  .form-label
    display: flex
    justify-content: flex-end
    align-items: center
