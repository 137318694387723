$menu-bg-color: #fff
$menu-border-color: #ccc
$active-ctx-item-color: #2980b9

@mixin menu-style
  background-color: $menu-bg-color
  border: 1px solid $menu-border-color
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5)
  display: inline-block
  min-width: 100px
  position: fixed
  padding: 5px
  z-index: 9999

.ctx-menu
  @include menu-style
  outline: none
  .ctx-item
    cursor: pointer
    font-family: Verdana, Arial, Helvetica, sans-serif
    font-size: 12px
    font-weight: normal
    &.underline
      border-bottom: 1px dashed $menu-border-color
    .ctx-title
      padding: 8px 24px 8px 10px
      position: relative
      &.active
       background-color: $active-ctx-item-color
       color: #fff
      .ctx-icon
        margin-right: 10px
        color: #3FAECA
      .direction-ico
        position: absolute
        right: 5px
        top: 9px
    .submenu
      @include menu-style
