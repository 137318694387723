.custom_message_editor_modal
  width: 450px
  .modal-header
    .modal-title
      font-size: 15px
    .modal-buttons
      flex-basis: 0 

  textarea
    border: 1px solid #ccc
    &:focus
      padding: 5px 15px
      box-shadow: none
      border-radius: 4px
    
