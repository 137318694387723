.m-dropdown
  &.m-dropdown_fixed-sizes
    max-height: 50vh
    overflow-y: auto

    .el-dropdown-menu__item
      max-width: 300px

      &:not(.is-disabled):hover
        color: $primary-color
