.company-form-tabbed
  .flex
    flex-wrap: wrap

  .el-tabs__nav
    border: none

  input:not(.checkbox)
    font-size: 13px
    line-height: 146%
    border-radius: 4px
    height: 28px !important
    padding: 3px 6px !important
    box-shadow: none
    color: #555

  input:not(.suggestion-search)
    border: solid 1px $grey

  textarea
    font-size: 13px
    color: #555
    padding: 3px 6px
    border: solid 1px $grey
  
  textarea:hover 
    border: solid 1px $grey

  .el-tabs__item
    transition: all 0.1s linear
    font-size: 13px
    height: auto

.company-form-tabbed.simple_form:not(.all-in-tabs)
  .company-form-tabbed__box
    max-width: 50%
  
  .company-form-tabs
    max-width: 50%
    padding-left: 15px

  .el-tabs__nav-wrap
    background-color: $primary-color

  .el-tabs__item
    line-height: normal
    padding: 7px 15px
    border-left: none
    color: #fff
    border-bottom: 3px solid $primary-color

  .el-tabs__item:hover 
    background-color: $link-color

  .el-tabs__item.is-active
    background-color: $link-color
    border-bottom: 3px solid $grey-blue

  .contact-tab-error #tab-contacts
    background-color: $red-light
    border-bottom-color: $grey-blue

.company-form-tabbed.all-in-tabs
  .company-form-tabs
    .el-tabs__header
      border-bottom: 1px solid $grey-light-border
      margin-bottom: 10px

    .el-tabs__item
      border: 1px solid white
      background-color: $grey-light-bg
      border-bottom-color: $grey-light-border
      line-height: 146%
      padding: 5px 10px
      color: $primary-font-color

    .el-tabs__item:hover
      background-color: $grey
      color: $primary-font-color

    .el-tabs__item.is-active
      background-color: white
      border: 1px solid $grey
      border-bottom: 1px solid white
      border-radius: 2px 2px 0 0
      color: $primary-font-color

    .vue-list-item
      padding: 3px !important
      font-size: 12px
      line-height: 142%

  .company-tab-error #tab-company, .contact-tab-error #tab-contacts
    background-color: $red-light
    border-bottom-color: $red-light
