.associated-protocol-selected,
.entry-association
  .si-field
    .options
      font-size: 16px
      flex-basis: 30px
    .title
      max-width: 100%
      flex: 1
    div
      flex-basis: 90px

.associated-protocol-selected
  .si-table
    .header
      border: none
    .body
      overflow-y: auto
      .si-field
        &:hover
          background-color: transparent
        .options
          color: #3FAECA
          cursor: pointer
          .tbl-icon
            cursor: auto

.associated-protocol-tree
  .si-table .body .si-field.disabled-field
    cursor: not-allowed


.entry-type-template-editor-title,
.entry-type-template-editor-body
  background-color: #fff

.entry-type-template-editor-wrap
  display: flex
  flex-direction: column

.entry-type-template-editor-toolbar
  margin: auto

.modal-index-zero
  z-index: 0