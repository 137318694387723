.textarea-proxy
  &.use-backend-z-index
    z-index: 10001

  .textarea-proxy__vertical-mode
    margin-bottom: 0 !important

  $field-height: 200px

  #textarea_proxy_textarea
    // костыли от лишнего растяжения и сжатия
    height: $field-height !important
    max-height: $field-height !important
    min-height: $field-height !important

    overflow-y: scroll
    word-break: normal
    margin-bottom: $indent

  .m-popover
    min-height: 615px
    min-width: 615px !important
    max-height: calc(100vh - 67px)
    position: relative
    resize: both
    overflow: hidden
    max-width: unset !important
    display: flex
    flex-direction: column

    .m-popover__title
      position: absolute
      cursor: grab
      top: 0
      left: 0
      right: 0
      height: 30px

    .m-popover__message
      flex-grow: 1
      max-height: calc(100% - 40px)
      display: flex
      flex-direction: column

      .textarea-proxy__tree
        flex-grow: 1
        overflow-y: scroll
        font-size: 13px

        .el-tree-node
          margin-bottom: $indent-small

          .el-tree-node__content
            height: unset

          .custom-tree-node
            white-space: break-spaces
            word-break: break-word
            padding-right: $indent-small

    .m-popover__footer
      flex-grow: 0
      flex-basis: 40px

@media print
  .treatment_plan_sum_area
    .row
      margin-left: 0
