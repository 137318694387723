ul.nav:not(.navbar-nav) {
  $border-width: 3px;
  margin-bottom: $indent-mid;
  padding-bottom: $border-width - 2; /* Коррекция бордера у li */

  li {
    height: 30px;

    a[data-toggle="tab"] {
      height: 30px;
      padding: 3px 10px;
      color: white;
      border: 0;
    }

    border-bottom: $border-width solid transparent;

    &.active {
      border-bottom-color: $grey-dark;

      a[data-toggle="tab"] {
        background-color: unset !important;
        border: 0;
      }
    }

    &:hover {
      a[data-toggle="tab"] {
        background-color: unset !important;
        border: 0;
      }
    }

    a[data-toggle="tab"]:hover,
    a[data-toggle="tab"]:focus {
      background-color: unset !important;
      border: 0;
    }
  }
}

@mixin ul-nav-primary {
  ul.nav {
    background-color: $primary-color;

    li:not(.active):hover {
      background-color: darken($primary-color, 5%)
    }
  }
}

.panel {
  &.panel-primary {
    @include ul-nav-primary;
  }

  &.panel-success {
    ul.nav {
      background-color: $success;

      li:not(.active):hover {
        background-color: darken($success, 5%)
      }
    }
  }
}

.modal {
  @include ul-nav-primary;
}

