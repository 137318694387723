@import "taxCertificate2024PrintablePageOne";
@import "taxCertificate2024PrintablePageTwo";

.fixed-document__container {
  overflow-y: hidden;
  padding: 0;

  @media print {
    overflow-y: unset;
  }
}
