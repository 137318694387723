.clinics-buttons-group
  .el-radio-button.is-active
    .el-radio-button__inner
      background-color: $primary-color
      border-color: $primary-color

    &:hover
      opacity: 0.9
      .el-radio-button__inner
        color: $white
