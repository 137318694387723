@import "styles/semd119";
@import "styles/semd230";

.semd-form {
  display: flex;
  width: 100%;
  flex-grow: 1;
  gap: $indent;
  padding: $indent-small;

  .m-select {
    .el-input__inner {
      max-height: $element-height !important;
    }
  }

  .semd-form__section {
    display: flex;
    flex-direction: column;
    gap: $indent;
  }
}

.registration-errors__errors-list {
  max-height: 250px;
  overflow-y: scroll;
}

.semd-document-attributes-container {
  width: 100%;
  min-height: 260px;
  max-height: 520px;
  border: 1px solid $el-grey;
  border-radius: $border-radius-base;
  overflow-y: scroll;
  padding: $indent;
}

.semd-modal {
  & > .m-modal {
    .el-dialog__footer {
      padding-top: $indent;
    }
  }

  .semd-document-attributes-container {
    .semd-area,
    .entry_template_diagnosis_props,
    .diagnosis-btn,
    .diagnoses_constructor,
    .print_area_start,
    .print_area_end {
      display: none;
    }
  }
}

.semd-state-block {
  height: 50px;

  .el-alert {
    height: inherit;
  }

  .el-alert__content {
    margin-top: $indent-small;
  }
}

.semd-modal-wrapper {
  &.semd-modal-wrapper_button {
    display: flex;
    gap: 0;

    .semd-modal-wrapper__open-modal {
      .m-button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .semd-modal-wrapper__semds-dropdown {
      .m-button {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
