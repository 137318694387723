.avatar-edit:focus
  outline: none
.avatar-edit
  width: 150px
  height: 150px
  position: relative
  float: left
  .epic-spinner-loading-mask
    border-radius: 100%
  .image
    width: 100%
    height: 100%
    border-radius: 100%
    -o-object-fit: cover
    object-fit: cover
    margin: 0
  .control
    width: 100%
    height: 100%
    position: absolute
    top: 0
    display: flex
    justify-content: center
    align-items: center
    background-color: rgba(0, 0, 0, 0.3)
    border-radius: 100%
    .buttons
      display: flex
      align-items: center
      .button
        color: #f6f6f6
        height: 30px
        width: 30px
        margin: 2.5px
        display: flex
        justify-content: center
        align-items: center
        border-radius: 100%
        cursor: pointer
        font-size: 14px
      .upload
        background-color: #8BC34A
      .upload:hover
        background-color: #71a436
      .remove
        background-color: #F44336
      .remove:hover
        background-color: #f32c1e
  #imageInput
    display: none
  .modal
    .avatar
      @media screen and (max-width: 768px)
        height: 100%
        width: 90%
      @media screen and (min-width: 768px) and (max-width: 1024px)
        width: 90%
      @media screen and (min-width: 1024px) and (max-width: 1280px)
        width: 80%
      @media screen and (min-width: 1280px) and (max-width: 1440px)
        width: 70%
      @media screen and (min-width: 1440px)
        width: 50%
      .modal-content
        .modal-body
          @media screen and (max-width: 768px)
            .vue-image-cropper
              display: block
              .cropped-image
                width: 100%
                height: 50%
              .preview-box
                width: 100%
  .modal
    .confirming-avatar-removal-modal
      margin: 30px auto
      display: block
      width: auto
      @media screen and (min-width: 768px)
        width: 600px
