=reminders-list-component
  #reminders-list-wrapper
    height: 0
    flex-basis: 100%
    display: flex
    flex-direction: column
    position: relative
    .schedule-reminders-list-control-element
      width: 100%
      margin-bottom: 5px
      .reminders-datepicker
        display: inline-block
        width: 100%
        font-size: 12px
        font-weight: bold
        margin-bottom: 5px
        .btn
          display: flex
          height: 30px
          justify-content: center
          align-items: center
          .fad
            margin: 0
        .date-container
          display: inline-block
          padding-top: 6px
        .reminders-datepicker-left, .reminders-datepicker-right
          width: 35px
          text-align: center
          vertical-align: initial
          margin: 0
          span
            margin-left: -5px /* fix task right, left buttons */
        .reminders-datepicker-left
          float: left
        .reminders-datepicker-right
          float: right
      .add-reminder
        margin: 0
        width: 100%
    .reminders-list
      height: 100%
      overflow-y: scroll
      ul
        list-style: none
        margin: 0
        padding: 0
        li
          padding: 5px
          cursor: pointer
          text-align: left
          font-size: 13px
          border-bottom: 1px solid $grey
          &:hover
            background-color: rgba(139, 195, 74, 0.1)
          div
            .status-container, .type-container, .title-container
              display: inline-block
              span
                margin: 0
              .fa-check-square
                color: $green
              .fa-user-hard-hat, .fa-phone, .fa-envelope
                color: $primary-color

