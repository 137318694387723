#report_treatment_plans {
  // Может пригодится, а может и нет
  //.hide-doctor {
  //  .col__doctorCell {
  //    display: none;
  //  }
  //}
  //
  //.hide-responsible {
  //  .col__responsibleCell {
  //    display: none;
  //  }
  //}

  .m-table__header {
    .m-table-row-cell {
      white-space: normal;
      overflow-wrap: anywhere;
    }
  }

  .m-table__footer {
    .col__numberCell {
      span {
        position: absolute;
      }
    }
  }
}

.treatment-plans-reports {
  &__filters-app {
    margin-top: auto;
  }
}
