@mixin all-button-kinds-to-element() {
  &.el-button--primary {
    @include btn-to-element(
                    $primary-color,
                    $primary-background-color,
                    $primary-border-color,
    );
  }

  &.el-button--success {
    @include btn-to-element(
                    $success-color,
                    $success-background-color,
                    $success-border-color,
    );
  }

  &.el-button--warning {
    @include btn-to-element(
                    $warning-color,
                    $warning-background-color,
                    $warning-border-color
    )
  }

  &.el-button--danger {
    @include btn-to-element(
                    $danger-color,
                    $danger-background-color,
                    $danger-border-color
    )
  }

  &.el-button--dark {
    @include btn-to-element(
                    $dark-color,
                    $dark-background-color,
                    $dark-border-color
    )
  }
}
