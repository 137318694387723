.variables-editor-whatsapp {
  align-items: center;

  .variables-editor__title {
    margin-bottom: $indent;

    .variables-editor__editorTitle {
      font-size: $header-font-size;
      color: $grey-dark;
      font-weight: bold;
    }

    .variables-editor__titleClarification {
      color: $grey-light;
      font-style: italic;
    }
  }

  .variables-editor-whatsapp__editor-field {
    min-height: 108px;
    border: 1px solid $el-grey;
    border-radius: $border-radius-base;
    margin-bottom: $indent-validation;
    color: #606266;
    padding: $indent-small $indent;

    .variable {
      cursor: pointer;
      background: $light-grey;
      border-radius: $border-radius-base;
      padding: 1px 10px;
    }
  }
}
