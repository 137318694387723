.m-menu
  flex-grow: 1
  overflow-x: hidden

  &::-webkit-scrollbar
    width: 1px
    height: 1px

  &::-webkit-scrollbar-track-piece
    background-color: $white

  &::-webkit-scrollbar-thumb
    background-color: $grey-dark

  .m-menu-item
    height: $menu-item-height
    padding: 0 $indent-small 0 $indent-small !important
    display: flex
    align-items: center
    min-width: $menu-item-width

    &.is-active
      background-color: $primary-background-color-dark

    &.is-disabled
      opacity: 1
      color: $grey-blue
      font-style: italic
      cursor: pointer
      &:after
        content: ''
        width: 100%
        margin: 0 $indent-small
        height: 1px
        background-color: lighten($el-grey, 5%)

    .m-menu-item__icon-wrapper
      display: flex
      justify-content: center
      align-items: center
      height: 20px
      width: 20px

    .m-menu-item__label
      margin-left: $indent-small

  &.m-menu-collapsed
    .m-menu-item-category,
    .m-menu-item.vertical
      height: unset
      padding-left: 0 !important
      font-style: normal

      &.is-disabled
        &:after
          content: unset
        &:hover
          color: $primary-color

      .m-menu-item__label
        padding: $indent-small*2 0 !important

        writing-mode: vertical-rl
        margin-left: 0
        width: $menu-item-width
        display: flex
        align-items: center

    .m-menu-item.vertical
      align-items: center
      justify-content: center
      flex-direction: column
      padding: $indent-small 0 !important
      .m-menu-item__label
        padding: $indent-small !important
