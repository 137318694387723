@import "styles/checkup";
@import "styles/doctorsAndTests";
@import "styles/checkupsDocuments";
@import "styles/companyCheckups";
@import "styles/personalCheckups";
@import "styles/clientSearchCreateFormShort";
@import "styles/document";
@import "styles/fastCreateModal";
@import "styles/personalCheckupVaccination";
@import "styles/personalCheckupEntries";
@import "styles/personalCheckupVaccinationsAndDiseases";
@import "styles/personalCheckupInfectiousDiseases";
@import "styles/personalCheckupDoctorResults";
@import "styles/personalCheckupLaboratoryResearchResults";
@import "styles/personalCheckupLaboratoryAndInstrumentalResearch";
@import "styles/personalCheckupInstrumentalResearchResults";
@import "styles/documentViews";
@import "styles/personalCheckupDiseasesPersonal";
@import "styles/personalCheckupFiles";

.checkups-module {
  display: flex;
  max-height: calc(100vh - 60px);
  max-width: 100vw;
  width: 100vw;
  height: 100%;
  gap: $indent-small;

  @media print {
    height: unset !important;
  }

  @include mFieldsFW;

  .checkups-module__content {
    $checkup-filters-width: 250px;

    height: 100%;

    &.periodic-checkups,
    &.prior-checkups {
      .m-panel__body {
        flex-direction: row;
        gap: $indent;
      }
    }

    .m-panel__body {
      .m-si-generator {
        height: 100%;
      }

      .checkup-filters {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: $checkup-filters-width;
        border-right: 1px solid $light-grey;
        padding-right: $indent;

        .date-picker__title {
          margin-bottom: $indent;
          font-size: 14px;
          color: lighten($primary-font-color, 5%);
        }

        .checkup-filters__buttons {
          display: flex;
          gap: $indent-small;
          justify-content: flex-end;
        }
      }

      .checkups-list-wrapper {
        display: flex;
        flex-grow: 1;
        overflow-x: hidden;

        .checkups-list {
          overflow-x: hidden;
        }
      }
    }
  }

  .checkups-module__aside {
    display: flex;
    flex-basis: $panel-title-height;
    max-width: 280px;

    .checkups-module__left-panel-menu {
      height: 100%;

      .m-panel__body {
        padding: 0;
        height: 100%;

        .checkups-module__menu {
          height: 100%;

          .m-menu {
            height: 100%;
          }
        }
      }
    }
  }
}

