
.m-pagination {
  @media print {
    display: none;
  }

  padding: 0;

  .number {
    font-weight: normal;
  }

  .number.active {
    background-color: $primary-background-color;
    border-color: $primary-border-color;
    color: $primary-color;
    font-weight: bold;
  }
}
