$green-active: #71a436
$turquoise-active: #34a0bc
$border-active: #607D8B

$grey-tab-default: #e5e5e5
$grey-tab-active: #fff
$grey-text: #6c6c6c

.v-tabs.green
  .el-tabs
    &.el-tabs--card
      .el-tabs__header
        .el-tabs__nav-wrap
          background: $green
          .el-tabs__nav-scroll
            .el-tabs__nav
              border: none
              .el-tabs__item
                height: initial
                line-height: initial
                padding: 7px 15px
                font-size: 13px
                border-left: none
                color: white
                &:last-child
                  padding-right: 15px
                &:hover
                  background: $green-active
                &.is-active
                  background: $green-active
                  border-bottom: 3px solid $border-active

.v-tabs.turquoise
  .el-tabs
    &.el-tabs--card
      .el-tabs__header
        .el-tabs__nav-wrap
          background: $turquoise
          .el-tabs__nav-scroll
            .el-tabs__nav
              border: none
              .el-tabs__item
                height: initial
                line-height: initial
                padding: 7px 15px
                font-size: 13px
                border-left: none
                color: white
                &:last-child
                  padding-right: 15px
                &:hover
                  background: $turquoise-active
                &.is-active
                  background: $turquoise-active
                  border-bottom: 3px solid $border-active

.v-tabs.grey
  .el-tabs__header
    border-bottom: 1px solid rgb(221, 221, 221)
  .el-tabs__nav
    border: none
    border-radius: 0
  .el-tabs__item
    padding: 5px 10px
    font-size: 13px
    color: $grey-text
    background-color: $grey-tab-default
    height: initial
    line-height: 1.42857143
    margin-right: 2px
    border: 1px solid transparent
    &.is-active
      border-radius: 2px 2px 0 0
      background-color: $grey-tab-active
      border: 1px solid rgb(204, 204, 204)
      border-bottom-color: transparent
