.egisz-case__card.egisz-case__card_med-documents
  .med-documents
    .med-documents__controls
      display: flex
      align-items: center

    .med-documents__table
      .col__doctor
        max-width: 260px

      .col__created-at
        max-width: 140px

      .col__options
        color: $red
        max-width: 40px
        i
          font-size: 17px
          cursor: pointer
