
.communication-messages {
  .communication-messages__filters {
    .filter__date-picker {
      .m-date-picker {
        max-width: 270px;
      }
    }
  }

  .col__deliveryTypeIcon,
  .col__statusIcon {
    flex-grow: 0;
    flex-basis: 50px;
  }

  .col__number {
    flex-grow: 0;
    flex-basis: 100px;
  }

  .col__dateTime {
    flex-grow: 0;
    flex-basis: 180px;
  }


  .col__phone {
    flex-grow: 0;
    flex-basis: 200px;
  }

  .col__clients {
    flex-grow: 0;
    flex-basis: 200px;
  }

  .col__messageType,
  .col__errorText {
    flex-grow: 0;
    flex-basis: 200px;
  }
}
