.orders-quick-addition {
  position: absolute;
  right: 7px;
  height: 100%;
  top: 0;
  max-width: 300px;
  border: 1px solid $success;
  border-top: none;
  box-shadow: 0 1px 6px rgba(139, 195, 74, 0.12), 0 1px 6px rgba(139, 195, 74, 0.12);

  .m-panel__body {
    padding: 0;
    overflow-x: hidden;
    box-shadow: none;
  }

  .orders-quick-addition__expander {
    writing-mode: vertical-lr;
    text-align: center;
    font-size: 14px;
    color: $primary-font-color;
    line-height: 26px;
    width: 100%;
    height: 100%;
  }

  &.m-panel-collapsed {
    filter: brightness(100%);

    &:hover {
      filter: brightness(95%);
      transition: all 0.5s ease;
    }
  }
}
