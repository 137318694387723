.tax-certificate-2024-editor-personal-information {
  $min-width: 314px;

  &__person {
    display: flex;
    flex-wrap: wrap;
    gap: $indent;

    .m-input, .m-date-picker {
      min-width: $min-width
    }
  }

  &__document {
    display: flex;
    flex-wrap: wrap;
    gap: $indent;

    .m-select {
      min-width: $min-width
    }
  }
}
