.personal-elmk-medical-assessment-view {
  .document-field-writable.document-field-writable_disabled {
    .document-field-writable__textarea textarea,
    .document-field-writable__input input {
      font-weight: bold;
    }
  }

  &__footer {
    display: flex;
    gap: 70px;
    margin-bottom: $indent-validation;
  }

  &__place-of-printing {
    margin-top: 19px;
  }
}
