.companies_page
  .catalog_slim_panel
    .panel-heading
      padding: 5px
      .slim-panel-heading-icon
        font-size: 18px
  .company-nav-container
    width: calc(100% - 30px)
    display: flex
    .alert-modal .modal-body span
      white-space: pre-line
    .company-nav-panel
      width: 250px
      margin-right: 5px
      overflow: hidden
      .panel-heading-buttons span
        font-size: $size-arrow-icon
        margin-top: -2px
      .panel-body
        .tabs-nav
          display: flex
          flex-direction: column
          .tab-nav
            display: flex
            align-items: center
            cursor: pointer
            &.is-disabled
              cursor: not-allowed
              color: $grey
            &.is-active
              background: $primary-background-color-dark
              color: $primary-color
              &:hover
                background: $primary-background-color
              .tab-ico
                .fad
                  color: $primary-color
                  &.fa-user
                    margin-left: 3px
              .tab-text
                color: $primary-color
            &:hover
              background-color: $primary-background-color
            .tab-ico
              padding: 5px 10px 5px 10px
            .tab-text
              padding: 5px 0px 5px 0px
