.v-modal
  display: none

.el-message-box__wrapper,
.el-dialog__wrapper
  background-color: $modal-background
  height: calc(100vh + 16px)

.m-modal
  border-radius: $border-radius-base
  overflow: unset

  &_height-100
    &>.el-dialog
      height: 100%

  &.el-dialog__wrapper
    height: calc(100vh + $indent)
    background-color: $modal-background

  .el-dialog
    border-radius: $border-radius-base + 1
    display: flex
    flex-direction: column

    max-height: 90vh
    margin-top: 5vh !important

    &.is-fullscreen
      max-height: 100vh
      margin-top: 0 !important

  .el-dialog__header
    align-items: center
    background-color: $primary-color
    border-top-right-radius: $border-radius-base
    border-top-left-radius: $border-radius-base
    color: $white
    display: flex
    font-size: $header-font-size
    height: $panel-title-height
    min-height: $panel-title-height
    padding: 0 $indent-mid 0 $indent-small

    & > span
      width: 100%

  .m-modal__header
    align-items: center

    & > span
      display: flex
      align-items: center

    & > span, & > div
      flex-grow: 1
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

      & > i
        font-size: $icon-font-size-mini

  .m-modal__close
    font-size: $icon-font-size

    &:hover
      opacity: 0.8

  .m-modal__fullscreen
    font-size: 16px
    // нюансы отрисовки иконки
    margin-top: 1px

    &:hover
      opacity: 0.8

  .el-dialog__body
    display: flex
    flex-grow: 1
    font-size: $primary-font-size
    overflow-y: auto
    padding: $indent

    & > span
      word-break: normal

  .el-dialog__footer
    display: flex
    justify-content: space-between
    padding: 0 $indent $indent

    .footer__left
      .pagination-vue
        margin-top: 0
        margin-bottom: 0

  &.hide-footer
    > .el-dialog
      > .el-dialog__footer
        display: none

  &.remove-body-top-padding
    .el-dialog__body
      padding-top: 0

  &_success
    > .el-dialog > .el-dialog__header
      background-color: $success !important

  &_warning
    > .el-dialog > .el-dialog__header
      background-color: lighten($orange, 5%)
