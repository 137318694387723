.date-range-picker-btn
  .vue-items-list
    .vue-list
      margin: 0

      .date-range-btn
        text-transform: uppercase
        padding: 7px 10px 7px 10px
        margin: 0 2px 0 0
        font-size: 12px
        border-radius: $border-radius-base
        height: 30px
        line-height: normal
        min-width: 80px

      .date-range-btn:last-child
        margin: 0
