.tax-certificate-2024-printable-page-one {
  &__header {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: $header-font-size;
  }

  &__taxpayer-is-client-field {
    display: flex;
    flex-direction: column;
    line-height: normal;
    font-size: 11px;
  }

  &__separated-block {
    $border: 1px solid $primary-font-color;
    display: flex;
    border-top: $border;

    &-employee {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      padding: $indent-small $indent-small 80px;
      border-right: $border;
    }

    margin-bottom: $indent;
  }
}
