#head-desktop-component
  .head-desktop__card-selector
    &.el-select
      width: 100%
      margin-bottom: 15px

  .cards-container
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(440px, 1fr))
    grid-template-rows: 1fr 1fr
    grid-gap: 1vw
    @media (max-width: 400px)
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)) !important

    .head-desktop__el-card
      &.el-card
        height: 400px

        .el-card__header
          cursor: move
          padding: 10px 15px

          .clearfix
            flex-wrap: nowrap
            display: flex

            // 7jt1ev (10)
            .card-title
              display: flex
              align-items: baseline

            .el-card-title__icon
              font-size: 18px
              color: $primary-color
              margin-right: 5px
              padding: 2px 0
              line-height: 1
              border-radius: 2px

            .el-card-title__title
              display: flex
              justify-content: flex-start
              white-space: nowrap
              flex-grow: 1
              overflow: hidden
              font-size: 18px
              line-height: 1.2

            .el-card-title__actions
              display: flex
              justify-content: flex-end

              .el-card-title__btn
                color: $primary-color
                font-size: 18px
                padding: 0

                &.fa-sync-alt
                  font-size: 14px
              // fix icon size

              .rotate_btn
                -webkit-animation: rotation 1s linear

              @-webkit-keyframes rotation
                from
                  -webkit-transform: rotate(0deg)
                to
                  -webkit-transform: rotate(359deg)

        .el-card__body
          height: 100%
          padding: 10px 10px

          .highcharts-credits
            display: none

          .el-card__body__header
            height: 50px
            display: flex

            .el-card__body__header__child
              flex-grow: 1
              padding: 5px 10px

              .el-card__body__header__indicator
                font-size: 18px
                text-align: center
                white-space: nowrap

              .el-card__body__header__explanation
                color: #ababab
                text-align: center
                white-space: nowrap

          .el-card__body__content
            height: 240px
            overflow-y: auto

            &::-webkit-scrollbar
              width: 5px
              height: 5px
              background-color: #eee

            &::-webkit-scrollbar-thumb
              background-color: #ccc

          .el-card__body__footer
            display: flex
            flex-wrap: nowrap
            padding: 10px 5px

            .el-card__body__footer__filters
              flex-grow: 1
              align-self: center
              display: flex

            .el-card__body__footer__actions
              flex-grow: 0
              align-self: center

          // 7jt1ev (10)
          .introduction-button-wrapper
            align-items: center
            display: flex
            height: 100%
            justify-content: center
            position: absolute
            top: 0
            width: 100%
            z-index: 2

            .introduction-button
              margin: 0
              box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5) !important

              .btn-with-icon_icon
                margin-top: 2px
