.lazy-tree {
  display: flex;
  overflow-x: hidden;

  .el-tree {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    width: 100%;
  }

  .el-tree-node {
    display: flex;
    flex-direction: column;

    .el-tree-node__content {
      flex-grow: 1;
    }

  }
  
  .entry-types-tree-node {
    display: flex;
    gap: $indent-mid;
    padding-right: $indent;
    flex-grow: 1;

    .entry-types-tree-node__icon {
      font-size: $icon-font-size;
    }

    .entry-types-tree-node__title {
      flex-grow: 1;
    }

    .entry-types-tree-node__price {
      display: flex;
      padding-left: $indent;
      min-width: 120px;
    }
  }
}
