$menu-bg: #fff
$menu-border: #e4e7ed
$item-color: #212529
$item-hover-bg: #f8f9fa
$item-hover-color: #212529

.v-context
  &, & ul
    background-color: $menu-bg
    background-clip: padding-box
    border-radius: 4px
    border: 1px solid $menu-border
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
    display: block
    margin: 0
    padding: 10px
    min-width: 10rem
    z-index: 1500
    position: fixed
    list-style: none
    box-sizing: border-box
    max-height: calc(100% - 50px)
    overflow-y: auto

  &:focus
    outline: none
