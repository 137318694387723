@import "taxCertificate2024Editor";
@import "taxCertificate2024Printable";

.tax-certificate-2024-app {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  .fixed-document-printable-container {
    display: flex;
    overflow-y: scroll;
    flex-direction: column;

    @media print {
      display: block;
      overflow-y: unset;
    }
  }

  .fixed-document-print-footer {
    box-shadow: $box-shadow;
    padding: $indent;

    @media print {
      box-shadow: none;
    }
  }

  @media print {
    display: block;
    overflow-y: unset;
  }
}
