#nsi.panel-body {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.nsi-configuration {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  gap: $indent;
  flex-grow: 1;
  padding: 2px;


  .nsi-configuration__form {
    min-height: 340px;
    flex-grow: 0 !important;
    flex-shrink: 1;

    .nsi-configuration__title {
      font-size: 13px;
      font-weight: bold;
    }

    .nsi-info {
      margin-left: 22px;
    }
  }

  .nsi-configuration__dictionaries {
    flex-grow: 1;
    overflow-y: hidden;

    .dictionaries__loading-bar {
      position: absolute;
      bottom: -2px;
      background-color: white;
      right: 0;
    }
  }
}

