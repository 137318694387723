.diseases-modal {
  .diseases-app {
    //.el-popover {
    //  position: fixed;
    //}
  }

  .el-dialog__body {
    flex-direction: column;
    padding-bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll;

    .el-tree-node__content {
      padding-right: $indent;
      height: unset;

      .node-content {
        align-items: flex-start;
      }
    }
  }

  .el-dialog__footer {
    padding-top: $indent;
  }
}
