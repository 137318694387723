.entry-option
  align-items: center
  cursor: pointer
  display: flex
  height: auto
  min-height: 56px
  max-width: 30vw !important
  min-width: 25vw !important
  width: 100%

  .entry-option__number
    align-self: stretch
    align-items: center
    background-color: lighten($el-grey, 7.5%)
    color: $primary-font-color !important
    display: flex
    min-width: 100px
    max-width: 100px
    margin-right: 8px
    overflow: hidden
    text-overflow: ellipsis
    padding: 4px

  .entry-option__analysis-laboratory-title
    font-style: italic
    margin-right: $indent-mid
    color: #a3a3a3

  .entry-option__title
    flex-grow: 1
    line-height: normal
    word-break: break-word
    white-space: normal

  .entry-option__price
    font-weight: bold
    min-width: 70px
    text-align: right
