@import "components/Documents";
@import "components/Feedbacks";
@import "components";
@import "components/MedicalPolices";

.clients_index_page {
  .catalog_slim_panel {
    border-top-right-radius: $border-radius-base;
    border-top-left-radius: $border-radius-base;

    .panel-heading {
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
      min-height: $panel-title-height;
      max-height: $panel-title-height;
    }
  }
}

/* 🩼🩼🩼 для панели меню клиента */
.clients_notifications_page {
  #client_card_list {
    .panel-heading {
      height: $panel-title-height;
      min-height: $panel-title-height;
      border-top-right-radius: $border-radius-base;
      border-top-left-radius: $border-radius-base;

      .panel-title.with-icon {
        font-size: $header-font-size;
      }
    }
  }
}

.company-wrapper-wrapper {
  .company-wrapper {
    overflow: unset;
  }

  @media screen and (max-height: 768px) {
    .el-dialog__footer {
      padding-top: $indent;
    }
  }
}
