.m-buttons-group
  display: flex
  gap: $indent-small
  &.m-buttons-group_legacy
    .m-button-wrapper
      margin-bottom: $indent-small

      &.hide-button-mb
        margin-bottom: 0

      &:not(.m-buttons-group_legacy__button_active)
        .m-button
          &:active, &:hover, &:focus
            background-color: $white
            border-color: $el-grey
            color: $primary-font-color

            &.el-button--primary
              background-color: #ecf7fa
              border-color: #b2dfea
              color: $primary-color

            &.el-button--success
              background-color: #f3f9ed
              border-color: #d1e7b7
              color: $success

            &.el-button--warning
              background-color: #fff5e6
              border-color: #ffd699
              color: $orange-bright

            &.el-button--danger
              background-color: #ffeee9
              border-color: #ffd699
              color: $red

            &.el-button--info
              background-color: #edeef0
              border-color: #b5bac1
              color: $grey-dark

          &:focus
            color: $primary-color

      &.m-buttons-group_legacy__button_active
        .m-button
          background-color: $white
          border-color: $primary-color
          color: $primary-color

          &.el-button--primary
            background-color: $primary-color
            color: $white

          &.el-button--success
            background-color: $success
            border-color: $success
            color: $white

          &.el-button--warning
            background-color: $orange-bright
            border-color: $orange-bright
            color: $white

          &.el-button--danger
            background-color: $red
            border-color: $red
            color: $white

          &.el-button--info
            background-color: $grey-dark
            border-color: $grey-dark
            color: $white

  .m-buttons-group__vertical
    display: flex
    flex-direction: column

    .el-radio-button
      &:first-child
        border-left: none

      border-left: 1px solid $el-grey
      border-radius: 4px
      align-self: flex-start

      .el-radio-button__inner
        border-radius: inherit

