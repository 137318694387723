@import './variables'

.appointment-type-preview
  position: relative
  cursor: pointer
  transform: translateX(2px)
  width: calc(100% - 4px)
  height: #{$cell-height - 3px}
  display: flex
  border: 1px solid white
  padding: 2px 0 0 2px
  overflow: hidden
  border-radius: 4px
  color: white

  background-color: $primary-color
  transition: transform .1s ease-in-out
  font-size: 11px
  line-height: 1.2
  vertical-align: middle
  &:hover
    transform: translateX(4px)

  .status-icon
    margin-right: 3px

  .new-client
    position: absolute
    bottom: 2px
    right: 5px
