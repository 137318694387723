.alert.alert-info.call_notification
  border: 1px dashed $green
  .fad
    font-size: medium
    vertical-align: middle
    &.fa-phone-alt
      color: $green
    &.fa-phone-rotary
      color: $red
    &.fa-phone-plus
      color: $green
    &.fa-headset
      color: $orange


.call-status-icon
  margin-right: 5px
  &.fa-phone-plus
    color: $green
  &.fa-phone-slash
    color: $red

.call-type-icon
  margin-right: 5px
  &.fa-phone-alt
    color: $green
  &.fa-headset
    color: $orange

.phone_number
  &.callable
    color: $link-color
    &:hover
      text-decoration: underline
      cursor: pointer
      color: $link-color-hover
