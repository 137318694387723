.image-cropper-container
  .modal
    .modal-image-cropper
      margin: 25px auto 25px auto
      height: calc(100% - 50px)
      flex-direction: column
      .modal-content
        .modal-body
          .vue-image-cropper
            display: flex
            .cropped-image
              width: 50%
              height: 50%
            .preview-box
              width: 50%
              display: flex
              justify-content: center
              align-items: center
              background: #eeeeee
              .preview
                border-radius: 100%