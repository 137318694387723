#uis_control_panel, #mango_control_panel, #itoolabs_control_panel
  .show_info_table
    .field-with-btn
      display: flex

      .field-with-btn_field
        margin-right: 10px
        flex-grow: 0.05
        min-width: 210px

        .el-input
          width: 210px
          padding-top: 6px

          .el-input__inner
            height: 30px
            border: 1px solid $grey
            border-radius: 2px
            font-size: 13px

      .field-with-btn_btn
        .el-button
          margin-top: 6px
          border-radius: 2px

    .el-select
      width: 210px

      .el-input__inner
        height: 30px
        border: 1px solid $grey
        border-radius: 2px
        font-size: 13px

      .el-input__suffix
        .el-input__icon
          line-height: 0px

    .el-input__inner:focus
      padding-left: 15px

  .control
    .el-button--success
      background-color: $green
