.catalog-tree {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: $indent;

  .el-popover {
    position: fixed;
  }
}
