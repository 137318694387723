$max-support-nav-width: 300px

.support-container
  display: flex
  justify-content: center
  width: 100vw

  // фикс вылезания элементов iframe за границы panel-body
  .panel-body
    max-height: 96%
    @media screen and (max-width: 1400px)
      max-height: 95%

  .support-nav
    min-width: $max-support-nav-width
    margin-right: 5px
    max-width: $max-support-nav-width

    .panel-body
      padding: 3px

    .tabs-nav
      display: flex
      flex-direction: column
      .tab-nav
        align-items: center
        cursor: pointer
        display: flex
        font-size: 13px
        padding: 5px 10px

        .tab-icon
          margin-right: 5px

        &.is-root
          font-weight: normal

        &.is-child
          margin-left: 32px

        &.is-active
          background: $primary-background-color-dark
          color: $primary-color
          &:hover
            background: $primary-background-color
          .tab-text,
          .tab-icon
            color: $primary-color
        &:hover
          background-color: $primary-background-color

  .support-body
    flex-grow: 1
