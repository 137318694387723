.appointment-types-table
  .appointment-types-table__header
    justify-content: space-between

  .appointment-types-table__color-title
    flex-grow: 0

  .appointment-types-table__default-icon
    display: flex
    align-items: center
    width: 13px
    height: 13px
    margin-right: 10px

    .fa-check-circle
      color: $primary-color

  .catalogs_table__item
    .appointment-types-table__color-title
      padding-left: 18px

    .appointment-types-table__color
      border-radius: 50%
      max-width: 13px
      height: 13px
      border: 1px solid $grey
