.diagnoses-selector
  $button-width: 47px

  &__input
    width: calc(100% - #{$button-width})

  // modifieers
  &_with-import
    .diagnoses-selector__input
      width: calc(100% - 2 * #{$button-width})

  &_as-row
    @mixin selector-button
      margin-top: -7px

    .diagnoses-selector__select
      @include selector-button

    .diagnoses-selector__import
      @include selector-button

