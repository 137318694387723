.semd-entity-nsi-measurement {
  display: flex;
  gap: $indent-small;

  &__amount {
    width: 200px;
  }

  &__units {
    max-width: 120px;

    .el-select {
      max-width: 120px;
    }
  }
}
