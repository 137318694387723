.document-square-field-labeled {
  display: flex;
  height: min-content;

  &__label, &__field {
    display: flex;
    flex-direction: column;
    gap: $indent-small;
  }

  &__label, &__post-label {
    line-height: 25px;
  }
}
