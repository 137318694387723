@import "DocumentFieldWritableDate";

.document-field-writable__input {
  display: inline-block;
}

.document-field-writable__textarea textarea,
.document-field-writable__input input {
  border: none !important;
  font-size: 12px;
  line-height: 20px !important;
  padding: 0 !important;
  background: url("@/vue_present/DocumentBase/DocumentFieldWritable/dash.png"), $primary-background-color !important;
}

.document-field-writable__input input {
  min-height: unset !important;
  height: 20px !important;
}

.document-field-writable__input .m-input {
  min-width: 30px;
}

@media print {
  .document-field-writable {
    -webkit-print-color-adjust: exact;

    & textarea {
      overflow: hidden;
      resize: none;
    }

  }

  .document-field-writable__textarea textarea,
  .document-field-writable__input input {
    background: url("@/vue_present/DocumentBase/DocumentFieldWritable/dash.png"), $white !important;
  }
}

@media screen {
  .document-field-writable.document-field-writable_disabled {
    .document-field-writable__textarea textarea {
      color: $primary-font-color !important;
    }
  }
}


.document-field-writable.document-field-writable_disabled {
  .document-field-writable__textarea textarea,
  .document-field-writable__input input {
    background: url("@/vue_present/DocumentBase/DocumentFieldWritable/dash.png"), $white !important;
  }

  .document-field-writable__textarea textarea {
    resize: none;
  }
}

