.mce-container {
  .mce-tab {
    height: 35px;
  }

  .mce-tab:hover {
    background: $white;
  }

  .mce-tab.mce-active {
    height: 36px;
  }
}

.mce-tinymce,
.mce-container,
.mce-btn {
  background-color: $form-label-bg-color
}

.mce-floatpanel {
  .mce-container {
    background-color: white;
  }

  .mce-primary {
    background-color: $primary-color;
  }
}

.mce-protocol-entities,
.mce-variable-btn,
.mce-primary-btn {
  button {
    height: $element-height;
    width: $element-height;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    .fa-user-md {
      margin-left: $indent-small;
    }

    .fa-user-edit {
      margin-left: 3px;
    }

    .fa-brackets-curly {
      margin-left: -1px;
    }

    .fa-code {
      margin-left: -4px;
    }
  }
}
