.date-range-picker
  .el-range-editor
    height: inherit
    width: 230px
    padding: 0 5px
    display: flex
    align-items: center
    justify-content: space-evenly
    .el-range__icon
      line-height: normal
      float: none
    .el-range-input
      font-size: inherit
      width: 80px
    .el-range-separator
      display: flex
      align-items: center
      justify-content: center
