.clinical-guideline {
  max-width: 700px;

  &-collapse-item__container {
    padding: $indent-small;
    border: 1px solid $grey-light-border;
    border-radius: $indent-small;
  }

  &-collapse-item-labeled-value {
    &__label {
      display: inline-block;
    }

    &__value {
      word-break: break-word;
    }
  }
}
