.similar-clients-list
  .similar-clients-list-fullname
    text-overflow: ellipsis
    overflow: hidden
    flex: 1 0 auto
  .similar-clients-list-phone
    flex: 0 0 160px
  .similar-clients-list-birthdate
    flex: 0 0 90px
  .similar-clients-list-entry
    display: flex
    cursor: pointer
    @media (max-width: 1500px)
      flex-direction: column
      .similar-clients-list-phone,
      .similar-clients-list-birthdate,
      .similar-clients-list-fullname
        flex-basis: initial
        padding: 3px 0
  .similar-clients-list-column
    @media (max-width: 1500px)
      max-width: initial
