.checkup__left-side {
  .company-checkup-info {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 0 !important;

    .m-panel__body {
      display: flex;
      flex-direction: row;
      gap: $indent;
    }

    .company-checkup-info__left-side,
    .company-checkup-info__right-side {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    &__link-container {
      position: absolute;
      top: 30px;
      padding-left: $indent-mid;
    }
  }

  .checkup-documents {
    flex-grow: 0 !important;
  }
}

.checkup__right-side {
  .company-checkups-personal-checkups {
    flex-grow: 0 !important;
  }
}
