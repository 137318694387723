$primary-color: #3FAECA
$primary-border-color: #B2DFEA
$primary-background-color: #ECF7FA
$primary-background-color-dark: darken($primary-background-color, 3%)

$success-color: #8bc34a
$success-border-color: #d1e7b7
$success-background-color: #f3f9ed

$warning-color: #ff9800
$warning-border-color: #ff9800
$warning-background-color: #fff5e6

$danger-color: #ff5722
$danger-border-color: #ffbca7
$danger-background-color: #ffeee9

$dark-color: #475264
$dark-border-color: #b5bac1
$dark-background-color: #edeef0

// ***************** старые цвета *****************
$primary-color-hover: darken(#3FAECA, 5%)
$primary-font-color: #6c6c6c
$primary-background: #EEEEEE
$label-hover: #009688
$link-color: #34a0bc
$link-color-hover: darken(#34a0bc, 5%)
$grey-dark-background-color: #edeef0
$grey-dark: #475264
$grey-dark-hover: darken(#475264, 10%)
$grey-dark-lighten: lighten($grey-dark, 20%)
$grey-dark-active: #323a46
$grey-default-button: #efefef
$grey-default-button-hover: #e2e2e2
$light-grey: #EAEAEA
$grey-light: #adadad
$grey: #ccc
$grey-hover: darken(#ccc, 10%)
$form-label-bg-color: #f7f7f7
$el-grey: #dcdfe6
$el-grey-hover: darken(#dcdfe6, 10%)
$grey-light-border: #ddd
$grey-light-bg: #e5e5e5
$grey-blue: #607D8B
$brown: #795548
$green: #8BC34A
$green-hover: darken(#8BC34A, 5%)
$green-active: #71a436
$green-dark-border: #607D8B
$red-light: #ff5722
$red-lighten: #ffeee9
$red: $red-light
$red-background-underline: #ef5350
$red-cross-remove: #ff6868
$orange: #e8a84b
$orange-hover: darken(#e8a84b, 10%)
$orange-active: #E49346
$orange-bright: #FF9800
$orange-dark-border: #B56F28
$crimson: #EC407A
$crimson-hover: darken(#EC407A, 10%)
$turquoise: #3FAECA
$turquoise-hover: #389cb4
$blue: #4aabff
$purple: #9c27b0
$lilac: #c8a2c8
$disabled: #E0E0E0
$error: #F44336
$danger: $error
$warning: #e8a84b
$warning-hover: darken(#e8a84b, 10%)
$success: #8BC34A
$success-hover: darken(#8BC34A, 10%)
$white: #fff
$canceled: #daa4b2
$table-header-color: #e6eeee

// selects
$select-line-height: 34px
$select-option-hover: #f9fafd
$select-border-color: #EBEEF5
$select-text: #606266
$select-bg: #ffffff

// appointment statuses
$appointment-status-need-approval: $crimson
$appointment-status-approved: $label-hover
$appointment-status-canceled: $grey-light
$appointment-status-billed: #7f9aa7
$appointment-status-paid: $green
$appointment-status-serviced: #b34dc5
$appointment-status-failed: lightcoral
$appointment-status-arrived: $orange
$appointment-status-confirmed-by-administrator: $green

// call statuses
$appointment-call-status-pending: $primary-color
$appointment-call-status-confirmed: $green
$appointment-call-status-client-canceled: $canceled
$appointment-call-status-failed-to-connect: $grey-light

// tables
$table-striped-odd: #f9f9f9
$table-striped-hover: $primary-background-color

$modal-background: rgba(0, 0, 0, 0.4)
