@import "TaxCertificate2024/styles";

.fixed_document_page {
  .fixed-document__body {
    overflow-y: hidden;
    display: flex;
    background-image: url('assets/hip-square.png');
    @media print {
      background-image: unset;
    }

    @media print {
      overflow-y: unset;
      display: block;
    }
  }
}

