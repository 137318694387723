.m-image-picker {
  $width: 400px;
  $height: 200px;

  position: relative;

  min-width: $width;
  width: $width;
  min-height: $height;
  height: $height;

  &--rounded {
    $size: 150px;
    min-width: $size;
    width: $size;
    min-height: $size;
    height: $size;

    border-radius: 100%;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__controls {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    opacity: 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
      opacity: 1;
    }
  }

  &__input {
    display: none;
  }
}
