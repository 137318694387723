#analysis_result
  .table_block
    padding-bottom: 10px

    .table_header.result_title
      padding: 10px
      font-weight: bold
      font-size: 14px

    .result_table
      tbody
        tr
          .value
            font-weight: bold
            padding-left: 5px

            div
              padding-left: 5px

            .failed_test
              background-color: $red-background-underline
              color: white
              width: 50%

        tr
          td
            padding: 3px 3px 3px 10px

