@import './mixins/appointments_reservations'

.doctor-schedule-grid
  height: 100%
  overflow-x: hidden
  overflow-y: scroll
  user-select: none
  position: relative
  padding-right: 5px
  &::-webkit-scrollbar
    width: 5px

  .time-line
    position: absolute
    width: 100%
    height: 1px
    right: 5px
    background-color: $orange
    z-index: 1

  $border: 1px solid $grey

  table
    display: flex
    flex-direction: column
    width: 100%
    thead
      width: 100%
      position: sticky
      top: 0
      z-index: 10

    tbody
      tr:last-child
        td
          border-bottom: $border
        td:first-child
          height: 27px

    thead, tbody
      display: block

      tr
        display: flex
        width: 100%

        td, th
          display: flex
          flex-direction: column
          height: 25px

        td
          font-size: 11px
          border-right: $border
          border-top: $border

        td + td, th + th
          width: 100%

        td:first-child
          border-left: $border

        th:first-child, td:first-child
          max-width: 45px
          min-width: 45px

        th
          text-align: center
          justify-content: center
          height: auto
          overflow: hidden
          .schedule-grid-header
            z-index: 6
            background: white
            //transition-duration: 0.3s
            border: 1px solid white
            padding: 2px
            font-weight: normal
            max-height: 40px
            overflow: hidden
            font-size: 13px
            height: 100%
            @media print
              .moment-date
                display: block !important
              .formatted-date
                display: none
          .schedule-grid-header-user:hover
            cursor: pointer
            background: #f8f8f8
            border: 1px solid #f8f8f8
            //transition-duration: 0.3s
          .schedule-grid-header-icons
            display: flex
            justify-content: center
            align-items: center
            .schedule-grid-header-icon
              padding: 1px
            .schedule-grid-header-icon__phone
              cursor: pointer
              margin-right: 0 !important // перебивка стилей
              padding: 1px
            a, .schedule-grid-header-icon
              color: $primary-font-color
              margin-right: 5px
              font-size: 15px
              padding: 0 2px
              border: 1px solid #ccc
              border-radius: 4px
              line-height: 20px
              &:hover
                color: $red
            .waiting-list-count
              cursor: pointer
              padding: 0 2px
              border: 1px solid $grey
              border-radius: 4px
              line-height: 15px
              font-size: 11px
              .count
                background: #adadad
                color: white
                padding: 1px 2px
                border-radius: 2px
              .waiting-list-count-icon
                font-size: 14px
                margin-right: 2px
                line-height: 21px

              &:hover
                .waiting-list-count-icon
                  color: $red


        th.schedule-grid-date
          .schedule-grid-header
            font-weight: bold
            background: #ADADAD
            color: white

        td.schedule-grid-time
            position: relative
            height: 26px
            .time-container
              position: absolute
              z-index: 2
              background-color: white
              border-radius: 1px
              top: 50%
              left: 47%
              transform: translate(-50%, -50%)

        td.schedule-grid-available, .schedule-grid-cell-time, .schedule-grid-cell-paste
          cursor: pointer

        td.schedule-grid-available:hover
          background: rgb(248, 248, 248)

        td.schedule-grid-with-divider
          border-right: 2px solid #ADADAD

        td:last-child.schedule-grid-with-divider
          border-right: $border

        td.schedule-grid-available, td.schedule-grid-unavailable
          box-sizing: content-box
          padding: 0
          position: relative

          .schedule-grid-cell-time
            position: absolute
            right: 0
            top: 0
            background-color: #f8f8f8
            border-radius: 5px
            //transition: visibility 0.2s, opacity 0.2s linear
            visibility: hidden
            opacity: 0
            z-index: 5
            padding: 1px

          .schedule-grid-cell-paste
            position: absolute
            right: 0
            z-index: 5
            display: none
            bottom: -5px
            font-weight: bold
            color: #F44336
            font-size: 17px
            margin-top: 1px
            top: 6px

          &:hover .schedule-grid-cell-time, &.schedule-grid-container-drag-hover .schedule-grid-cell-time
            //transition: visibility 0.2s, opacity 0.2s linear
            visibility: visible
            opacity: 1

          &:hover .schedule-grid-doctor-name:first-child
            //transition: padding-top 0.5s
            padding-top: 15px

          &.schedule-grid-no-celltime:hover .schedule-grid-cell-time
            display: none

          &.schedule-grid-not-empty .schedule-grid-cell-time
            display: none

          +appointments-reservations-mixin

        td.schedule-grid-unavailable
          background: gainsboro

        td.schedule-grid-selected
          background-color: $grey-dark
          border: none
          border-right: 1px solid $grey-dark-hover
          border-top: 1px solid $grey-dark-hover
          cursor: pointer
          position: relative
          &:hover
            background-color: $grey-dark
          .schedule-grid-cell-time
            display: none
          .time-range-container
            top: 0
            height: 100%
            width: 100%
            position: absolute
            z-index: 5000
            color: white
            font-size: 14px
            text-align: center
            line-height: 25px
          .schedule-grid-appointments-container
            background-color: $grey-dark

  table.empty-table
    thead
      display: none
    tbody
      tr
        td.schedule-grid-time
          max-width: 100%
          width: 100%

.doctor-schedule-grid.schedule-grid-mode-paste
  table
    tbody
      tr
        td.schedule-grid-available:hover
          .schedule-grid-cell-paste
            display: inline

