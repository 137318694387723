@import "styles/protocolEditorView";
@import "styles/protocolSaveAsTemplate";
@import "styles/protocolShowView";
@import "SemdProtocolEditor";
@import "styles/protocolsForPrint";
@import "styles/addProtocolButton";

.protocol-view {
  .m-panel__body {
    padding: $indent-mid;
  }

  .protocol-view__body {
    display: flex;
    flex-grow: 1;

    @media print {
      display: block;
      width: 100vw;
    }
  }

  .protocol-view__show {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: hidden;
    align-items: center;
    gap: $indent;

    @media screen and (max-width: 1400px) {
      .protocol-show__buttons {
        position: relative !important;
        margin-bottom: 0;
      }
    }

    @media print {
      display: block;
    }

    .protocol-show__footer {
      display: flex;
      align-items: center;
      flex-basis: 46px;
      max-height: 46px;

      gap: $indent-small;
      width: 100%;
      border-top: 1px solid $grey-default-button;
      padding: $indent $indent 0 0;

      @media print {
        display: none;
        padding: 0;
      }
    }
  }

  .protokol-content-wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;

    .protokol-content-wrapper__doctor-signature {
      margin-left: 15mm;
      @media print {
        margin-left: unset;
      }
    }

    .protocol-content-margins {
      min-height: $element-height;
      height: auto;
      border: 1px dashed $grey-dark;
      border-radius: 2px;
      display: table;
      @media print {
        display: block;
        border: none;
        width: unset;
      }
    }

    #protokol-content {
      margin: 0 10mm 0 15mm;
      width: 180mm;

      p {
        margin: 2px 0;
      }

      table {
        max-width: 180mm;

        @media print {
          max-width: unset;
        }
      }

      @media print {
        margin: unset;
        max-width: unset;
      }

      .print_area_start,
      .print_area_end {
        display: none;
      }
    }

    @media print {
      display: block;
      width: calc(100vw - 10px);
    }
  }
}


.based-on-protocol-modal {
  .m-si-generator {
    width: 100%;
  }

  .el-dialog {
    height: 600px;
  }
}

