.medical-records-protocol-selector {
  display: flex;

  $pads: calc((100% - 210mm) / 2);
  padding-left: $pads;
  padding-right: $pads;

  // фикс просачивания стилей
  .m-select {
    .el-input__inner {
      height: 30px !important;
    }
  }


  .medical-records-protocol-selector__pdf-icon {
    margin-right: $indent-small;

    .el-loading-spinner {
      top: 75%;

      .circular {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.entries_show_page {
  .medical-records-protocol-selector {
    padding-left: unset;
    padding-right: calc(100% - 210mm);
  }
}
