.circle-icon
  width: 25px
  height: 25px
  display: block
  text-align: center
  line-height: 25px
  border-radius: 50%
  margin-right: 5px
  color: white
  font-weight: bold

.container-icon-red
  background: #ff0000

.container-icon-blue
  background: dodgerblue
  color: #000

.container-icon-green
  background: #008000

.container-icon-green-gel
  background: #004d00

.container-icon-violet
  background: #800080

.container-icon-violet2
  background: #670067

.container-icon-gray
  background: #808080

.container-icon-yellow-gel
  background: #ffff00
  color: #000

.container-icon-lilac
  background: $lilac

.container-icon-white
  border: #000 1px solid
  color: #000

#control_panel
  .dropdown-grey-dark
    max-width: 300px
    span
      max-width: 250px

#order-info-printable
  .order-info-column
    display: flex
    min-width: 230px
    flex-direction: column

    .title
      display: flex
      flex-basis: 135px
      flex-shrink: 1

#order-number-qr
  display: none
  @media print
    display: flex
    position: absolute
    top: 72px
    right: 0
