#employees-schedule
  padding-bottom: 5px
  .panel-body
    .inner-container
      overflow-y: visible
      display: flex
      flex-direction: column
      width: 100%
      .employees-schedule-grid

        $color-transition: color 100ms linear
        $border: 1px solid #eee
        padding-right: 5px

        table
          width: 100%
          display: block
          tbody
            display: flex
            flex-direction: column

            tr.date-row
              position: sticky
              top: 0
              z-index: 10
              font-weight: bold
              .week-day
                cursor: pointer
              th
                max-height: 50px
                border-bottom: none
                border-top: $border
                &.user-name-cell_th
                  flex-basis: 130px
                  flex-shrink: 0
                &.current-date-cell
                  border-bottom: 5px solid #fff7b3

            tr
              display: flex
              width: 100%
              td, th
                display: flex
                flex-direction: column
                justify-content: center
                background-color: white
                border-top: $border
                border-left: $border
                font-size: 13px
                overflow: hidden
                min-height: 50px
                &:not(.cabinet-cell):not(.current-date-cell):not(:checkbox-cell)
                  border-bottom: 5px solid #fff
                  &:hover
                    border-bottom: 5px solid #f8f8f8
                span
                  .fad
                    color: $grey
                div
                  text-align: center
                  .interval-work-time
                    color: $grey
                  .interval-work-time.active-interval
                    color: $success
                  .interval-work-time.expired-interval
                    color: $red

              td:last-child, th:last-child
                border-right: $border

              td + td, th + th
                width: 100%

              .checkbox-cell
                cursor: pointer
                min-width: 50px
                max-width: 50px
                .checkbox
                  margin: 0 16px
                  cursor: pointer
              .checkbox-cell.cabinet-row-checkbox
                background-color: $primary-background
                .checkbox:before
                  background-color: $primary-background
                .checkbox:after
                  background-color: $primary-background

              .cabinet-cell
                padding-left: 5px
                font-weight: bold
                background-color: $primary-background
                .specialty-select, .cabinet-select, .reset-all-filters
                  color: $primary-font-color
                  cursor: pointer
                  transition: $color-transition
                  &:hover
                    color: darken($primary-font-color, 3%)
                    transition: $color-transition

              .user-name-cell
                flex-direction: row
                align-items: center
                justify-content: flex-start
                cursor: pointer
                flex-basis: 130px
                flex-shrink: 0
                .user-avatar-container
                  margin-left: 10px
                  position: relative
                  img
                    cursor: default
                    border-radius: 50%
                .user-sell_user-surname
                  margin-left: 10px

                .templates-counter
                  display: inline-flex
                  justify-content: center
                  align-items: center
                  max-width: min-content
                  min-width: 16px
                  border-radius: 18px
                  padding: 4px
                  margin-right: 4px
                  background-color: $success
                  color: white
                  white-space: initial
                  position: absolute
                  right: -14px
                  top: -7px
                  height: 18px
                  border: 1px solid #fff
                  cursor: default

              .user-cell
                cursor: pointer

              .vacation-cell
                background-color: #f8f8f8
                cursor: pointer

              .current-date-cell
                cursor: pointer
                border-bottom: 5px solid #fff7b3

              .week-day:hover, .user-name-cell:hover, .user-cell:hover
                background-color: #f8f8f8

              .not-clickable:hover
                cursor: default
                background: white

              .vacation-cell:hover
                background-color: #ececec

              .with-separator
                border-top: none

            tr:nth-child(2)
              td
                border-top: none

            tr:last-child
              td
                border-bottom: $border
                &.current-date-cell
                  border-bottom: 5px solid #fff7b3

