$primary-font: Verdana, Arial, Helvetica, sans-serif
$primary-font-size: 13px
$small-font-size: 12px
$header-font-size: 14px
$buttons-icon-font-size: 15px
$icon-font-size: 17px
$icon-font-size-mini: 12px
$primary-line-height: 1.7

$border-base: solid 1px $primary-color
$border-radius-base: 4px
$border-radius-ext-borders: $border-radius-base * 2
$border-radius-base-left: 4px 0 0 4px
$border-radius-base-top: 4px 4px 0 0

$line-height: 1.4
$clinics-logo-table-height: 30px

$box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12)
$modal-box-shadow-color: rgba(0, 0, 0, 0.02)
$white-overlay: rgba(255, 255, 255, .4)

$hover-opacity: 0.75

$browser-default-print-page-margin: 0.4in // дефолтный margin страницы при печати в хроме

// высоты
$select-line-height: 34px
$element-height: 30px
$element-height-small: 22px
$select-line-height: 34px
$panel-title-height: 28px
$menu-item-height: 34px
$menu-item-width: 28px
$m-select-tag-height: 18px
$table-row-height: 35px

// ширины
$row-cell-basis: 100px

// отступы
$indent: 15px
$indent-mid: 10px
$indent-small: 5px
$indent-validation: 24px

