.m-popover
  z-index: 1050
  width: max-content

  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3)

  .m-popover__footer
    display: flex
    justify-content: space-between

    .m-popover__footer_right,
    .m-popover__footer_left
      display: flex
      align-items: center

  .m-popover__buttons
    display: flex
    justify-content: flex-end

    .m-button
      min-width: 60px

    .margined-top
      margin-top: 10px !important

  &.max-400
    max-height: 400px
    .max-400__target
      max-height: 380px
      overflow-y: auto

  &.w-400
    width: 400px
