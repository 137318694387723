.user-list, .cabinet-list
  =button-mixin
    display: flex
    justify-content: center
    align-items: center
    min-height: 28px
    background-color: $primary-color
    color: white
    border: none
    border-radius: 3px
    outline: none
    .button-text
      margin-left: 3px
    .fad
      color: white

  =text-button-group-mixin
    text-align: left
    margin-bottom: 10px
    padding-left: 15px
    color: $link-color
    div
      margin-bottom: 5px
      cursor: pointer
      span:first-of-type
        margin-right: 5px
      &:hover
        color: $link-color-hover
        text-decoration: underline
      &:last-of-type
        margin-bottom: 0
      .fad
        color: $link-color

  .user-cabinet-switch-control
    display: flex
    justify-content: space-between
    margin-bottom: 10px
    button
      width: 49%
      +button-mixin
    .selected-switch-button
      background-color: $grey-dark

  .specialties-list
    margin-bottom: 10px
    margin-right: 0
    padding-left: 0
    background: white
    list-style: none
    .specialty
      display: flex
      justify-content: space-between
      align-items: center
      height: 28px
      max-height: 28px
      border-radius: 3px
      border: none
      background-color: $primary-color
      color: white
      cursor: pointer
      padding: 0 10px
      margin-bottom: 2px
      .hide-specialties
        span
          float: unset
          line-height: unset
      &.selected
        background-color: $grey-dark
      &:last-of-type
        margin-bottom: 0

  .users-without-cabinets
    margin-bottom: 10px
    button
      +button-mixin
      width: 100%
      &.selected
        background-color: $grey-dark

  .list-select-buttons
    +text-button-group-mixin

  .new-user-notification, .new-cabinet-notification
    margin-bottom: 10px
    display: flex
    flex-direction: column
    border: 1px solid $primary-color
    border-radius: 3px

  .specific-items-list
    width: 100%
    margin-bottom: 10px
    margin-right: 0
    padding-left: 0
    background: white
    list-style: none
    .specific-item
      display: flex
      justify-content: space-between
      align-items: center
      min-height: 33px
      background-color: white
      border: 1px solid $grey
      border-radius: 3px
      color: $primary-font-color
      padding-left: 4px
      padding-right: 8px
      margin-bottom: 2px
      cursor: pointer
      &.selected
        border-left: 5px solid $primary-color
        padding-left: 0
      &:hover
        background: rgba(241, 241, 241, 0.50)
      &:last-of-type
        margin-bottom: 0
      .left-container
        display: flex
        align-items: center
        margin-right: 1px
        padding-left: 3px
        .user-avatar-container
          margin-right: 3px
          img
            border-radius: 20px
            border: 1px solid white
      .waiting-list-count-container
        display: flex
        justify-content: center
        align-items: center
        height: 18px
        min-width: max-content
        padding: 0 6px
        font-weight: bold
        color: white
        background-color: $orange
        border-radius: 10px
        user-select: none
        cursor: default

  .work-time-adjust
    +text-button-group-mixin
