.m-card {
  display: flex;
  flex-direction: column;
  border-color: $grey;

  .el-card__body {
    display: flex;
    flex-direction: column;
    padding: $indent;
    height: 100%;
    overflow-x: hidden;
  }

  .m-card__body {
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
    gap: $indent;
  }

  .m-card__footer {
    border-top: 1px solid $grey-dark-background-color;
    display: flex;
    gap: $indent-small;
    padding: $indent 0 0
  }
}
