.m-panel
  border-radius: $border-radius-base
  display: flex
  flex-direction: column
  min-width: $panel-title-height
  transition: width 200ms ease-in-out
  max-height: 100%

  .m-panel__head,
  .m-panel__body,
  .m-panel__footer
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.2)

  .m-panel__head
    align-items: center
    background-color: $primary-color
    border-top-left-radius: $border-radius-base
    border-top-right-radius: $border-radius-base
    color: $white
    display: flex
    height: $panel-title-height
    min-height: $panel-title-height
    padding: 0 $indent-small 0 $indent-small+1

    &.m-panel__head_warning
      background-color: lighten($orange, 5%)

    &.m-panel__head_success
      background-color: $success

    &.m-panel__head_danger
      background-color: $red

    &.m-panel__head_default
      background-color: $white
      color: $primary-font-color

    .m-panel__title
      font-size: $header-font-size
      flex-grow: 1
      overflow: hidden
      text-overflow: ellipsis
      margin-left: $indent-small
      white-space: nowrap

    .m-panel__icon
      font-size: $icon-font-size-mini

  .m-panel__body
    @media print
      overflow-y: unset

    &.no-footer
      border-bottom-left-radius: $border-radius-base
      border-bottom-right-radius: $border-radius-base

    display: flex
    flex-direction: column
    flex-grow: 1
    background-color: white
    padding: $indent
    overflow-y: scroll

    &:has(.m-menu)
      overflow-y: hidden

    &::-webkit-scrollbar
      width: 2px
      height: 2px

    &::-webkit-scrollbar-track-piece
      background-color: $white
      margin-bottom: 2px

    &::-webkit-scrollbar-thumb
      background-color: $grey-light

    &.hide-scroll
      overflow-y: hidden

  .m-panel__footer
    display: flex
    justify-content: space-between
    border-bottom-left-radius: $border-radius-base
    border-bottom-right-radius: $border-radius-base
    background-color: white
    padding: $indent
    border-top: 1px solid $light-grey

  .m-panel__collapse-button
    align-self: flex-end
    cursor: pointer
    font-size: $header-font-size
    margin: auto

  &.m-panel-collapsed
    transition: width 200ms ease-in-out
    width: $panel-title-height !important


    // перекрытие .front-book__left-panel::min-width
    min-width: $panel-title-height


    .m-panel__head
      cursor: pointer

      .m-panel__title,
      .m-panel__collapse-button
        display: none
