.fixed-document-print-footer {
  display: flex;
  flex-wrap: wrap;
  gap: $indent-mid;
  margin-top: $indent;

  &__back {
    margin-top: 0;
    flex-basis: 100%;
  }
}
