.relations-list__container
  min-height: 32px // чтобы спиннер не триггерил скроллбар

.relations-list
  list-style-type: none
  padding: 0
  margin-bottom: 0
  display: flex
  flex-direction: column
  row-gap: 8px
  .relations-list__item
    padding-top: 8px
    padding-bottom: 8px
    border-bottom: 1px solid $light-grey
    display: flex
    justify-content: space-between
    align-items: center
    &:last-of-type
      border-bottom: none
