@import "styles/clinicLogotypes";

.clinics-catalog {
  display: flex;
  gap: $indent-small;
  width: 100%;

  .clinics-catalog__list {
    flex-basis: 450px;
    flex-grow: 1;

    [data-status="not_active"] {
      opacity: 0.50;
    }

    .col__address {
      display: none;
    }

    &.clinics-catalog__list_show-mode {
      .col__address {
        display: flex;
      }
    }
  }

  .clinics-catalog__editor {
    flex-basis: 70%;
    max-width: 50vw;
    flex-grow: 1;
  }

  .clinics-editor {
    .el-tabs--right .el-tabs__header.is-right {
      margin-left: $indent-small;
    }

    .clinics-editor__tabs {
      flex-grow: 1;
      overflow-y: hidden;

      .el-tabs__content {
        overflow-y: auto;
        height: 100%;
        padding-right: 15px;
      }

      .el-tabs__nav {
        width: 150px;
      }

      .el-tabs__item {
        font-size: 13px;
        padding: 0 $indent;

        &.is-active {
          font-weight: bold;
        }
      }

      .clinic-online-access-tab {
        display: flex;
        flex-direction: column;
        gap: $indent;
      }

      .clinic-egisz-tab {
        .clinic-egisz-tab__mis-number {
          width: 230px;
        }
      }

      .clinic-main-tab {
        .m-switch.is-checked {
          &.success {
            .el-switch__label {
              color: $green;
            }

            .el-switch__core {
              background-color: $green;
              border-color: $green;
            }
          }

          &.warning {
            .el-switch__label {
              color: $warning;
            }

            .el-switch__core {
              background-color: $warning;
              border-color: $warning;
            }
          }
        }
      }
    }
  }
}
