#egisz-root
  display: flex
  width: 100%
  max-width: 100vw

  .navigation-menu
    margin-right: 5px
    max-width: 250px
    min-width: 250px
    width: 100%
    .sidebar-switch
      display: flex
      flex-direction: column
      margin-bottom: 4px
      .vue-list
        display: flex
        column-gap: 4px
      .vue-list-item
        flex-basis: 50%
        text-transform: uppercase
        margin: 0


    .panel-body
      padding: 5px 5px

    .navigation-menu__hide-navigation-icon
      font-size: 2.6rem

  .current-page
    display: flex
    flex-grow: 1
    flex-direction: column

  .btn-control
    align-items: center
    display: flex
    height: 30px
    padding: 0 10px
    font-size: 12px

  .current-page__semd-table
    overflow-x: hidden

    .panel-body
      display: flex

      .egisz-semds
        overflow: hidden
        & > .m-si-generator
          overflow: hidden
