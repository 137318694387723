.m-checkbox
  display: flex
  align-items: flex-start

  .el-checkbox__input
    &.is-indeterminate
      .el-checkbox__inner::before
        top: 6px !important


  .el-checkbox__inner
    height: 16px !important
    top: 2px !important
    width: 16px !important

    &::after
      border-width: 2px !important
      height: 10px !important
      left: 5px !important
      top: 0 !important

  .el-checkbox__label
    padding-left: 5px
    position: relative
    top: 1px
    font-size: $primary-font-size
    text-wrap: wrap
