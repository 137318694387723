.si
  height: inherit
  display: flex
  flex-direction: column
  &.si-left
    flex-direction: row
    flex-wrap: wrap
    .si-control
      align-items: flex-start
      margin-right: 20px
      flex-direction: column
      margin-top: 6px
      height: calc(100% - 65px)
      .si-control-item
        margin: 0 6px 10px 0px
      .si-control-tools-footer
        margin-top: auto
    .si-table
      height: calc(100% - 55px)

@media print
  .si
    display: block
