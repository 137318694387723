.treatment-plans-entries-transfer {
  .treatment-plans-entries-transfer__reference {
    position: relative;

    .treatment-plans-entries-transfer__counter {
      .el-badge__content {
        font-size: 10px;
        line-height: 16px;
      }

      pointer-events: none;
      position: absolute;
      right: -5px;
      top: 3px
    }
  }

  .treatment-plans-entries-transfer__modal {
    .el-dialog {
      min-height: 400px;

      .el-dialog__body {
        overflow-x: hidden;
        overflow-y: scroll;
        margin-bottom: $indent;
        padding-right: 0;
      }
    }
  }

  .treatment-plans-tree {
    max-width: 100%;
    width: 100%;

    .el-tree-node__content {
      height: 40px;
      max-width: 100%;

      span.is-leaf {
        display: none;
      }
    }

    .treatment-plan-node {
      display: flex;
      gap: $indent-mid;
      align-items: center;
      width: 100%;
      padding-right: $indent;

      .treatment-plan-node__title {
        display: flex;
      }
    }

    .treatment-entry-node {
      display: flex;
      align-items: center;
      gap: $indent;
      max-width: 100%;
      width: 100%;

      &.treatment-entry-node_first {
        border-bottom: 1px solid $light-grey;
        font-weight: bold;
      }

      .treatment-entry-node__transferred-amount {
        flex-shrink: 0;
        flex-basis: 100px;
      }

      .treatment-entry-node__title {
        flex-grow: 1;
      }

      .treatment-entry-node__price {
        flex-shrink: 0;
        flex-basis: 150px;
      }

      .treatment-entry-node__available-amount {
        flex-shrink: 0;
        flex-basis: 100px;
      }

      .treatment-entry-node__discount {
        flex-shrink: 0;
        flex-basis: 100px;
      }

      .treatment-entry-node__comment {
        flex-shrink: 0;
        flex-basis: 100px;
        max-width: 100px;
      }

      .treatment-entry-node__state-icon {
        flex-shrink: 0;
        flex-basis: 40px;
      }
    }
  }
}
