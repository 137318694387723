@import "components/EntriesTab";
@import "components/AttachmentsTab";
@import "components/ProtocolsTab";

#entry_list_area {
  .medical-records-tabs-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    overflow-y: hidden;
    gap: $indent-mid;

    .el-tabs__header {
      margin: 0;

      .el-tabs__nav.is-top {
        border-top-left-radius: 0;
      }

      .el-tabs__nav-wrap {
        padding: 0 20px 0 20px;

        .el-tabs__nav-prev,
        .el-tabs__nav-next {
          border: 1px solid $light-grey;
          background-color: $form-label-bg-color;
          width: 20px;
          line-height: 35px;

          i {
            font-weight: bold;
            font-size: $icon-font-size;
            vertical-align: middle;
            color: $primary-color;
          }
        }

        .el-tabs__nav-prev {
          border-right: none;
          border-top-left-radius: $border-radius-base;

          i {
            margin-left: 1px;
          }
        }

        .el-tabs__nav-next {
          border-left: none;
          border-top-right-radius: $border-radius-base;

          i {
            margin-left: 3px;
          }
        }
      }
    }

    .el-tabs__item {
      font-size: $primary-font-size;
      padding: 0 $indent-mid;
      height: 36px;
      line-height: 34px;

      &.is-active {
        background-color: $primary-background-color;
      }
    }

    .el-tabs__content {
      display: flex;
      overflow-y: hidden;
      height: 100%;

      .el-tab-pane {
        width: 100%;
        overflow-x: hidden;

        &#pane-egisz {
          display: flex;
        }
      }
    }

    .medical-records-tabs-container__tab {
      display: flex;
      height: 100%;
      overflow: hidden;
    }


    .documents-tab,
    .protocols-tab {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      overflow: hidden;
    }

    .medical-records-tabs-container__egisz-tabs {
      display: flex;
      flex-direction: column;
      width: 100%;

      .el-tabs__header {
        margin-bottom: $indent-mid;

        .el-tabs__nav-wrap.is-top {
          padding: 0;
        }

        .el-tabs__item {
          height: $element-height;
          line-height: 28px;

          &.is-active {
            background-color: $primary-background-color;
          }
        }
      }

      #pane-semds {
        .egisz-semds {
          display: flex;
          flex-direction: column;
        }

        .sidebar-filters {
          flex-direction: row;
          gap: $indent-small;
          flex-wrap: wrap;

          .sidebar__filter {
            width: unset;
            flex-grow: 1;

            &_period {
              width: 100%;
              max-width: 230px;
            }
          }
        }

        .col__client {
          display: none;
        }
      }
    }
  }
}
