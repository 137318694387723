.appointment-client-summary-table
  td:first-child, .last-visit, .special-marks, .adv_distribution
    font-size: 11px
    width: 80px
  .adv_distribution
    color: $red-light
  .last-visit
    &__days-ago
      color: $red-light
  .previous_appointments_show-text
    color: $red-light
    text-decoration: underline

  .client-groups-list
    .label
      margin-right: 5px
      font-size: 11px
      max-width: 230px
      display: inline-block
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
