.reminder-client-input
  .el-input.is-disabled .el-input__inner
    color: currentColor
  .errors-content
    margin-left: 30px
  .reminder-client-container
    display: flex
    align-items: center
    column-gap: 3px
    position: relative
    .client-actions-modal
      background: transparent
      border: none
      padding: 0
      margin: 0
      .fad
        cursor: pointer
        &.fa-bars
          font-size: 20px
          color: $primary-color
      &:disabled
        .fad
          cursor: not-allowed
          &.fa-bars
            color: #ccc
    .fa-pencil
      cursor: pointer
      position: absolute
      right: 10px
      top: 50%
      transform: translateY(-50%)
