.egisz-documents
  display: flex
  flex-direction: column

  .egisz-documents__control
    display: flex

  .egisz-documents__data-table
    flex-grow: 1
    margin: 5px 0

    .doc-table__col_cb
      max-width: 28px

      .el-checkbox
        margin: 0
        .el-checkbox__inner
          width: 17px
          height: 17px
          &:after
            height: 9px
            left: 6px

          &:before
            height: 3px
            top: 6px

    .doc-table__col_remove
      color: $red
      cursor: pointer
      font-size: 15px
      max-width: 40px

    .doc-table__row
      cursor: pointer
