.el-notification {
  border-radius: $border-radius-base * 3;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);

  .el-notification__title {
    font-size: $header-font-size;
  }

  .el-notification__icon {
    opacity: 0.75;
  }
}
