@import './entry_types_create_form.sass'
@import './entry_types_association_protocol_modal.sass'

.entry-association
  .entry-search
    width: 90%
    margin-right: 20px
    .el-input__inner
      border: none
      border-bottom: 1px solid #eee

.entry-association
  .si-field
    .options
      font-size: 16px
      flex-basis: 30px
      cursor: pointer
      color: #F44336
    .title
      flex-basis: 100%
      max-width: 100%
      flex-grow: 1
    div
      flex-basis: 110px

    .amount
      input
        width: 85%

  .footer .si-field
    width: 100%
    border-top: 2px solid #3FAECA
    .summary
      font-weight: bold
      font-size: 13px

.el-scrollbar .el-scrollbar__view.el-autocomplete-suggestion__list
  .name
    display: flex
    justify-content: space-between