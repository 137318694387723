#last-appointments-modal,
#last-appointments-modal-standalone
  .modal-content,
  .modal-body
    height: 100%
  .last-appointments
    height: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    outline: none
    .appointment-create-order
      padding-left: 0
      color: $primary-color

    .schedule-grid-appointment-btn-container
      display: flex
      column-gap: 5px
      .btn
        flex-grow: 1

  .checkbox
    margin-left: unset
