@import './mixins/appointments_reservations'

.cabinet-schedule-grid
  height: 100%
  overflow-x: hidden
  overflow-y: scroll
  user-select: none
  position: relative
  padding-right: 5px
  &::-webkit-scrollbar
    width: 5px

  .time-line
    position: absolute
    width: 100%
    height: 1px
    right: 5px
    background-color: $orange
    z-index: 1

  $border: 1px solid $grey

  table
    display: flex
    flex-direction: column
    width: 100%
    thead
      width: 100%
      position: sticky
      top: 0
      z-index: 10

    tbody
      tr:last-child
        td
          border-bottom: $border
        td:first-child
          height: 27px

    thead, tbody
      display: block

      tr
        display: flex
        width: 100%

        td, th
          display: flex
          flex-direction: column
          height: 25px

        td
          font-size: 11px
          border-right: $border
          border-top: $border

        td + td, th + th
          width: 100%

        td:first-child
          border-left: $border

        th:first-child, td:first-child
          max-width: 45px
          min-width: 45px

        th
          text-align: center
          height: auto
          overflow: hidden
          .schedule-grid-header
            z-index: 6
            background: white
            transition-duration: 0.3s
            border: 1px solid white
            padding: 2px
            font-weight: normal
            max-height: 40px
            overflow: hidden
            font-size: 13px
            height: 100%
          .schedule-grid-header-cabinet:hover
            cursor: pointer
            background: #f8f8f8
            border: 1px solid #f8f8f8
            //transition-duration: 0.3s

        th.schedule-grid-date
          .schedule-grid-header
            font-weight: bold
            background: #ADADAD
            color: white

        td.schedule-grid-time
            position: relative
            height: 26px
            .time-container
              color: $primary-font-color
              position: absolute
              z-index: 2
              background-color: white
              border-radius: 1px
              top: 50%
              left: 47%
              transform: translate(-50%, -50%)

        td.schedule-grid-available, .schedule-grid-cell-time, .schedule-grid-cell-paste
          cursor: pointer

        td.schedule-grid-available:hover
          background: #f8f8f8

        td.schedule-grid-with-divider
          border-right: 2px solid #ADADAD

        td:last-child.schedule-grid-with-divider
          border-right: $border

        td.schedule-grid-available, td.schedule-grid-unavailable
          box-sizing: content-box
          padding: 0
          position: relative

          .schedule-grid-cell-time
            position: absolute
            right: 0
            top: 0
            background-color: #f8f8f8
            border-radius: 5px
            transition: visibility 0.2s, opacity 0.2s linear
            visibility: hidden
            opacity: 0
            z-index: 5
            padding: 1px

          .schedule-grid-cell-paste
            position: absolute
            right: 0
            z-index: 5
            display: none
            bottom: -5px
            font-weight: bold
            color: #F44336
            font-size: 17px
            margin-top: 1px
            top: 6px

          &:hover .schedule-grid-cell-time, &.schedule-grid-container-drag-hover .schedule-grid-cell-time
            transition: visibility 0.2s, opacity 0.2s linear
            visibility: visible
            opacity: 1

          &.schedule-grid-no-celltime:hover .schedule-grid-cell-time
            display: none

          &.schedule-grid-not-empty .schedule-grid-cell-time
            display: none

          +appointments-reservations-mixin
          .schedule-grid-appointments-container
            .schedule-grid-appointment-container, .schedule-grid-reservation-container
              .schedule-grid-appointment, .schedule-grid-reservation
                opacity: 1
                transition: opacity 0.2s linear
                .schedule-grid-appointment-doctor-name
                  position: absolute
                  padding: 1px
                  margin-left: -2px
                  bottom: 0
                  opacity: 0
                  background: aliceblue
                  color: $primary-font-color
                  width: 100%
                  font-size: 10px
              .schedule-grid-hide-appointment
                opacity: 0.3
                transition: opacity 0.2s linear
                transition-delay: 800ms
                div, span
                  opacity: 0
                  transition-delay: 800ms
              .schedule-grid-appointment:hover
                .schedule-grid-appointment-doctor-name
                  height: 15px
                  bottom: 0
                  opacity: 1
                .schedule-grid-appointment-icons-container
                  div
                    color: $primary-font-color

        td.schedule-grid-unavailable
          background: gainsboro

        td.schedule-grid-available
          .schedule-grid-doctor-name-container
            position: absolute
            top: 0
            padding-top: 2px
            display: flex
            flex-wrap: wrap
            overflow-y: hidden
            height: 25px
            width: 100%
            &::-webkit-scrollbar
              height: 0
            .schedule-grid-doctor-name
              font-size: 10px
              flex-basis: 50%
              padding-left: 2px
              line-height: 1
              flex-grow: 1

  table.empty-table
      thead
        display: none
      tbody
        tr
          td.schedule-grid-time
            max-width: 100%
            width: 100%

.cabinet-schedule-grid.schedule-grid-mode-paste
  table
    tbody
      tr
        td.schedule-grid-available:hover
          .schedule-grid-cell-paste
            display: inline

.cabinet-schedule-grid.hide-appointments
  table
    tbody
      tr
        td
          .schedule-grid-appointments-container
              .schedule-grid-appointment-container, .schedule-grid-reservation-container
                .schedule-grid-appointment, .schedule-grid-reservation
                  opacity: 0.3
                  //transition: opacity 0.1s linear
                  div, span
                    opacity: 0

@media print
  .schedule-grid .schedule-grid-appointment
    border: 1px solid #7b7b7b !important
    background: antiquewhite !important
