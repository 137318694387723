$paddingSizePX: 25px
$maxContentHeight: calc(100vh - 25px - 25px)
$minContentWidth: 600px

#js_global_introduction_modal
  .modal-dialog
    display: flex
    height: 100vh
    justify-content: center
    margin: unset
    padding-top: $paddingSizePX
    width: 100vw

    .modal-content
      height: fit-content
      max-height: $maxContentHeight
      min-width: $minContentWidth
      width: fit-content
