.egisz-clients-table
  .clients-col
    flex-grow: 0

    &.clients-col_date
      --size: 180px

    &.clients-col_status-btn
      --size: 65px

    &.clients-col_status
      --size: 30px

    &.clients-col_clinic
      --size: 200px

    &.clients-col_fio
      --size: 250px

    &.clients-col_phone
      --size: 180px

    &.clients-col_snils
      --size: 160px

    &.clients-col_comment
      flex-grow: 1
      max-width: 400px

    &.clients-col_errors
      flex-grow: 1

    &.clients-col_actions
      text-align: center
      --size: 140px
