.client-search-create {
  &-form-short {
    display: flex;
    flex-direction: row;
    gap: $indent;
    width: calc(100% - $indent);

    @include mFieldsFW;

    &__col {
      flex-shrink: 0;
      flex-basis: 50%;
      flex-grow: 1;
    }
  }
}
