.main-item-right
  display: flex
  flex-direction: column
  .schedule-right-panel
    width: 225px
    height: 100%
    display: flex
    flex-direction: column
    overflow-y: hidden
    padding: 5px
    .inner-container
      position: relative
      height: 100%
      display: flex
      flex-direction: column
      position: relative
      #calendar-wrapper
        height: 100%
        overflow-y: auto
        padding-right: 2px
        .schedule-calendar
          +jquery-calendar-component

        .schedule-control-elements
          .control-element-title
            background: $green
            color: white
            padding: 2px
            margin: 3px 0

          .control-element-checkbox
            display: flex
            margin-top: 10px
            .control-element-checkbox-label
              padding-top: 1px
              text-align: left
              font-size: 12px
              white-space: nowrap
              label
                font-weight: normal

          .control-element-buttons
            .select2Buttons
              text-align: center
              margin-left: unset
              .select-buttons
                display: flex
                flex-wrap: wrap
                justify-content: center
                text-align: center
                li
                  margin: 2px
                  min-width: 30px
                  a
                    min-width: 30px

      +waiting-list-component
      +reminders-list-component
      +online-recording-list-component

      #btn-trigger-group
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        min-height: max-content
        margin-top: 5px
        width: 100%
        overflow: hidden
        z-index: 1
        position: absolute
        bottom: 0
        .side-menu-trigger
          display: flex
          justify-content: center
          align-items: center
          min-height: 30px
          max-height: 30px
          min-width: 106px
          max-width: 106px
          margin-bottom: 3px
          border-radius: 2px
          cursor: pointer

          .trigger-icon
            color: white
            font-size: 14px
            margin-right: 10px
          .item-counter
            display: flex
            justify-content: center
            align-items: center
            height: 18px
            min-width: max-content
            padding: 0 6px
            font-weight: bold
            color: white
            background-color: #777
            border-radius: 10px
            user-select: none
        .calendar-trigger
          background-color: $primary-color
          .trigger-icon
            margin: 0
        .reminders-trigger
          background-color: $green
        .waiting-list-trigger
          margin-bottom: 0
          background-color: $orange
        .online-recording-trigger
          margin-bottom: 0
          background-color: $crimson
        .active
          background-color: $grey-dark

.main-item-right.rolled
  .schedule-right-panel
    display: none
