=el-checkbox17
  .el-checkbox__input
    top: 2px

    &.is-indeterminate
      .el-checkbox__inner
        &:before
          height: 3px
          top: 6px

    .el-checkbox__inner
      $cb-size: 17px
      width: $cb-size
      height: $cb-size
      &:after
        height: 10px
        left: 5px
