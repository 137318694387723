.m-collapse {
  overflow-y: scroll;
  padding-right: $indent-mid;

  &-item {
    &__title {
      display: flex;
      align-items: center;
      gap: $indent-small;
      width: 100%;
      text-wrap: nowrap;
      overflow-x: auto;
    }

    &-title {
      &__text {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &_left-arrow {
      $el-collapse-item-arrow: 21px; // width: 13px + margin-right: 8px
      $padding-left-with-folder: $el-collapse-item-arrow + $icon-font-size + $indent-small;

      .el-collapse-item {
        &__header {
          flex-direction: row-reverse;
          justify-content: flex-end;
        }

        &__content {
          padding-left: $el-collapse-item-arrow;
        }

        &__arrow {
          margin-left: 0;
        }
      }

      &:has(.m-collapse-item-title__icon) {
        .el-collapse-item__content {
          padding-left: $padding-left-with-folder;
        }
      }
    }

    &__body {
      padding: $indent-small 0 $indent-small 0;
    }
  }
}
