=waiting-list-component
  #waiting-list-wrapper
    display: flex
    flex-direction: column
    position: relative
    height: 0
    flex-basis: 100%
    .schedule-waiting-list-control-element
      width: 100%
      margin-bottom: 5px
      button
        margin: 0
        width: 100%
      .el-select
        margin-top: 5px
        width: 100%
        .el-input
          font-size: 13px
          line-height: 30px
          input
            height: 30px
            &:focus
              border-color: $orange
          .el-input__suffix
            .el-input__suffix-inner
              .el-input__icon
                line-height: 30px
      .el-range-editor.is-active
        border-color: $orange
      .el-range-editor
        width: 100%
        height: 30px
        line-height: 30px
        margin-top: 5px
        .el-range__icon
          display: none
        .el-range-input
          font-size: 13px
          width: 42%
        .el-range-separator
          line-height: 22px
          width: 8%
        .el-range__close-icon
          line-height: 22px
          width: 8%

    .waiting-list
      height: 100%
      overflow-y: scroll
      ul
        list-style: none
        margin: 0
        padding: 0
        li
          padding: 5px
          cursor: pointer
          border-bottom: 1px solid $grey
          &:hover
            background-color: rgba(217, 161, 78, 0.1)
          .date-client-line, .doctor-line
            text-align: left
          .doctor-line
            .appointment-reference-icon
              color: #979797
          .waiting-list-urgent
            color: $orange

.user-list-search-select
  .el-input__prefix
    align-items: center
    display: flex
    margin-left: 3px
