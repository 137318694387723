.tax-certificate2024-editor {
  max-width: 850px;
  overflow-y: scroll;
}

.tax-certificate2024-editor__body {
  overflow-y: scroll;
  padding: 0 $indent $indent $indent;
}

.tax-certificate2024-editor__footer {
  box-shadow: $box-shadow;
  padding-top: $indent;
}

.fixed-document-editor-header {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.05);
}

.fixed-document-editor-header,
.tax-certificate2024-editor__footer {
  padding: $indent;
}


.tax-certificate2024-editor {
  overflow-y: hidden;

  @media print {
    overflow-y: unset;
  }
}

