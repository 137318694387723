.measure-units {
  display: flex;
  gap: $indent-small;
  flex-grow: 1;

  &__list-wrapper {
    flex-basis: 45%;
  }

  &__editor-wrapper {
    flex-basis: 55%;
  }

  &__editor-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__replace-measure-unit-modal {
    .el-dialog__body {
      flex-direction: column;
      gap: $indent;
      padding-bottom: 0;
    }
  }

  &__warning-icon {
    font-size: 24px !important;
    float: left;
    margin: $indent-small $indent-mid 0 0;
  }
}
