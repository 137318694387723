.entry-types-tree-modal {
  .m-modal {
    .el-dialog__footer {
      margin-top: $indent;
    }

    .el-tree {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      max-width: min(calc(70vw - 40px), 1160px);

      .entry-types-tree-node {
        display: flex;
        align-items: center;
        width: 100%;

        .entry-types-tree-node__icon {
          flex-basis: $indent;
          flex-shrink: 0;
        }

        .entry-types-tree-node__title {
          flex-grow: 1;
        }

        .entry-types-tree-node__price {
          flex-basis: 120px;
          flex-shrink: 0;
        }
      }
    }
  }
}
