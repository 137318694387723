.navigation-menu
  .el-menu
    border: none

    .el-menu-item
      padding: 0 0 0 10px !important
      height: 30px
      line-height: 28px

      &.is-disabled
        border-bottom: 1px solid $grey
        color: $orange
        font-style: italic
        opacity: 1

      &.is-active
        background-color: $primary-background-color
        i, span
          color: $primary-color

        &:hover
          background-color: $primary-background-color-dark

