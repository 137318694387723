.document-table {
  display: flex;
  flex-direction: column;
  gap: $indent-mid;

  &__table, &__th, th, &__td, td {
    border: 1px solid $primary-font-color;
  }

  &__table {
    width: 100%;
  }

  &__th, th {
    background-color: $light-grey;
    text-align: center;
  }

  &__th, th, &__td, td {
    padding: $indent-small;
  }

  &_td, td {
    .document-field-writable__input input {
      height: 30px !important;
      padding-bottom: 10px !important;
    }
  }

  &__delete-row {
    width: 30px;
  }

  &__add-row {
    width: min-content;
  }

  &__header-delete-icon, &__delete-row, &__add-row {
    @media print {
      display: none;
    }
  }
}
