.companies_page
  .company-nav-panel
    .panel-heading-title
      overflow: hidden
    .panel-body
      padding: 5px
  .company-container
    display: flex
    flex-direction: column
    width: calc(100% - 255px)
  .company-container-collapsed
    width: calc(100% - 35px)

  .company-container
    margin-bottom: 5px
    overflow: hidden
    .company-router-container
      height: 100%
      margin-bottom: 0
      > .panel-body
        height: calc(100% - 34px)
  .company-show
    display: flex
    flex-wrap: wrap
    margin: 0px -5px 0px -5px
    .delete-company
      .modal-footer-right
        display: flex
        .btn
          display: flex
          align-items: center

    .left-container
      .panel-body
        height: unset
      flex: 1
      .flex-form
        margin-bottom: 10px
    .right-container
      margin-left: 10px
      flex: 1
    .bottom-container
      width: 100%
      display: flex
      .delete-company
        width: 550px
    .company-show-phones
      display: flex
      flex-direction: column
      > .phone_number
        width: max-content
      
@media screen and ( max-width: 1220px )
  .companies_page .company-nav-container .company-container .company-show
    flex-direction: column

.company-payments
  .si-control .si-control-tools-footer
    width: 100%
    margin-top: 10px
  
  .btn--massrefund
    margin-left: auto
  .si-table
    .si-field
      .tbl-icon
        flex-basis: 35px
        flex-grow: 1
        flex-shrink: 0
    