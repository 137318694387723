.about-modal {
  .m-button {
    .m-button__text {
      color: $primary-color;
    }

    &:hover {
      .m-button__text {
        color: white;
      }
    }
  }
}
