=link-styles($color: $link-color-hover)
  color: $color
  text-decoration: underline
  cursor: pointer

.calls_index_page
  #calls_table
    thead
      width: 100%

    .call_row
      &.selected
        background-color: rgba(255, 152, 0, 0.15)
    .duration
      width: 60px
    .number
      width: 50px
    .type
      width: 30px
    .short_name
      width: 120px
    .call-status
      width: 30px
    .phone
      width: 150px
    .client_name
      width: 11%
      span
        cursor: pointer
        &:hover
         +link-styles
    .result
      width: 135px
    .datetime
      width: 140px
    .call-modal
      width: 30px
      .fa-phone-rotary
        color: $link-color
        font-size: medium
        &:hover
         +link-styles
    .record
      width: 55px
      .call_record_player_show
        &.fa-headphones-alt
          color: $link-color
          font-size: large
          &.active
            +link-styles($grey-dark)
          &:hover:not(.active)
            +link-styles
      .popover
        max-width: 323px
        z-index: 0
        background-color: #f1f3f4
        .popover-content
          padding: 1px 5px

  .app-header
    z-index: 1
  .refuse_result
    span
      cursor: pointer
    .fa-plus-circle
      font-size: 16px
      text-align: center
      color: gray
