.company-registry-list
  height: 100%
  .company-registry-controls
    display: flex
    width: 100%
    align-items: center
    .clients-options-btn
      margin-bottom: 15px
  .si-control
    flex-wrap: nowrap
    .btn-add-registry
      margin-left: 40px
  .si-field
    .number
      flex-grow: 2
    .description
      flex-grow: 20
    .edit-options
      color: #34a0bc
    .delete-options
      color: #F44336
