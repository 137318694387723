#report_entries_report {
  @media print {
    .col {
      &__dateCell {
        flex-basis: 85px !important;
      }

      &__statusCell {
        flex-basis: 40px !important;
      }

      &__attractionSourceCell {
        flex-basis: 100px !important;
        word-break: break-all;
      }

      &__clientCell {
        flex-basis: 120px !important;
        word-break: break-all;
      }

      &__entryTypesCell {
        flex-basis: 150px !important;
        word-break: break-all;
      }

      &__administratorCell {
        flex-basis: 120px !important;
        word-break: break-all;
      }

      &__doctorCell {
        flex-basis: 120px !important;
        word-break: break-all;
      }

      &__createdAtCell {
        flex-basis: 85px !important;
      }
    }
  }
}
