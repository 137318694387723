.m-input {
  &-wrapper {
    $padding-x: 30px;

    display: flex;
    align-items: center;
    gap: $indent-small;
    position: relative;

    @mixin prefix-suffix-mixin {
      position: absolute;
      z-index: 1;
    }

    &__prefix {
      @include prefix-suffix-mixin;
      left: $indent-small;
    }

    &__suffix {
      @include prefix-suffix-mixin;
      right: $indent-small;
    }

    &__has-prefix {
      .m-label {
        left: 32px !important;
      }

      .el-input__inner {
        padding-left: $padding-x;
      }
    }

    &__has-suffix {
      .el-input__inner {
        padding-right: $padding-x;
      }
    }
  }
}
