.client-search-filter {
  margin-right: 5px;
  flex-basis: 310px;

  #client_id {
    display: none;
  }

  .validation-wrapper {
    padding-bottom: initial;
  }
}
