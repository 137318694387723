.epic-spinner-default-transition-enter-active, .epic-spinner-default-transition-leave-active
  transition: opacity .2s cubic-bezier(1.0, 0.5, 0.8, 1.0)

.epic-spinner-default-transition-enter, .epic-spinner-default-transition-leave-to
  opacity: 0

.epic-spinner-loading-mask
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  flex-direction: column
  display: flex
  justify-content: center
  align-items: center
  background-color: $modal-box-shadow-color
  border-radius: 2px
  z-index: 20000
  .epic-spinner
    position: absolute
    width: 0
    z-index: 20001
    left: 50%
    top: 50%
    .epic-spinner-animation
      position: absolute
      top: -1px
      opacity: 0.25
      .epic-spinner-inner-element
        position: absolute
        width: 6px
        height: 2px
        background: #868686
        box-shadow: rgba(0, 0, 0, 0.1) 0 0 1px
        transform-origin: left center 0
        border-radius: 1px
