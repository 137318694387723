@import '../el-checkbox17'

.ip-table
  top: -4px

  .ip-table__field
    min-height: 36px
    position: relative

    .ip-table__checkbox
      padding: 0 8px 0 10px
      +el-checkbox17

    .ip-table__address
      flex-basis: 150px
      flex-grow: 0

    .ip-table__active
      flex-basis: 150px
      flex-grow: 0

    .ip-table__comment
      flex-grow: 1
      max-width: 45vw
      @media screen and (min-width: 1920px)
        max-width: 50vw

    .ip-table__search
      font-weight: normal
      max-width: 250px
      position: absolute
      right: -$indent-small

  .ip-table__row
    cursor: pointer
    &.ip-table__row_selected
      color: $primary-color
      background-color: $primary-background-color !important
      &:hover
        background-color: $primary-background-color-dark !important
