.m-draggable-window {
  position: fixed;
  z-index: 1;

  height: 615px;
  width: 615px;

  .m-popover {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
  }
}
