.emd
  height: 100%

  .emd__controls
    display: flex
    flex-wrap: nowrap
    gap: 3px
    @media (max-width: 1400px)
      flex-wrap: wrap
      justify-content: flex-start

  .emd__table
    .col
      cursor: pointer

    .col__status
      max-width: 40px

    .col__number
      max-width: 80px

    .col__options
      color: $red
      cursor: default
      font-size: 17px
      max-width: 40px

    .col__created-at
      max-width: 120px
