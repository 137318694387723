.m-search-select-input-deprecated {
  .validation-wrapper {
    padding-bottom: 0;
  }
}

.dropdown_m-search-select-input {
  padding: 15px 20px;
  overflow-y: auto;
  max-height: 250px;

  .dropdown-category-title {
    font-weight: bold;
  }

  .el-dropdown-menu__item {
    display: flex;
    padding: 0;
    flex-direction: row;
    align-items: center;
    flex: 1;
  }

  .min-string-length-hint {
    color: $number-color-disabled;
  }
}
