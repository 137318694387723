.fixed-document-editor-header {
  display: flex;
  align-items: center;
  gap: $indent-mid;
  margin-bottom: $indent-mid;

  &__prompt-notice {
    flex-grow: 1;
  }

  .m-button-wrapper {
    margin: 0;
  }
}
