.filterable-select
  min-height: $element-height
  .el-select__input
    border: none
    margin-left: 5px !important
    max-height: unset !important
    &:focus
      outline: none
      box-shadow: none
      border: none
  .el-input__suffix
    .el-icon-arrow-up
      opacity: 0
  .el-input__prefix
    display: flex
    align-items: center
    left: auto
    right: 10px
  .el-input--prefix
    .el-input__inner
      padding-left: 15px
