.el-select.si-control-item.payer-deleted
  .el-input__inner
    text-decoration: line-through
  
.payer-dropdown-list
  max-width: 500px
  .el-scrollbar
    .el-select-dropdown__wrap
      .el-select-dropdown__list
        .el-select-group__wrap
          .el-select-dropdown__item
            padding: 7px 20px
            height: auto
            line-height: 20px
.more-info-company
  opacity: 0.5
  margin-right: 5px
  &:hover
    color: #3FAECA