@import './appointment_types'

=appointments-reservations-mixin
  .schedule-grid-appointments-container
    padding: 0 10% 0 0
    height: 100%
    flex-direction: row
    display: flex
    align-items: stretch

    .schedule-grid-placeholder
      position: relative
      align-self: stretch
      flex: 1
      flex-direction: row
      display: flex

    .schedule-grid-appointment-container, .schedule-grid-reservation-container
      position: relative
      align-self: stretch
      flex: 1
      flex-direction: row
      display: flex

      .schedule-grid-appointment, .schedule-grid-reservation
        display: flex
        flex-wrap: wrap
        align-items: flex-start
        align-content: flex-start
        position: absolute
        width: 100%
        top: 0
        z-index: 2
        border: 1px solid white
        border-radius: 4px
        background-color: $primary-color
        font-size: 13px
        color: white
        cursor: pointer
        line-height: 17px
        overflow: hidden
        padding-left: 2px
        padding-top: 2px

    .schedule-grid-reservation-container
      .schedule-grid-reservation
        background-color: white
        border: 1px solid $primary-color
        font-style: italic
        color: #475264
        padding-left: 4px
        display: flex
        flex-direction: column
        flex-wrap: nowrap

    .schedule-grid-appointment-container
      .schedule-grid-appointment
        font-size: 11px
        line-height: 1.2

        .schedule-grid-appointment-surname-container
          display: flex
          .schedule-grid-appointment-status-icon
            margin-right: 3px
            margin-top: 2px

        .schedule-grid-appointment-time-end
          margin-right: 5px

        .schedule-grid-appointment-icons-container
          position: absolute
          right: 0
          bottom: 0
          width: fit-content
          height: fit-content
          display: flex
          flex-direction: row
          justify-content: space-between

          .schedule-grid-appointment-unbilled-entries
            width: 13px
            height: 13px
            color: white

          .schedule-grid-appointment-confirmation-call-status
            width: 13px
            height: 13px
            color: white
            margin-left: 2px

          .schedule-grid-appointment-reminder-call-status
            width: 13px
            height: 13px
            color: white
            margin-right: 3px
            margin-left: 2px

      .appointment-with-hidden-window-in-the-schedule-top
        border-top-style: dotted
        border-top-width: 2px

      .appointment-with-hidden-window-in-the-schedule-bottom
        border-bottom-style: dotted
        border-bottom-width: 2px

      +appointment-types-mixin

    .schedule-grid-appointment-container.by_dms
      .schedule-grid-appointment
        .schedule-grid-appointment-surname-container
          .schedule-grid-appointment-surname
            background-color: white
            color: $primary-font-color
            border-radius: 2px
            padding-left: 3px
            margin-right: 2px

    .schedule-grid-appointment-container.VIP
      .schedule-grid-appointment
        border-color: $red-light

    .schedule-grid-appointment-container.black_list
      .schedule-grid-appointment
        border-color: black

