.personal-checkup-entries {
  flex-grow: 0 !important;

  &__filters {
    display: flex;
    gap: $indent-small;

    .el-checkbox__label {
      white-space: nowrap;
    }
  }

  .m-si-generator {
    height: auto !important;
    flex-grow: 0;
  }

  &-modal {
    .m-checkbox {
      width: max-content;
    }

    .el-dialog__body {
      display: flex;
      flex-direction: column;
    }
  }

  .m-table__body {
    flex-grow: 0;
  }

  &_edit {
    .m-table__header {
      height: 0;
    }
  }
}
