.entry-types-search-add {
  .m-search-select-input-deprecated, .m-dropdown {
    width: 100%;
  }
}

.entry-types-search-add__menu {
  max-width: 800px !important;
  width: 100%;

  .entry-option {
    max-width: unset !important;
  }
}
