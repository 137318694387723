.personal-checkup-doctor-results-modal {
  .el-dialog__body {
    flex-direction: column;
  }

  &__foreign-user {
    margin-left: $indent-small;
    margin-right: auto;
  }
}
