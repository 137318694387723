.catalogs_table
  .catalogs_table__add-btn
    margin: 0 0 10px
    max-height: 30px
    min-width: 50px
    padding: 6px 12px

  .catalogs_table__title
    display: flex
    align-items: center

  .catalogs_table__item
    cursor: pointer

    .catalogs_table__default-icon
      width: 23px

      .fad
        color: $primary-color

    .catalogs_table__icon
      margin-right: 15px
      font-size: 16px
      color: $primary-font-color

  .catalogs_table__item.active
    background-color: $primary-background-color-dark !important
    .catalogs_table__icon
      color: $primary-color

  .catalogs_table__item.active:hover
    background-color: $primary-background-color
