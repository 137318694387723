.schedule-container
  margin-bottom: 5px
  .schedule-grid-container
    margin-bottom: 0
    .panel-heading
      .panel-heading-icon
        font-size: 17px
      .panel-heading-left-block
        .panel-title
          .title
            padding-right: 5px
      .panel-heading-buttons
        .main-item-entry-client-search
          margin: 0 5px

.schedule-grid-change-days
  display: flex
  margin: 0 5px
  column-gap: 5px
