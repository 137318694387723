.personal-checkup-diseases-personal-modal {
  .el-dialog__body {
    flex-direction: column;
  }

  .diseases-app {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
