.panel {
  .panel-heading {
    height: $panel-title-height;
    min-height: $panel-title-height;
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;

    .panel-heading-title {
      font-size: $header-font-size;
    }
  }
}

@media print {
  .panel {
    .panel-heading {
      height: auto;
    }

    .panel-heading-title {
      height: auto;
    }
  }
}


.table > tbody > tr {
  &:hover {
    background-color: $primary-background-color;
  }
}

.table > tbody > tr.active > td {
  background-color: $primary-background-color-dark;
  color: $primary-color;

  i, span.fad, .m-icon {
    color: $primary-color !important;
  }
}

.panel-list-body {
  tr:hover {
    background-color: $primary-background-color;
  }

  tr.active {
    background-color: $primary-background-color-dark;

    td.fad {
      color: $primary-color;
    }

    &:hover {
      background-color: $primary-background-color-dark;
    }
  }
}


