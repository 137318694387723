@mixin common
  font-weight: bold
  margin-left: 5px
  line-height: 1.15
  font-size: 1.1em

$green: #8bc34a
$red: #ff5722

.trend-arrow
  .mdi
    &.mdi-arrow-bottom-right
      @include common
      color: $red

    &.mdi-arrow-top-right
      @include common
      color: $green
