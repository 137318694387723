.support-education
  font-size: $header-font-size

  .support-education__introduction
    padding-bottom: $indent

  .knowledge-base-link
    padding-bottom: $indent-mid

  .knowledge-base-link-icon
    margin-right: $indent-mid

  .support-education__links-list
    .links-list_item
      list-style: circle
      margin-bottom: $indent-small

.import-validator
  .import-validator__introduction
    font-size: $header-font-size
    max-width: 650px
    padding-bottom: $indent

  label
    font-size: $header-font-size
    font-weight: bold

  .validation_data
    display: flex
    flex-direction: column

  .section
    min-width: 270px

  .section__import_details
    display: flex
    flex-direction: column
    margin-bottom: $indent-mid

  .details
    margin-right: $indent-mid

  .section__file_uploader
    margin-right: $indent
    width: 30%

  .el-upload-dragger i.fad
    font-size: 67px
    color: #C0C4CC
    margin: 40px 0 16px
    line-height: 50px

  .import-file-name
    font-size: $header-font-size
    margin-top: $indent-mid
    display: block

  .validation-wrapper.fixed-height
    padding-bottom: 18px

  button
    margin-top: 20px
