@mixin radius
  border-radius: 100%

#clinic_form
  .form-right-containter
    .address_container
      .inner_tabpanel
        .tab-content
          .tab-pane
            #clinic_logotypes
              .max-file-size
                font-style: italic
                margin-bottom: 10px
              .logo-container
                margin-bottom: 15px
                .title
                  font-weight: bold
                  font-size: 13px
                  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
                  margin-bottom: 15px
                .logotype 
                  display: flex
                  .upload-control
                    display: flex
                    justify-content: center
                    align-items: center
                    position: relative
                    .img-logo
                      max-width: 100%
                      height: auto
                      max-height: 150px
                      min-height: 30px
                    .control
                      display: flex
                      position: absolute
                    &:focus
                      outline: none
                    &:hover
                      &:before
                        content: ""
                        width: 100%
                        height: 100%
                        background-color: rgba(0, 0, 0, .3)
                        position: absolute

                .widget-logotype .upload-control
                  max-width: 150px
                  height: 150px
                  background: #eee
                  border-radius: 100%
                  display: flex
                  justify-content: center
                  align-items: center
                  position: relative
                  .img-logo
                    width: 100%
                    height: 100%
                    @include radius
                  .control
                    display: flex
                    position: absolute
                  &:focus
                    outline: none
                  &:hover
                    &:before
                      content: ""
                      width: 100%
                      height: 100%
                      background-color: rgba(0, 0, 0, .3)
                      position: absolute
                      @include radius

                .logo-not-found
                  display: flex
                  align-items: center
                  position: relative
                  .fact
                    margin-left: 10px

                .confirmation-modal
                  width: 600px
.clinics-logo-field
  width: 150px
.clinic-name-in-table
  display: inline-block
  max-width: 100%
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
.logo-clinics-in-table
  height: $clinics-logo-table-height
