.el-select
  &.full-width
    width: 100%
    max-width: 100%
    .el-input,
    .el-date-editor.el-input
      width: 100%
      max-width: 100%
  &[multiple=multiple]:not(.el-select_inline):not(.filterable-select)
    .el-select__tags
      flex-direction: column
      align-items: flex-start
      > span
        display: flex
        flex-wrap: wrap
        width: 100%

    .el-select__input
      border: none
      width: 100% !important
      margin-left: 5px !important
      &:focus
        outline: none
        box-shadow: none
        border: none
