.introduction-container
  margin: 15px
  .introduction-icon-container
    font-size: 100px
    color: $primary-color
    .module-disabled
      color: $grey
  .introduction-description-container
    font-size: 14px
    .introduction-description
      max-width: 650px
      line-height: 1.6
      word-break: normal
  .introduction-request-button
    margin-top: 15px
  .telegram-qr img
    max-width: 200px
