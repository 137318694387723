.fast-create-modal {
  .el-dialog__body {
    display: flex;
    flex-direction: column;
    margin-bottom: $indent;
  }

  &__client {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
    padding: $indent 0;
  }

  .validation-wrapper {
    width: 200px;
  }

  &__delete-btn {
    grid-column: 2 / 3;
    margin-top: $indent-small;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: $indent;
    gap: $indent-small;
    grid-column: 1 / 2;
  }

  &__long-field {
    width: 405px !important;
  }
}
