.company-final-act {
  &-worker-list {

    &__title {
      .document-field-writable {
        margin-right: 5px;

        &:after {
          content: ".";
        }
      }
    }

    &__list {
      .document-field-writable {
        position: relative;

        &:after {
          content: ",";
          position: absolute;
        }
      }
    }

    &__item {
      &:last-child {
        .document-field-writable {
          &:after {
            content: ".";
          }
        }
      }
    }
  }

  .document-table {
    margin-bottom: $indent-mid;
  }

  &-view {
    &__header {
      display: flex;
      flex-direction: column;
      gap: $indent-small;
      margin-bottom: $indent-mid;
    }

    &__footer {
      display: flex;
      gap: $indent * 2;
      margin-top: 30px;
      page-break-inside: avoid;
    }

    &__place-of-printing {
      margin-left: 50px;
    }
  }
}
