.catalogs-form
  .panel_close_button
    cursor: pointer

  .flex-form
    margin-bottom: 0

  input, textarea
    padding: 3px 6px !important
    border-radius: 4px
    border: 1px solid $grey
    box-shadow: none
    font-size: 13px
    line-height: 146%
    color: #555

  input:not(.checkbox)
    height: 28px
    padding-right: 28px !important 
  
  input:focus
    box-shadow: none

  input:disabled, textarea:disabled
    background-color: $primary-background !important
    border-color: $grey !important
    color: #555 !important
    cursor: auto !important

  .catalogs-form__btns
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%

    .fa-trash 
      font-size: 16px
      cursor: pointer
