.schedule-container
  .modal
    .doctor-schedules-select-user-modal,
    .doctor-schedules-alert-modal,
    .doctor-schedules-copy-appointment-with-services
      width: 500px
      .modal-content
        width: 500px
        font-size: 15px

    .doctor-schedules-confirmation-modal
      width: 550px
      .modal-content
        width: 550px
        font-size: 15px

    .doctor-schedules-create-work-time-modal
      width: 700px
      .modal-content
        width: 700px
        font-size: 15px
        .modal-body
          .schedule-form-item
            display: flex
            align-items: center
            column-gap: 5px
            .schedule-form-left
              label
                margin-bottom: 0
            .schedule-form-right
              flex-basis: 65%
              margin-left: auto
              .el-select
                width: 100%

            .main-message
              flex-basis: auto

          .schedule-form-item + .schedule-form-item
            margin-top: 15px
