.user-dropdown-list
  .el-scrollbar
    .el-select-dropdown__wrap
      .el-scrollbar__view
        .el-select-dropdown__item
          .user-dropdown
            align-items: center
            display: flex
            .vue-user-avatar
              margin: 0 20px 0 0
              border-radius: 100%
