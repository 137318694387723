.el-notification
  .el-notification__content
    text-align: left

  .el-icon-info
    color: #3faeca

  .el-notification__closeBtn
    &:hover
      font-weight: bold
      transition: 150ms