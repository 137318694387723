.appointment__params
  align-content: stretch
  display: flex
  flex: 2
  flex-direction: column
  .form-value
    overflow: hidden
  .form-label.selected-entries-label
    align-items: flex-start
    label
      margin-top: 6px
  .entry-types__list
    display: flex
    flex-direction: column

    .prompt-notice
      padding: 5px

    .entry-types__list-item
      align-items: center
      display: flex
      gap: 10px
      padding: 5px
      &:nth-of-type(odd)
        background-color: #f9f9f9

      .list-item__remove
        color: #ff6868
        cursor: pointer
        margin-left: auto
        flex-shrink: 0

      .list-item__title
        max-width: 285px
        text-overflow: ellipsis
        white-space: nowrap

    .entry-types__list-total
      display: flex
      font-weight: bold
      justify-content: flex-end
      padding: 5px
