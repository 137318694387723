$border-color: #ddd
$hover-background-color: #eee
$background-color-of-active-page: #3faeca
$number-color-of-active-page: #fff
$number-color: #6c6c6c
$number-color-disabled: #c0c4cc

@mixin full-border
  border: 1px solid $border-color

.pagination-vue
  display: block
  margin: 5px 0
  .el-pagination
    font-weight: 300
    white-space: normal
    padding: 0
    color: inherit
    .el-pager
      li
        @include full-border
        border-left: 0
      .active
        border: 1px solid $primary-border-color
        background-color: $primary-background-color
        color: $primary-color
        &:hover
          background-color: $background-color-of-active-page
          color: $number-color-of-active-page
      li:hover
        background: $hover-background-color
        color: $number-color

    .btn-prev
      @include full-border
      border-top-left-radius: 4px
      border-bottom-left-radius: 4px
      padding: 0 6px
      color: $number-color

    .btn-next
      @include full-border
      border-top-right-radius: 4px
      border-bottom-right-radius: 4px
      padding: 0 6px
      color: $number-color
      border-left: 0

    .btn-prev,
    .btn-next
      .el-icon
        font-weight: 1000
      &:disabled
        color: $number-color-disabled
      &:hover
        background: $hover-background-color
      &:hover:disabled
        background: white
        color: $number-color-disabled


