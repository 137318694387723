.blade-runner__title {
  font-size: 12px;
  font-weight: bold;
}

.blade-runner__entry-types td {
  border: 1px solid;
  padding: 0 5px;

  &:first-child {
    width: 100px
  }
}
