@import "entities";

.entity-draggable-window.draggable-window {
  height: 600px !important;
  width: 600px !important;

  &.draggable-window_fix_list {
    height: 80px !important;
    min-width: 350px !important;
    width: 350px !important;
  }

  &.draggable-window_diagnoses_constructor {
    height: 620px !important;
    width: 700px !important;
    max-width: 700px !important;
  }

  &.draggable-window_diagnoses {
    height: 280px !important;
    width: 500px !important;
  }

  padding: 0;
  display: flex;
  flex-direction: column;
  border: none;
  overflow: hidden;
  border-radius: $border-radius-base;
  box-shadow: $box-shadow;


  .draggable-window__title {
    height: $panel-title-height;
    min-height: $panel-title-height;
    border-radius: $border-radius-base $border-radius-base 0 0;

    color: white;
    display: flex;
    align-items: center;
    padding: $indent-small $indent-small;


    &_success {
      background-color: $success-color;
    }

    &_warning {
      background-color: lighten($orange, 5%);
    }

    &_primary {
      background-color: $primary-color;
    }
  }

  .draggable-window__draggable-area {
    cursor: grab;
    user-select: none;

    &:active {
      cursor: grabbing;
    }
  }

  .draggable-window__body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;
    padding: $indent-mid;
  }

  .draggable-window__footer {
    display: flex;
    justify-content: flex-end;
    gap: $indent-small;
    flex-basis: $element-height * 2;
    padding: $indent;
    border-top: 1px solid $border-color;
  }

  .el-tree-node__content {
    height: unset;
  }

  .custom-tree-node {
    padding: $indent-small 0;
    white-space: break-spaces;
    word-break: break-word;
  }
}

