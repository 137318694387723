.document-type-data-editor {
  font-size: 12px;
}

#document_types_page {
  .catalog-buttons-container {
    gap: $indent-small;
  }

  #document_types_catalog_search_container {
    flex-grow: 1;

    #document_types_catalog_search {
      width: 100%;
    }
  }

  #form_builder,
  #show_test_modal {
    z-index: unset;

    .modal-dialog {
      width: 224mm;
    }
  }

  #form_builder.modal,
  #show_test_modal {
    .modal-body {
      background: unset;
      overflow-y: hidden;
      overflow-x: hidden;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }

    .modal-content {
      width: 224mm;
    }

    #show_test_ifr {
      width: calc(100% + 4px) !important;
    }
  }

  .form_builder__container,
  #document_type_data_form_value {
    overflow-y: hidden;
  }

  #document_type_form {
    overflow-y: scroll;

    #document_type_data_form_value {
      min-height: 512px;
    }
  }

  #document_type_form,
  #document_type_data_form_value,
  .form_builder__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: nowrap;
  }


  .document-type-data-editor {
    outline: none;
    padding: 15px;
    border: 1px solid $grey;
    border-top: none;
    width: 800px;
    flex-grow: 1;
    overflow-y: auto;
  }

  #document_type_data_toolbar {
    height: 105px;

    .mce-tinymce-inline {
      display: block !important;
      width: 798px !important;
    }
  }

  .document_type_modal__form {
    padding-left: 4px;
    padding-right: $indent;
    flex-wrap: wrap;
  }

  #add-var-button {
    background-color: $success;
    color: white;

    &:hover {
      opacity: 0.8;
    }
  }

  .doc_type_generated {
    td {
      text-wrap: wrap;
    }
  }


  #show_test_modal {
    .modal-content {
      height: -webkit-fill-available;
    }

    .modal-body {
      max-height: unset;
    }

    .modal-body > .mce-tinymce.mce-container.mce-panel {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .mce-container-body {
        height: 100%;
        overflow-y: hidden;

        .mce-edit-area {
          height: calc(100% - 36px);
          overflow-y: scroll;
          overflow-x: hidden;

          #show_test_ifr {
            height: 100% !important;
          }
        }
      }
    }

  }
}
