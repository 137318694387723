.acquiring-modal {
  .el-dialog__body {
    flex-direction: column;
  }

  &__operation-type-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: $indent-mid;

    .m-button {
      width: 190px;
    }
  }

  &__info {
    display: flex;
    gap: $indent-mid;
    margin-bottom: $indent-mid;

    .m-input {
      width: 190px;
    }
  }

  &__long-field {
    .m-input, .m-select {
      width: 300px;
    }
  }

  &__button-full {
    flex-grow: 1;

    .m-button {
      width: 100%;
    }
  }
}
