.checkup-doctors-and-tests {
  display: flex;
  flex-direction: column;
  flex-grow: 0 !important;

  .checkup-doctors-and-tests__factors {
    display: flex;
    gap: $indent-small;

    .factors-select {
      flex-grow: 1;
    }
  }

  .checkup-doctors-and-tests__content {
    display: flex;
    gap: $indent-small;

    .m-table {
      flex-basis: 50%;
    }

    .col__action {
      .m-icon {
        font-size: 12px !important;

        &.fa-times {
          color: $danger;
        }

        &.fa-share {
          color: $primary-color;
        }
      }
    }
  }

  .checkup-doctors-and-tests__stats {
    margin-top: $indent;
  }

  &-modal {
    font-weight: normal;

    .m-table-row:hover {
      background-color: $hover-background-color !important;
      border-radius: $border-radius-base !important;
    }

    .m-table {
      &__header {
        border: none;
      }

      &-row-cell {
        user-select: none;
      }
    }
  }
}
