#softphone
  .softphone-masked-input
    background-color: $grey-dark
    border-color: $grey-blue
    color: white
    font-size: 15px
    padding-right: 40px

    &, &:focus
      height: 40px
      padding-left: 5px

    &:hover, &:focus
      box-shadow: 0 0 5px #FF9800 !important
      border-color: #FF9800 !important

  // 7jt3w2 - free input styles
  .free-input-icon-wrapper
    align-items: center
    display: flex

    .free-input-icon
      color: $grey-blue
      cursor: pointer
      font-size: 16px
      margin-right: 12px

      &.free-input-active
        color: #FF9800

  .free-input-zero-key
    &:after
      content: "+"
      bottom: 22px
      font-size: 12px
      left: 126px
      position: absolute


  .masked-input-reset
    color: white
    cursor: pointer
    font-size: 14px
    position: absolute
    right: 16px
    top: 14px

  .softphone-number-keyboard
    display: flex
    justify-content: center
    flex-wrap: wrap
    margin: 7px 0

    .softphone-number-key-wrapper
      display: flex
      justify-content: center

    .softphone-number-key
      color: white
      background-color: transparent
      border: none
      height: 60px
      font-family: Tahoma, serif, inherit
      font-size: 20px
      width: 60px

      span
        user-select: none

      &:focus
        outline: none

      &.free-input-key-pressed
        border: 2px solid #FF9800 !important

  #free_input_checkbox
    position: absolute
    top: 22px
    left: 22px

    .checkbox
      cursor: pointer
      &:after
        background-color: $grey-dark !important
      &:before
        border-color: $grey-blue
        border-width: 1px


  =softphone_buttons_styles($color)
    color: white
    border: none
    height: 32px
    width: 32px
    font-size: 15px
    background-color: $color
    &:hover
      background-color: darken($color, 10%)

  #softphone_call
    +softphone_buttons_styles($success)
    margin-right: 8px

  #softphone_sms
    +softphone_buttons_styles($blue)
    margin-left: 8px

  #softphone_whatsapp
    min-height: 32px !important
    width: 32px
    margin-left: 8px


// 7jt3w2 - colorizing softphone popper
.softphone-popper-panel
  background-color: $grey-dark !important
  border-color: $grey-dark !important
  box-shadow: 0 5px 5px 0 rgba(50, 50, 50, 0.75) !important
  left: unset !important
  top: 40px !important
  right: 0 !important
  @media screen and (min-width: 1140px)
    top: 45px !important
    right: 22px !important
  width: 266px !important

  .popper__arrow,
  .popper__arrow:after
    border-bottom-color: $grey-dark !important

  .popper__arrow:after
    content: unset !important

  .popper__arrow
    left: unset !important
    right: 6px !important
