.order_goods_list_wrapper
  .panel-body
    margin-top: 5px

    .si-table
      .header
        width: 100%

      .body
        overflow-y: auto
        min-height: 35px

  .si-column
    flex: 1
