.protocols-for-print {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;

  .protocols-for-print__protocol {
    max-width: 210mm;

    .protokol-content-wrapper,
    .entries-wrapper {
      overflow-y: hidden;
    }

    .protocol-content-margins {
      @media screen {
        padding-top: 10mm;
        padding-bottom: 10mm;
      }
    }
  }

  .protocols-for-print__protocol_stub {
    height: 300px;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;

    &.semd-area {
      font-size: 11px;
    }
  }
}
