
.entry-types-transfer {
  .entry-types-transfer__counter {
    .el-badge__content {
      font-size: 10px;
      line-height: 16px;
    }

    pointer-events: none;
    position: absolute;
    right: -5px;
    top: 3px
  }

  .el-dialog__body {
    padding-top: $indent-small;
    min-height: 250px;
  }
}
