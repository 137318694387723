.sidebar-right
  &-container
    flex-basis: 250px
    height: 100%
    position: relative
    width: 250px
    &--collapsed
      flex-basis: 30px
  &-collapsed
    height: 100%
    margin-left: 0!important
  &-panel,
  &-collapsed
    &.reminder .panel-heading
      background-color: $green
      border-color: $green
    &.waiting .panel-heading
      background-color: $orange
      border-color: $orange
    &.online .panel-heading
      background-color: $crimson
      border-color: $crimson

  &-panel
    display: flex
    flex-direction: column
    flex-grow: 0
    flex-basis: 250px
    height: 100%
    padding-left: 0
    padding-right: 0
    text-align: center
    .control-element-grid-step, .control-element-grid-days
      .vue-list
        display: flex
        flex-wrap: wrap
        justify-content: center
        gap: 5px
        .vue-list-item
          margin: 0
          flex-basis: 35px
    .control-element-grid-step
      margin-bottom: 5px
      .vue-list
        flex-wrap: nowrap
    .panel-heading-title span
      font-size: 17px
    .panel-heading-buttons span
      font-size: 2.6rem
      cursor: pointer
    +waiting-list-component
    +reminders-list-component
    +online-recording-list-component
    .panel-body
      display: flex
      flex-direction: column
      overflow: hidden
    .side-menu-wrapper
      margin-bottom: 5px
      min-width: 240px
    .sidebar-calendar
      height: 100%
      overflow-y: auto
      overflow-x: hidden
      margin: 0 -5px
      padding: 0 5px
      min-width: 250px
      padding-bottom: 20px
      .schedule-calendar-container
        min-height: 260px
        position: relative
      .schedule-calendar
        +jquery-calendar-component

      .schedule-control-elements
        display: flex
        flex-direction: column
        .control-element-title
          background: $green
          color: white
          padding: 2px
          margin: 3px 0
        .el-select
          margin-top: 10px
          text-align: left
          .el-select__tags
            span
              flex-wrap: nowrap
        .control-element-checkbox
          display: flex
          margin-top: 10px
          .control-element-checkbox-label
            padding-top: 1px
            text-align: left
            font-size: 12px
            white-space: nowrap
            label
              font-weight: normal
        .appointments-filter
          margin-bottom: 0
        .reset-filters
          margin-top: 10px
          width: fit-content

    #btn-trigger-group
      display: grid
      grid-template-columns: 1fr 1fr
      grid-template-rows: 30px 30px
      gap: 5px
      margin-top: auto
      .side-menu-trigger
        display: flex
        justify-content: center
        align-items: center
        min-height: 30px
        border-radius: 2px
        cursor: pointer
        &.disabled
          opacity: .65
        .trigger-icon
          color: white
          font-size: 14px
          margin-right: 10px
        .item-counter
          display: flex
          justify-content: center
          align-items: center
          height: 18px
          min-width: max-content
          padding: 0 6px
          font-weight: bold
          color: white
          background-color: #777
          border-radius: 10px
          user-select: none
      .calendar-trigger
        background-color: $primary-color
        .trigger-icon
          margin: 0
      .reminders-trigger
        background-color: $green
      .waiting-list-trigger
        margin-bottom: 0
        background-color: $orange
      .online-recording-trigger
        margin-bottom: 0
        background-color: $crimson
      .active
        background-color: $grey-dark
