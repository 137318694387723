#modal_form_appointment_edit
  display: none
  .modal-dialog
    width: 90%
    height: calc(100% - 50px)
    margin-top: 20px
    .modal-content
      display: flex
      flex-direction: column

      .modal-header
        max-height: 40px

      .modal-footer
        min-height: max-content
        .modal-clear
          background-color: $blue

      .modal-body
        height: 100%
        overflow-y: scroll
        .modal-form-appointment-new-container
          display: flex
          flex-wrap: nowrap

          =form-elements-mixin
            .form-label
              display: flex
              justify-content: flex-end
              align-items: center
              flex: 0 0 35%
            .form-value
              display: flex
              align-items: center
              flex: 1 1 65%
              max-width: 65%
              padding-right: 0
              .form-group
                width: 100%

          /* ################### COLUMN-CLIENT ################### */

          .appointment-column-client
            flex: 3
            #clients_form
              margin-bottom: 0
              #clients_creation_form
                padding-right: 15px
                flex-basis: 100%
                .surname_value_container
                  .switch_right_container_btn
                    display: flex
                    min-width: 28px
                    margin: 0 0 0 2px
                    padding: 0
                    height: 100%
                    justify-content: center
                    align-items: center
                .fs-appointment-date-container
                  justify-content: space-between
              +form-elements-mixin

              #cient_additional_fields
                display: none

          .appointment-column-client.show_right_form_container
            flex: 6
            #clients_form
              #clients_creation_form
                flex-basis: 50%
              #cient_additional_fields
                display: flex
                flex-basis: 50%
                padding: 0
                #clients_tabpanel
                  margin-bottom: 0

          /* ################### COLUMN-CLIENT-MATCH ################### */

          .appointment-column-client-match
            flex: 3

            =header-mixin($margin-top)
              background-color: $primary-color
              padding: 3px
              text-align: center
              margin-top: $margin-top
              margin-bottom: 5px
              color: white
              font-weight: bold
              font-size: 13px

            .appointment-similar-clients-container
              .client-form-with-similar-header
                +header-mixin(0)
              table
                tbody
                  .f-si-item
                    display: flex
                    .similar-clients-list-fullname
                      flex-grow: 1

            .appointment-client-info-container
              .client-form-with-similar-header
                +header-mixin(0)
              .client-info-list
                table
                  tbody
                    tr
                      .adv_distribution
                        color: $red-light
                      .medium-editor-show
                        display: table-cell
                        border: none
                        background: white
                        font-weight: normal
                        text-align: start
                      .service-card-title
                        border: none
                        padding: 0
                        background-color: white
                        div
                          +header-mixin(5px)
                      #service_card_edit_form

                        .client_service_card
                          width: 100%
                          margin-bottom: 0

                        .medium-editor-element
                          height: 185px
                          word-break: break-word

          /* ################### COLUMN-PARAMS ################### */

          .appointment-column-params
            flex: 4
            padding-left: 15px
            #appointment-form
              margin-bottom: 0
              .form-value
                .entry-types-sum
                  margin-left: auto
                  margin-top: 4px
                  font-weight: bold
                .appointment_client_comment
                  textarea
                    color: $red
                    height: 45px
                .appointment_entry_type_ids
                  display: none
              .by_dms
                .appointment_by_dms
                  div
                    label
                      display: flex
                      margin: 0
              .fs-appointment-creator-container
                flex-wrap: wrap
                div
                  width: 100%
              .appointment_sms_status_data
                flex-direction: column
                align-items: flex-start
                div
                  padding: 5px 0
            +form-elements-mixin
