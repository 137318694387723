.m-buttons-group
  .m-buttons-group__vertical
    display: flex
    flex-direction: column

    .el-radio-button
      &:first-child
        border-left: none

      border-left: 1px solid $el-grey
      border-radius: 4px
      align-self: flex-start

      .el-radio-button__inner
        border-radius: inherit
