#treatment_plans {
  width: 100%;
}

.client-treatment-plans {
  .treatment-plans-filters__period {
    .m-date-picker {
      max-width: 270px;
    }
  }

  .treatment-plans-filters__type,
  .treatment-plans-filters__status {
    .m-select {
      min-width: 210px;
    }
  }
}

.treatment-plans-filters__status_poper {
  .m-icon {
    top: 1px
  }
}

.clients_treatment_plans_page {
  #client_card_list {
    .panel-heading {
      min-height: $panel-title-height;
      height: $panel-title-height;
      border-top-left-radius: $border-radius-base;
      border-top-right-radius: $border-radius-base;
    }
  }
}
