.company-orders
  height: 100%
  .si-table
    .si-field
      .order-final-sum
        flex-grow: 3
        flex-basis: 70px
        flex-shrink: 0
      .order-paid-sum
        flex-grow: 3
        flex-basis: 70px
        flex-shrink: 0