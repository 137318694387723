@import './doctor_schedule_grid'
@import './cabinet_schedule_grid'

@media print
  .doctor-schedule-grid, .cabinet-schedule-grid
    height: 100% !important
    overflow: visible
    font-size: 8px !important
    table
      thead
        tr
          th
            border-right: 1px solid $grey !important
            border-top: 1px solid $grey !important 
            &:first-child
              border-left: 1px solid $grey !important
      tbody
        tr
          td
            .schedule-grid-appointments-container
              .schedule-grid-appointment-container
                .schedule-grid-appointment
                  border: 1px solid #7b7b7b !important
                  background: antiquewhite !important
