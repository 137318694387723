@import "OrdersReport";

#report_orders_per_day {
  .number {
    flex-grow: 0;
    flex-basis: 60px;
  }

  .short_name {
    flex-grow: 0;
    flex-basis: 120px;
  }

  .title {
    flex-grow: 0;
    flex-basis: 150px;
    min-width: 150px;
  }

  .receipt-field {
    flex-grow: 0;
    flex-basis: 50px;
  }

  .document-number {
    flex-grow: 0;
    flex-basis: 160px;
  }

  .document-createdAt {
    flex-grow: 0;
    flex-basis: 220px;
  }

  .emk_number {
    max-width: 110px;
  }

  .measureUnitShortTitle {
    flex-grow: 0;
    flex-basis: 80px;
  }
}

.cancellations-report {
  .measureUnitShortTitle {
    flex-grow: 0;
    flex-basis: 80px;
  }
}