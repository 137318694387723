#order_entries_table_container {
  .entry-types-search-add-legacy {
    display: flex;
    gap: $indent-small;
    flex-grow: 1;

    .entry_type_selector {
      flex-grow: 1;
    }

    .entry_type_selector_modal {
      padding-top: 4px;
      min-width: 55px;
      border-radius: $border-radius-base;
      border-color: $el-grey;

      span.fad {
        margin-right: 0;
      }

      &:hover {
        border-color: $primary-color;
        background-color: white;

        span.fad {
          color: $primary-color;
        }
      }
    }
  }
}
