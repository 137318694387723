.protocol-entity-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  gap: $indent-mid;

  .protocol-entity-view__textarea {
    flex-basis: 150px;
    flex-shrink: 0;
    width: 100%;
    word-break: normal;
  }

  .protocol-entity-view__tree {
    flex-grow: 1;
  }

  .protocol-entity-view__vertical-mode {
    flex-shrink: 0;
    position: absolute;
    bottom: $indent + 2;
    left: $indent;
  }
}
