.personal-checkup-vaccination-modal {
  .el-dialog {
    max-width: 1000px;
  }

  .el-dialog__body {
    flex-direction: column;
  }

  &__large-icon {
    font-size: x-large !important;
    height: min-content;
  }

  &__mibp-diseases-group {
    width: 400px;
  }
}
