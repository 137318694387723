=jquery-calendar-component
  .datepicker
    border: none
    padding: 0
    width: 100%
    div
      table
        width: 100%
        thead
          tr
            .datepicker-switch
              width: 100% !important
            .prev, .next
              height: 30px
              width: 35px !important
              min-width: 35px
              font-size: 16px
              line-height: 1em
            th
              width: auto
        tbody
          tr
            td
              width: auto
