.analysis_orders_index_page
  #analysis_orders
    height: 100%

    .fa-square
      color: $primary-color

    .fa-check-square
      color: $green

    .si
      .si-control
        .vue-items-list
          .vue-list-item
            background: $primary-color
            border-color: $primary-color
            color: white
            line-height: 1.4
            &:hover
              background-color: $primary-color-hover
              border-color: $primary-color-hover

          .active
            background: $grey-dark
            border-color: $grey-dark
            &:hover
              background: $grey-dark-hover
              border-color: $grey-dark-hover

        .search
          .el-input__inner
            border: none
            border-bottom: 1px solid $grey
            border-radius: 0
            height: 30px
            margin-bottom: 7px
            &:focus
              box-shadow: none
              border-bottom: 1px solid $primary-color
              padding-left: 10px

        =primary-button-styles
          background-color: $primary-color
          border-color: $primary-color
          border-radius: 2px
          height: 30px
          padding: 0 8px

          .title-icon
            margin-right: 5px

        .analysis_orders__workplace-selector
          .workplace-selector
            height: 30px
            top: 1px

            .el-button
              +primary-button-styles


          .analysis_orders__workplace-updater
            height: 30px
            i
              margin-right: 0

        .workplace-selector__single-button
          +primary-button-styles

      .si-table
        .body
          overflow-y: scroll

        .si-field
          display: flex
          flex-direction: row
          justify-content: flex-start
          width: 100%

          .id
            flex-basis: 50px
            flex-shrink: 0
            flex-grow: 1

          .date
            flex-basis: 100px
            flex-shrink: 0
            flex-grow: 1

          .state
            flex-basis: 120px
            flex-grow: 2
            flex-shrink: 0

          .full_name
            flex-basis: 240px
            flex-grow: 4
            flex-shrink: 1

          .analysis_laboratory
            flex-basis: 120px
            flex-grow: 1
            flex-shrink: 1

          .order
            flex-basis: 65px
            flex-grow: 1
            flex-shrink: 0

          =df-jcc-alignment
            display: flex
            justify-content: center

          =fa-check
            .fa-check
              color: $success

          .questionnaire
            flex-grow: 0
            +df-jcc-alignment
            +fa-check

          .preanalytics
            flex-basis: 120px
            flex-grow: 0
            +df-jcc-alignment
            +fa-check

          .order_send_checkbox
            flex-basis: 60px
            flex-grow: 0
            +df-jcc-alignment

            .el-checkbox__input
              top: 2px
              .el-checkbox__inner
                $cb-size: 17px
                width: $cb-size
                height: $cb-size
                &:after
                  height: 10px
                  left: 5px

    .si-control-tools-footer
      display: flex
      flex-grow: 1
      justify-content: flex-end

.workplace-selector__items
  .el-dropdown-menu__item:hover
    color: $primary-color
