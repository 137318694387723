.consultation-form {
  margin-bottom: $indent;
  gap: $indent;

  .m-select {
    width: 100%;
  }

  .consultation-form__left-side {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: $indent;

    .consultation-form__document-title {
      width: 100%;
    }
  }

  .consultation-form__right-side {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: $indent;

    .foundation-document, .medical-policy {
      display: flex;
      gap: $indent-small;

      .el-input__inner {
        max-height: $element-height !important;
      }

      .m-button {
        min-width: 66px;
      }
    }

    .signature-clarification, .registration-errors__title {
      color: $grey-light;
      font-style: italic;
    }
  }

  .consultation-form__left-side,
  .consultation-form__right-side {
    .m-panel {
      flex-grow: 0 !important;
    }
  }


  .m-select {
    .el-input__inner {
      max-height: $element-height !important;
    }
  }

  .documents-tree-modal,
  .medical-policy-editor,
  .company-search-create {
    & > .m-button-wrapper > .m-button {
      width: 70px;
    }
  }
}
