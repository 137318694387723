.currency-input
  .el-input__inner
    &:disabled
      color: #555
  .el-input__suffix
    display: flex
    align-items: center
    color: #8BC34A
    outline: none
    transition: color .3s
    &:hover
      color: #539a01
    .reply-button
      border: none
      outline: none
      background-color: transparent
  .el-input__prefix
    left: 7px
    top: 3px
