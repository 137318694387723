@import "_filters";
@import "TreatmentPlansReports";
@import "AcquiringReport";
@import "DoctorAppointments";

.m-table-row:has(.salary-by-payments-doctor) {
  background: $success !important;
  color: $white;
}

.report-filters {
  .validation-wrapper:has(.validation-entity.no-error) {
    padding-bottom: $indent-mid;
  }
}
