.si-control
  display: flex
  align-items: center
  flex-wrap: wrap
  @media not print
    .print-content
      display: none

  .component, .si-control-item
    margin: 3px 6px 4px 0
    height: 30px
    font-size: 13px
    .el-input__inner
      border-radius: $border-radius-base

    &.width-unset
      width: unset

  .reset-btn-icon
    color: white
    font-size: larger
  .si-control-btns
    display: flex
    flex-direction: row
    .reset-btn-icon
      color: #fff
    .refresh-btn-icon
      margin-right: 0
    .btn
      margin: 0
    .refresh-btn
      .fad
        margin-right: 0
    &.reset-margin
      margin: 1px 0 0 5px
      .component
        margin: unset

  @media not print
    .print-content
      display: none
