@import "components";

.report-filters {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  gap: $indent;
  position: relative;

  .report-filters__filter {
    @mixin periodFilterMixin {
      max-width: 235px;
      display: flex;
      justify-content: space-between;

    }

    &_periodFilter {
      .m-date-picker_range {
        @include periodFilterMixin;

        .el-input__icon.el-range__close-icon {
          display: none;
          font-size: 12px;
        }
      }
    }

    &_periodFilter_clearable {
      .m-date-picker_range {
        @include periodFilterMixin;
      }
    }
  }

  label.report-filters__filter {
    margin-bottom: 0;
    height: 16px;
  }

  .report-filters__buttons {
    display: flex;
    gap: $indent-small;

    .buttons__report {
      flex-grow: 1;

      .m-button {
        width: 100%;
      }
    }
  }
}
