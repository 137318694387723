@import "styles/semdEntityViews";

.semd-protocol-editor {
  .m-select {
    width: 230px;
  }
}

.semd-area {
  width: 100%;
  background-color: lighten($orange-bright, 38%);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: $indent-small;

  color: darken(orange, 15%);
  font-weight: bolder;
  font-size: 11px;

  margin-left: -1px;

  @media print {
    display: none;
  }

  &:hover {
    opacity: $hover-opacity;
  }

  // на прекрасное светлое будущее
  //&.objectively {
  //  background-color: lighten($success, 37%);
  //  color: darken($success, 15%);
  //
  //  &:before,
  //  &:after {
  //    color: darken($success, 15%);
  //  }
  //}
}

[data-saic] {
  background-color: lighten($orange-bright, 47%);
  margin: 0;
  padding: 3px 0;

  [style="background-color: rgb(255, 255, 255);"] {
    background-color: inherit !important;
  }
}

.mce-content-body {
  ul[data-saic], ol[data-saic] {
    margin: initial;
    padding-left: 40px;
  }

  ul:has(li[data-saic]), ol:has(li[data-saic]) {
    background-color: lighten($orange-bright, 47%);
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 40px;
  }

  ul:not([data-saic]), ol:not([data-saic]) {
    margin-bottom: unset;
  }
}

// на прекрасное светлое будущее
//[data-saic="objectively"] {
//  background-color: lighten($success, 45%);
//}
