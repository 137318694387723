%skeleton-styles
  display: inline-block
  vertical-align: middle
  position: relative
  overflow: hidden
  background-color: #f6f6f6
  border-radius: 10px
  &::after
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    transform: translateX(-100%)
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#ffffff, 0.2) 20%, rgba(#3FAECA, 0.2) 60%, rgba(#fff, 0))
    animation: shimmer 3s infinite
    content: ''

  @keyframes shimmer
    100%
      transform: translateX(100%)

.vue-skeleton-span
  @extend %skeleton-styles

.vue-skeleton-span__hidden
  @extend %skeleton-styles
  div, span, i
    display: none

.vue-skeleton-rect
  @extend %skeleton-styles
  border-radius: 1px
