$dark-button-color: #475264

.small-dropdown
  display: inline-block
  vertical-align: middle
  &__button
    display: flex
    justify-content: center
    align-items: center
    height: 22px
    font-size: 10px
    text-transform: uppercase
    padding: 3px 7px
    border-radius: 2px
    .fad
      margin-right: 3px
      font-size: 13px

  &__menu
    font-size: 13px
    background-color: $dark-button-color
    border-color: $dark-button-color
    min-width: 90px
    transform: translateY(-8px)
    .popper__arrow
      display: none
    .el-dropdown-menu__item
      color: white
      cursor: pointer
      padding: 3px 10px
      line-height: inherit
      &:hover, &:active
        background-color: #FF9800

    @media print
      display: none
