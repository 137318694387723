.print-receipt-modal {
  .el-dialog {
    width: 600px;

    .m-modal__header {
      justify-content: space-between;
    }

    .el-dialog__body {
      flex-direction: column;
    }

    .print-receipt_input-clarification {
      font-style: italic;
      margin-bottom: $indent-validation;
      color: $grey-light;
    }
  }
}
