.egisz-case__card_information
  .date-picker
    .el-date-editor
      width: 215px

  .egisz-legal-representative
    display: flex
    align-items: center

    .egisz-legal-representative__info
      font-size: 20px
      top: -6px
      margin-left: 10px
