@mixin btn-to-element($accent-color, $background-color, $border-color) {
  color: $accent-color;
  background-color: $background-color;
  border-color: $border-color;
  .m-icon {
    color: $accent-color;
  }

  &:hover, &:focus, &:active {
    color: $white;

    .m-icon {
      color: $white;
    }

    background-color: $accent-color;
    border-color: $accent-color;
  }

  &:active {
    background-color: darken($accent-color, 3%);
  }
}
