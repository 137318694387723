.delete-button
  color: #f6f6f6
  height: 30px
  width: 30px
  margin: 2.5px
  display: flex
  justify-content: center
  align-items: center
  border-radius: 100%
  cursor: pointer
  font-size: 14px
  background-color: #F44336
  &:hover
    background-color: #e23d32
