.protocols-tree-modal {
  .el-dialog__body {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }

  .lazy-tree {
    width: 100%;
  }
}
