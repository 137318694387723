$doctor-column-flex: 1

@mixin rating-column
  flex: 1 0 50px

@mixin average-assessment-column
  flex: 1 0 50px

@mixin section
  height: 100%
  display: flex
  flex-direction: column
  overflow: hidden

.client-feedbacks-module
  display: flex
  flex-direction: column
  height: inherit

  .m-menu
    display: flex
    height: 50px
    flex-grow: 0
    .m-menu-item
      border: 1px solid $primary-color
      border-radius: $border-radius-base
      margin-right: $indent-small
      .m-menu-item__label
        padding-right: $indent-mid

  .col__doctor
    display: none

  .employees-rating-section
    @include section

    .si
      .si-table
        .header
          .si-field
            .rating
              @include rating-column

            .average-assessment
              @include average-assessment-column

            .doctor
              flex: $doctor-column-flex

        .body
          .si-field
            .rating
              @include rating-column

            .average-assessment
              @include average-assessment-column

            .doctor
              flex: $doctor-column-flex
              display: flex
              align-items: center

              .vue-user-avatar
                margin: 0 10px 0 0

            .down-trend
              font-weight: bold
              margin-left: 5px
              line-height: 1.15
              font-size: 1.1em
              color: #ff5722

      .si-footer
        .rating-calculation-information
          font-style: italic
          margin: 0 0px 0 10px
          display: flex
          align-items: center

        @media print
          .rating-calculation-information
            margin: 0

  .admission-assessments-section
    @include section
    .admission-assessments-control
      display: flex
      gap: $indent-mid

    .si
      .admission-assessments-dynamics
        position: relative
        height: 100%
        overflow: auto

  .nps-section
    @include section

    .si
      .nps-dynamics
        position: relative
        height: 100%
        overflow: auto

.modal-feedback
  .doctor-row
    display: flex
    align-items: center

    .vue-user-avatar
      margin: 0 5px 0 0
