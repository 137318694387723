@import '../../abstract/colors'

.service-item
  align-items: center
  cursor: pointer
  display: flex
  height: auto
  min-height: 56px
  max-width: 30vw !important
  min-width: 25vw !important
  padding: 8px 14px
  width: 100%

  &:hover
    background-color: $primary-color
    color: white

    .service-item__lab
      color: inherit

  div
    margin-right: 8px

  .service-item__number
    align-self: stretch
    align-items: center
    background-color: $el-grey
    color: $primary-font-color !important
    display: flex
    min-width: 100px
    max-width: 100px
    overflow: hidden
    text-overflow: ellipsis
    padding: 4px

  .service-item__block
    align-items: center
    display: flex
    justify-content: space-between
    overflow: hidden

  .service-item__info
    display: flex
    flex-flow: column nowrap
    overflow: hidden

  .service-item__lab
    color: $grey-light
    font-style: italic
    text-overflow: ellipsis
    overflow: hidden

  .service-item__title
    flex-grow: 1
    line-height: normal
    word-break: break-word
    white-space: normal

  .service-item__price
    font-weight: bold
    min-width: 70px
    text-align: right

.appointment__attach-to-right
  left: unset !important
  right: calc(5% + 22px)

  .popper__arrow
    left: 50% !important
