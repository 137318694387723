.acquiring-filters {
  &_payment-type {
    width: 200px;

    .m-buttons-group {
      gap: $indent-small;
    }

    .m-button-wrapper {
      flex-basis: 50%;
      margin: 0 !important;
    }

    .m-button {
      width: 100%;
    }
  }
}
