.checkup-catalogs {
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  display: flex;
  gap: $indent-small;

  .checkup-catalogs__submenu {
    flex-basis: 260px;
    flex-grow: 0 !important;
    flex-shrink: 0;
  }

  .checkup-catalogs__content {
    flex-basis: max(calc(50% - 260px), 300px);
    flex-grow: 1;
    flex-shrink: 0;
  }

  .checkup-catalogs__editor {
    flex-basis: 50%;
  }
}

.checkup-entry-type-cabinet-clinic {
  display: flex;
  gap: $indent-small;
  min-width: 400px;

  &__clinic-title {
    max-width: 180px;
    width: 100%;
  }

  &__cabinet {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: $indent-mid;
  }
}

.checkup-entry-type-cabinet-clinic__clinic-title {
  max-width: 180px;
  width: 100%;
}
