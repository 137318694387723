.template_records_page {
  #form_builder {
    z-index: unset;
  }
}

#frontend-form {
  z-index: unset;
}

.entry_types_page {
  #form_builder,
  #modal_entry_type_edit,
  #modal_entry_type_edit,
  #template_record_protokol,
  #frontend-form {
    z-index: unset;
  }
}

#protokol-content {
  td {
    word-wrap: break-word;
  }
}
