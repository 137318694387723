.teeth-selector {
  display: flex;
  flex-direction: column;
  align-items: center;

  .up-teeth,
  .down-teeth {
    display: flex;
    flex-direction: row;

    .dent-tooth {
      height: 32px;
      width: 29px;
      margin: 0;
    }
  }

  .up-teeth {
    .dent-tooth {
      transform: rotate(180deg);
    }

    .dent-num {
      top: 11px;
    }
  }

  .down-teeth {
    .dent-num {
      top: 1px;
    }
  }
}