$max-width: 250px
$button-text-width: $max-width - 26px

.dropdown-grey-dark
  max-width: $max-width

  button.btn-grey-dark
    width: 100%

    span
      max-width: $button-text-width
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

  .dropdown-menu
    margin-right: -5px
    max-width: 100%

    .dropdown__menu-item
      color: white
      cursor: pointer
      font-size: 13px
      line-height: 1.5em
      margin: 0
      padding: 0 16px

      &:hover
        background-color: #FF9800

      &.ellipsis
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
