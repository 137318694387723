.m-form-show {
  display: flex;
  flex-direction: column;

  .m-form-show-row {
    display: flex;
    align-items: stretch;

    .m-form-show__label {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      background-color: $form-label-bg-color;
      border-right: 1px solid #ccc;
      padding: 5px !important;
      font-weight: bold;
      font-size: 13px;
      min-width: 215px;
      max-width: 215px;
      align-items: center;
    }

    .m-form-show__value {
      display: flex;
      flex-grow: 2;
      padding: 5px !important;
      text-align: left;
      border: none;
      align-items: center;
    }
  }
}
