@import "print";

.report-acquiring {
  &_sbp-icon {
    width: $icon-font-size;
    height: $icon-font-size;
    background-image: url('/public/assets/sbp-logo.svg');
    background-size: cover;
  }

  &__filters {
    &_payment-type {
      .m-buttons-group {
        gap: $indent-small;
      }

      .m-button-wrapper {
        flex-basis: 50%;
        margin: 0 !important;
      }

      .m-button {
        width: -webkit-fill-available;
      }
    }
  }
}
