@import '../el-checkbox17'

.ip-form
  $space-gap: 15px
  =mb
    margin-bottom: $space-gap

  .ip-form__title
    font-size: 16px
    text-align: center
    +mb

  .ip-form__address-container
    +mb
    .el-card__body
      padding: 4px

    .ip-form__address
      .address-fields__separator
        font-weight: bold
        line-height: 1
        margin: 0 4px

      &.address-fields
        =input-styles
          border: none !important
          border-bottom: 1px solid #EBEEF5 !important
          text-align: center
          padding: 0

          &:active, &:focus
            border-bottom: 1px solid darken(#EBEEF5, 20%) !important
            box-shadow: none
            outline: none

        .address-fields__field
          .el-input__inner
            +input-styles


  .ip-form__active
    +el-checkbox17
    .el-checkbox__label
      font-size: 14px
      position: absolute
      top: 4px

  .ip-form__buttons
    display: flex
    gap: $indent-small
    margin-top: 4px
    align-items: center

    .ip-form__button
      &_delete
        margin-left: auto

  .ip-form__comment
    .el-textarea__inner
      min-height: 100px !important
    +mb

  .ip-form__active
    +mb
