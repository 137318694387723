%add-protocol-button__dot {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 7px;
  height: 7px;
  background-color: $danger-color;
  border-radius: 50%;
  z-index: 1;

  animation: blink 3s infinite ease-in-out;
}

.add-protocol-button {
  position: relative;

  &__dot-warn {
    @extend %add-protocol-button__dot;
  }

  &__dot-warn-item {
    @extend %add-protocol-button__dot;
    top: 11px;
    right: 5px;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
