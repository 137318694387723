.personal-simple-medical-assessment-view {
  &__footer {
    display: flex;
    gap: 100px;
    margin-bottom: $indent-validation;
    page-break-inside: avoid;
  }

  &__place-of-printing {
    margin-top: 20px;
  }
}
