.number-range-slider
  display: flex
  border: 1px solid #ddd
  padding: 0 7px
  align-items: center
  border-radius: 4px
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)
  .header
    border-right: 1px solid #ddd
    padding: 0 7px 0 0
    font-style: italic
    color: #9e9e9e
  .el-slider
    flex: 1
    margin: 0 13px
    .el-slider__runway
      height: 4px
      .el-slider__bar
        height: 4px
        background-color: #3faeca
      .el-slider__button-wrapper
        height: 34px
        .el-slider__button
          border: 2px solid #3faeca
          width: 14px
          height: 14px
  .range
    border-left: 1px solid #ddd
    padding: 0 0 0 7px
  &:hover
     border-color: #c0c4cc
  &:focus
    outline: none
    border-color: #3faeca
  &.disabled
    background-color: #f5f7fa
    border-color: #E4E7ED
    color: #C0C4CC
    cursor: not-allowed
    .el-slider
      .el-slider__runway
        .el-slider__bar
          background-color: #C0C4CC
        .el-slider__button-wrapper
          .el-slider__button
            border-color: #C0C4CC
    &:hover
      border-color: #c0c4cc
    &:focus
      outline: none
      border-color: #3faeca
