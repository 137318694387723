.update-tax-rates-modal {
  &_datepicker-visible {
    .el-dialog__body {
      padding-bottom: 0;
    }
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $indent;
  }

  &__answer-select {
    margin-top: $indent-small;
  }

  &__warning-icon {
    display: block;
    float: left;
    font-size: 24px !important;
    margin: $indent-small $indent-mid 0 0;
  }
}
