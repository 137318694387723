.tree
  margin-top: 10px
  .options.tbl-icon
    margin-right: 10px
  .si-field .tree-path-part-zero
    flex-grow: 0
    flex-basis: 35px
    padding: 5px 0
  .si-field .tree-path-part-step
    padding: 5px 0px
    flex-grow: 0
    flex-basis: auto
  .tree-path-part
    background-color: #3FAECA
    margin-right: 2px
    margin-left: 2px
    border-radius: 5px
    padding: 0 3px
    color: #ffffff
    padding: 3px 4px
    min-height: 21px
  .si-table 
    .header
      border: none
    .body .si-field
      cursor: pointer
      &:nth-of-type(odd)
        background-color: #fff
        &:hover
          background-color: rgba(255,152,0,0.15)
      .options
        flex-basis: 25px
        .tree-folder
          color: #FF9800
        .tree-file
          color: #3FAECA