#discount-select-modal
  .modal-dialog
    width: 600px !important

    .modal-header
      height: 40px

#discount-select-form
  display: flex

  .discount-tabs
    border: none
    box-shadow: none
    max-width: 400px
    width: 100%

    .el-tabs__header
      border-bottom: none
      display: none /* Как в планах лечения */

    .el-tabs__item
      align-items: center
      display: flex
      justify-content: flex-start
      padding: 0 6px
      width: 40px
      &.is-active, &:hover
        color: $primary-color
      i
        font-size: 20px

    .el-tabs__content
      padding: 0

    .discount-form
      display: flex
      width: 100%

      .discount-form__discount
        display: flex
        flex-direction: column
        flex-grow: 1
        margin-right: 10px

        .discount__value
          align-items: center
          display: flex
          margin-bottom: 10px

          .el-input-number
            width: 200px

          .el-input__inner
            border-radius: 2px
            height: 30px
            width: 200px

          .el-input__inner:focus
            font-style: italic

          .value__discount-types
            width: 100px
            margin-left: 5px
            .btn-width-37
              height: 30px
              width: 37px !important

        .discount__round-order
          font-size: 14px

      .discount-form__catalog
        display: flex
        flex-direction: column
        width: 400px

        .el-tree-node
          .el-checkbox
            margin-right: 6px
            .el-checkbox__input
              vertical-align: bottom
              .el-checkbox__inner
                border-radius: 50%
                &::after
                  border: none
                  transform: unset !important

    .discount-history
      $negativeRightMargin: -15px /* нужен для сдвига скроллбара к границе модалки */

      .discount-history__filters
        align-items: center
        display: flex
        justify-content: flex-end
        margin-right: $negativeRightMargin

        .filters__entry-filter
          margin-left: 10px

        .el-radio
          margin-bottom: 0

        .el-radio.is-checked
          .el-radio__label
            color: $primary-color

        .el-radio__input.is-checked
          .el-radio__inner
            background-color: $primary-color

        .el-radio__inner, .el-radio__inner:hover
          border-color: $primary-color

      .discount-history__timeline
        max-height: 70vh
        overflow-y: auto
        padding: 8px 16px 0 1px
        margin-top: 16px
        margin-right: $negativeRightMargin

        .el-timeline-item__wrapper
          padding-left: 34px

        .el-timeline-item__content
          .el-card__body
            padding: 16px 16px 8px

          .tl-item
            display: flex

            .el-card__body
              display: flex
              width: 100%

              .tl-item__tl-info
                flex-grow: 1

                .tl-info__label,
                .tl-info__value
                  vertical-align: top

                .tl-info__label
                  color: $grey-light
                  height: 24px
                  width: 80px

                .tl-info__value
                  color: $primary-font-color
                  flex-grow: 1
                  .tl-info__value-result
                    margin-left: 10px

              .tl-item__tl-user
                margin-left: 16px
                .tl-user__link
                  align-items: center
                  display: flex
                  text-decoration: none
                  white-space: nowrap

                  .tl-user__user-name
                    color: $primary-font-color

                  .tl-user__user-avatar
                    height: 20px
                    margin-left: 5px
                    width: 20px
